import { ImportVideoDataInterface } from '../type/videoSourceType';
import axios from 'axios';

interface DetailHandlersProps {
  setLoading: (loading: boolean) => void;
  setModalTitle: (title: string) => void;
  setModalVisible: (visible: boolean) => void;
  setImportVideoDataList: (list: ImportVideoDataInterface[]) => void;
}

interface DetailHandlersReturn {
  handleOnClickPreviewButton: (url: string, name: string) => void;
  handleCloseModal: () => void;
}

const detailHandlers = ({
  setLoading,
  setModalTitle,
  setModalVisible,
  setImportVideoDataList,
}: DetailHandlersProps): DetailHandlersReturn => {
  const handleOnClickPreviewButton = async (url: string, name: string): Promise<void> => {
    try {
      setLoading(true); // 로딩 시작
      const response = await axios.get(url);
      const mrssData = response.data;

      // XML 파싱 로직 (필요에 따라 추가)
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(mrssData, 'text/xml');
      const items = xmlDoc.getElementsByTagName('item');

      const videoItems = [];
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        const title = item.getElementsByTagName('media:title')[0]?.textContent || 'No Title';
        const pubDate = item.getElementsByTagName('pubDate')[0]?.textContent || 'No Date';
        const thumbnailUrl =
          item.getElementsByTagName('media:thumbnail')[0]?.getAttribute('url') || '';
        const contentUrl = item.getElementsByTagName('media:content')[0]?.getAttribute('url') || '';
        const duration =
          item.getElementsByTagName('media:content')[0]?.getAttribute('duration') || '0';

        videoItems.push({
          key: i.toString(),
          title,
          pubDate,
          thumbnailUrl,
          contentUrl,
          duration,
        });
      }

      setImportVideoDataList(videoItems); // 비디오 데이터 설정
      setModalTitle(name); // 모달 제목 설정
      setModalVisible(true); // 모달 열기
    } catch (error) {
      console.error('Error fetching video data:', error);
    } finally {
      setLoading(false); // 로딩 종료
    }
  };

  const handleCloseModal = (): void => {
    setModalVisible(false);
    setImportVideoDataList([]); // 비디오 데이터 초기화
  };

  return {
    handleOnClickPreviewButton,
    handleCloseModal,
  };
};

export default detailHandlers;
