import React from 'react';
import { Card } from 'antd';

const TranscodeProfile: React.FC = () => {
  return (
    <Card title="Transcode Profile Details" className="transcode-profile-card">
      <p>The selected transcode profile is configured with the following settings:</p>
      <ul>
        <li>HLS 1080p (Medium)</li>
        <li>HLS 720p (Medium)</li>
        <li>HLS 480p (Medium)</li>
        <li>HLS 360p (Medium)</li>
      </ul>
    </Card>
  );
};

export default TranscodeProfile;
