import { makeAutoObservable } from 'mobx';
import { AccountInterface } from 'type/accountType';
import accountApi from '../api/accountApi';

class AccountStore {
  private account: AccountInterface | null = null;
  private projectId: number | null = null;
  private role: string | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  public reset = (): void => {
    this.account = null;
    this.projectId = null;
    this.role = null;
  };

  public async init(): Promise<void> {
    const { results } = await accountApi.fetch();
    this.account = results[0];
    const { project_id: projectId, role } = await accountApi.getUserProjectRoles(
      Number(this.account?.id)
    );
    this.projectId = projectId;
    this.role = role;
  }

  public async update(account: AccountInterface): Promise<void> {
    this.account = await accountApi.updateByAccount(account);
  }

  public async updatePassword(oldPassword: string, newPassword: string): Promise<void> {
    this.account = await accountApi.updatePassword(oldPassword, newPassword);
  }

  public setAccount = (account: AccountInterface): void => {
    this.account = account;
  };

  public setProject = (projectId: number): void => {
    if (this.projectId) {
      this.projectId = projectId;
    } else {
      throw new Error('Account not found');
    }
  };

  public getAccount = (): AccountInterface | null => {
    return this.account;
  };

  public getProject = (): number | null => {
    return this.projectId;
  };

  public getRole = (): string | null => {
    return this.role;
  };

  public isStaff = (): boolean => {
    return this.account?.is_staff ?? false;
  };

  public isInit = (): boolean => {
    return this.account !== null;
  };
}

export const accountStore = new AccountStore();
