import { Form } from 'antd';
import * as Component from 'components';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { videoSourceStore } from '../store/videoSourceStore';
import { VideoSourceInterface } from '../type/videoSourceType';
import * as VideoSourceComponent from './components';

const VideoSourceEdit: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const [videoSource, setVideoSource] = useState<VideoSourceInterface>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  useEffect(() => {
    const fetchVideoSource = async (): Promise<void> => {
      const fetchedVideoSource = await videoSourceStore.getItem(Number(id));
      if (fetchedVideoSource) {
        setVideoSource(fetchedVideoSource);
        form.setFieldsValue(fetchedVideoSource);
      }
    };

    fetchVideoSource().then(() => {
      setIsLoading(false);
    });
  }, [videoSource, id, form]);

  const handleOnClickButton = async (): Promise<void> => {
    try {
      const values = await form.validateFields();
      const updatedVideoSource: VideoSourceInterface = {
        id: Number(id),
        created: values.created ? values.created.toISOString() : '',
        modified: values.modified ? values.modified.toISOString() : '',
        name: values.name,
        // eslint-disable-next-line
        mrss_url: values.mrss_url,
        category: values.category,
        // eslint-disable-next-line
        category_values: values.category_values,
        // eslint-disable-next-line
        import_from: values.import_from,
        // eslint-disable-next-line
        import_to: values.import_to,
        // eslint-disable-next-line
        auto_add_videos: values.auto_add_videos,
        // eslint-disable-next-line
        auto_activate_videos: values.auto_activate_videos,
        // eslint-disable-next-line
        auto_update_videos: values.auto_update_videos,
        // eslint-disable-next-line
        auto_update_custom_thumbnails: values.auto_update_custom_thumbnails,
        // eslint-disable-next-line
        auto_update_video_data_sources: values.auto_update_video_data_sources,
        // eslint-disable-next-line
        auto_deactivate_video_data_sources: values.auto_deactivate_video_data_sources,
        // eslint-disable-next-line
        sync_interval: values.sync_interval,
        headers: values.headers,
        // eslint-disable-next-line
        transcode_profile: values.transcode_profile,
        active: values.active,
        // eslint-disable-next-line
        import_setting: values.import_setting,
        // eslint-disable-next-line
        last_synced: values.last_synced,
      };

      await videoSourceStore.update(updatedVideoSource.id, updatedVideoSource);
      navigate('/video-source');
    } catch (error) {
      console.log('유효성 검사 실패:', error);
    }
  };

  return (
    <div>
      <Component.BasePageComponent>
        {isLoading ? (
          <Component.LoadingSpinnerComponent />
        ) : (
          <VideoSourceComponent.VideoSourceDetailComponent
            form={form}
            handleOnClickButton={handleOnClickButton}
            videoSource={videoSource as VideoSourceInterface}
          />
        )}
      </Component.BasePageComponent>
    </div>
  );
};

export default observer(VideoSourceEdit);
