import { Form } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Component from '../../../../shared/components';
import { contentProviderStore } from '../../store/contentProviderStore';
import { userStore } from '../../store/userStore';
import { ContentProviderInterface } from '../../type/contentProviderType';
import { UserInterface } from '../../type/userType';
import * as UserComponent from './components';
import { detailHandlers } from './handlers';

const UserEdit: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [user, setUser] = useState<UserInterface | null>(null);
  const [contentProviderList, setContentProviderList] = useState<ContentProviderInterface[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { handleOnClickEditButton } = detailHandlers({ id: Number(id), form, navigate });

  useEffect(() => {
    const fetchUser = async (): Promise<void> => {
      await userStore.init();
      await contentProviderStore.init();
      const user = userStore.getItem(Number(id));
      const contentProviderList = contentProviderStore.getItems();
      if (user) {
        setUser(user);
        const formattedUser = {
          ...user,
        };

        form.setFieldsValue(formattedUser);
        setContentProviderList(contentProviderList);
      }
    };
    fetchUser().then(() => {
      setIsLoading(false);
    });
  }, [id]);

  return (
    <Component.BasePageComponent>
      {isLoading ? (
        <Component.LoadingSpinnerComponent />
      ) : (
        <UserComponent.UserDetailComponent
          form={form}
          user={user || undefined}
          actionTitle="SAVE"
          onSubmit={handleOnClickEditButton}
          contentProviderList={contentProviderList}
          readOnly={true}
        />
      )}
    </Component.BasePageComponent>
  );
};

export default observer(UserEdit);
