import { Form, FormInstance, Input } from 'antd';
import React from 'react';
import * as Component from '../../../../../../shared/components';
import { CategoryInterface } from '../../../../type/categoryType';

interface CategoryDetailProps {
  form: FormInstance;
  category?: CategoryInterface;
  actionTitle: string;
  handleOnClickButtonClick: () => void;
}

const CategoryDetail: React.FC<CategoryDetailProps> = ({
  form,
  category,
  actionTitle,
  handleOnClickButtonClick,
}) => {
  return (
    <div>
      <div className="people-details-page">
        <h1>Category: {category?.name}</h1>
        <Form form={form} layout="vertical" className="people-details-form">
          <Form.Item
            name="name"
            label={
              <>
                <div style={{ marginRight: '10px' }}>Name</div>
                <span style={{ fontSize: '10px', color: 'gray' }}>
                  * All category name saves to lowercase.
                </span>
              </>
            }
            rules={[{ required: true, message: 'please enter name' }]}
            initialValue={category?.name.toLowerCase()}
          >
            <Input onChange={e => (e.target.value = e.target.value.toLowerCase())} />
          </Form.Item>
          <Form.Item
            style={{ marginTop: '10px' }}
            name="description"
            label="description"
            initialValue={category?.description}
          >
            <Input />
          </Form.Item>
        </Form>
      </div>
      <div className="people-detail-update-button">
        <Component.ColorButtonComponent text={actionTitle} onClick={handleOnClickButtonClick} />
      </div>
    </div>
  );
};

export default CategoryDetail;
