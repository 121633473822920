import { TableColumnInterface } from 'components/table/Table';
import { RevenueInterface } from 'content/type';

export const getRevenueColumns = (): TableColumnInterface<RevenueInterface>[] => {
  return [
    {
      title: 'Content ID',
      dataIndex: 'content_id',
      key: 'content_id',
      width: '5%',
    },
    {
      title: 'Episode ID',
      dataIndex: 'episode_id',
      key: 'episode_id',
      width: '5%',
    },
    {
      title: 'Year/Month',
      dataIndex: 'yearmon',
      key: 'yearmon',
      width: '8%',
    },
    {
      title: 'Content Provider',
      dataIndex: 'content_provider',
      key: 'content_provider',
      width: '10%',
    },
    {
      title: 'Title (KO)',
      dataIndex: 'title_ko',
      key: 'title_ko',
      width: '10%',
    },
    {
      title: 'Title (EN)',
      dataIndex: 'title_en',
      key: 'title_en',
      width: '10%',
    },
    {
      title: 'Kocowa',
      dataIndex: 'is_kocowa',
      key: 'is_kocowa',
      width: '5%',
    },
    {
      title: 'Membership',
      dataIndex: 'membership',
      key: 'membership',
      width: '8%',
    },
    {
      title: 'Service',
      dataIndex: 'service_name',
      key: 'service_name',
      width: '8%',
    },
    {
      title: 'EBS',
      dataIndex: 'ebs_check',
      key: 'ebs_check',
      width: '5%',
    },
    {
      title: 'CPM',
      dataIndex: 'cpm',
      key: 'cpm',
      width: '5%',
    },
    {
      title: 'VH',
      dataIndex: 'vh',
      key: 'vh',
      width: '5%',
    },
    {
      title: 'VP',
      dataIndex: 'vp',
      key: 'vp',
      width: '5%',
    },
    {
      title: 'Video Ad Imp',
      dataIndex: 'video_ad_imp',
      key: 'video_ad_imp',
      width: '8%',
    },
    {
      title: 'Total VP',
      dataIndex: 'total_vp',
      key: 'total_vp',
      width: '5%',
    },
    {
      title: 'SVOD Rev',
      dataIndex: 'svod_rev',
      key: 'svod_rev',
      width: '8%',
    },
    {
      title: 'SVOD Rev PRG',
      dataIndex: 'svod_rev_prg',
      key: 'svod_rev_prg',
      width: '8%',
    },
    {
      title: 'AVOD Rev PRG',
      dataIndex: 'avod_rev_prg',
      key: 'avod_rev_prg',
      width: '8%',
    },
    {
      title: 'Total Rev PRG',
      dataIndex: 'total_rev_prg',
      key: 'total_rev_prg',
      width: '8%',
    },
  ];
};
