import { Form } from 'antd';
import { TransferDataHandlerInterface } from 'components/transfer/Transfer';
import { contentStore } from 'content/store';
import { playlistItemStore } from 'content/store/playlistItemStore';
import { PlaylistInterface } from 'content/type/playlistType';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Component from '../../../../shared/components';
import { playlistStore } from '../../store/playlistStore';
import * as PlaylistComponent from './components';
import contentListHandlers from './handlers/contentListHandlers';
import detailHandlers from './handlers/detailHandlers';

const PlaylistEdit: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [playlistData, setPlaylistData] = useState<PlaylistInterface>();
  const { filterOption } = contentListHandlers();
  const { handleUpdateButtonClick } = detailHandlers({ form, navigate, id: id as string });

  const fetchPlaylist = async (): Promise<void> => {
    const playlist = await playlistStore.fetchById(Number(id));
    if (playlist) {
      setPlaylistData(playlist);
    }
    setIsLoading(false);
  };

  const dataHandler: TransferDataHandlerInterface = {
    fetchSourceData: async (query: Record<string, string>) => {
      const contents = await contentStore.fetch(query);
      const transferDataSource = contents.map(content => ({
        id: content.id,
        key: content.id,
        name: content.title,
        disabled: false,
      }));
      return {
        data: transferDataSource,
        count: contentStore.getTotalCount(),
      };
    },
    fetchTargetData: async (query: Record<string, string>) => {
      const playlistItems = await playlistItemStore.fetch(query);
      const contents = await contentStore.fetchByIds(playlistItems.map(item => item.object_id));
      return {
        data: playlistItems.map(item => ({
          id: item.id,
          key: item.id,
          name: contents.find(content => content.id === item.object_id)?.title || '',
          disabled: false,
        })),
        count: playlistItemStore.getTotalCount(),
      };
    },
    createWithParentId: async (ids: number[]) => {
      await playlistItemStore.createWithParentId(Number(id), ids);
    },
    deleteWithParentId: async (ids: number[]) => {
      await playlistItemStore.deleteIds(ids);
    },
  };

  useEffect(() => {
    fetchPlaylist().then();
  }, [id, form]);

  const playlistComponents = [
    {
      key: 'detail',
      title: 'Detail',
      component: (
        <div>
          <PlaylistComponent.DetailComponent
            form={form}
            playlistData={playlistData}
            actionTitle="UPDATE"
            handleOnClickButton={handleUpdateButtonClick}
          />
        </div>
      ),
    },
    {
      key: 'videos',
      title: 'Videos',
      component: (
        <PlaylistComponent.ContentListComponent
          dataHandler={dataHandler}
          filterOption={filterOption}
        />
      ),
    },
  ];

  return (
    <Component.BasePageComponent>
      {isLoading ? (
        <Component.LoadingSpinnerComponent />
      ) : (
        <Component.TapComponent components={playlistComponents} />
      )}
    </Component.BasePageComponent>
  );
};

export default PlaylistEdit;
