import { Form } from 'antd';
import { contentStore, seasonStore } from 'content/store';

import { SeasonInterface } from 'content/type';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Component from '../../../../shared/components';
import * as SeasonComponent from './components';
import { detailHandlers } from './handlers';

const SeasonEdit: React.FC = () => {
  const [form] = Form.useForm();
  const { id } = useParams<{ id: string }>();
  const [, setLoading] = useState(true);
  const navigate = useNavigate();
  const [season, setSeason] = useState<SeasonInterface | null>(null);
  const { handleOnClickEditButtonClick } = detailHandlers({ form, navigate, id: Number(id) });

  useEffect(() => {
    const fetchSeason = async (): Promise<void> => {
      const season = await seasonStore.fetchById(Number(id));
      if (season) {
        setSeason(season);
        const formattedSeason = {
          ...season,
          release_date_start: season.release_date_start ? moment(season.release_date_start) : null,
          release_date_end: season.release_date_end ? moment(season.release_date_end) : null,
        };

        const contents = await contentStore.getContentListFromIds(season.contents || []);
        form.setFieldsValue(formattedSeason);
        form.setFieldValue(
          'contents',
          contents.map(content => {
            return { ...content, name: content.title };
          }) || []
        );
      }
      setLoading(false);
    };
    fetchSeason().then(() => {
      setLoading(false);
    });
  }, [id, form]);

  const components = [
    {
      key: 'detail',
      title: 'Detail',
      component: (
        <SeasonComponent.SeasonDetailComponent
          form={form}
          season={season || undefined}
          actionTitle="SAVE"
          handleOnClickButtonClick={handleOnClickEditButtonClick}
        />
      ),
    },
    {
      key: 'poster',
      title: 'Poster',
      component: (
        <SeasonComponent.PosterComponent
          seasonId={Number(id)}
          uploadedPosterList={season?.posters || []}
        />
      ),
    },
    {
      key: 'content',
      title: 'Content',
      component: <SeasonComponent.ContentComponent seasonId={Number(id)} />,
    },
  ];

  return (
    <Component.BasePageComponent>
      <Component.TapComponent components={components} />
    </Component.BasePageComponent>
  );
};

export default observer(SeasonEdit);
