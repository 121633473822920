import * as Component from 'components';
import {
  CategoryInterface,
  DubbingInterface,
  ContentInterface,
  PeopleInterface,
  SubtitleInterface,
  ThumbnailInterface,
} from 'content/type';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as ContentComponent from './components';
import { fetchContentDetailData } from './handlers';
import './ContentEdit.css';

const ContentEdit: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [content, setContent] = useState<ContentInterface | undefined>();
  const [categoryList, setCategoryList] = useState<CategoryInterface[]>([]);
  const [peopleList, setPeopleList] = useState<PeopleInterface[]>([]);
  const [subtitleList, setSubtitleList] = useState<SubtitleInterface[]>([]);
  const [dubbingList, setDubbingList] = useState<DubbingInterface[]>([]);
  const [thumbnailList, setThumbnailList] = useState<ThumbnailInterface[]>([]);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const {
        fetchedContent,
        fetchedCategories,
        fetchedPeople,
        fetchedSubtitleList,
        fetchedThumbnailList,
        fetchedDubbingList,
      } = await fetchContentDetailData(Number(id));

      setContent(fetchedContent);
      setPeopleList(fetchedPeople);
      setSubtitleList(fetchedSubtitleList);
      setCategoryList(fetchedCategories);
      setThumbnailList(fetchedThumbnailList);
      setDubbingList(fetchedDubbingList);
    };
    fetchData().then(() => {
      setIsLoading(false);
    });
  }, [id]);

  const videoComponents = [
    {
      key: 'detail',
      title: 'Detail',
      component: (
        <ContentComponent.DetailComponent
          peopleList={peopleList}
          contentCategoryList={categoryList}
          content={content || ({} as ContentInterface)}
        />
      ),
    },
    {
      key: 'thumbnail',
      title: 'Thumbnail',
      component: (
        <ContentComponent.ThumbnailComponent
          contentId={Number(id)}
          uploadedThumbnailList={thumbnailList}
        />
      ),
    },
    {
      key: 'subtitle',
      title: 'Subtitle',
      component: (
        <ContentComponent.SubtitleComponent contentId={Number(id)} subtitleList={subtitleList} />
      ),
    },
    {
      key: 'dubbing',
      title: 'Dubbing',
      component: (
        <ContentComponent.DubbingComponent contentId={id || '0'} dubbingList={dubbingList} />
      ),
    },
    {
      key: 'transcode',
      title: 'Transcode',
      component: (
        <ContentComponent.TranscodeComponent
          id={Number(id)}
          content={content || ({} as ContentInterface)}
          initialContentStreamManifests={content?.content_stream_manifests || []}
          type="application/x-mpegURL"
        />
      ),
    },
    {
      key: 'contract',
      title: 'Contract',
      component: <ContentComponent.ContractComponent contractId={content?.contract || 0} />,
    },
    {
      key: 'revenue-share',
      title: 'Revenue Share',
      component: <ContentComponent.RevenueComponent contentId={Number(id)} />,
    },
    {
      key: 'service',
      title: 'Service',
      component: (
        <ContentComponent.ServiceComponent content={content || ({} as ContentInterface)} />
      ),
    },
  ];

  return (
    <div>
      <Component.BasePageComponent>
        {isLoading ? (
          <Component.LoadingSpinnerComponent />
        ) : (
          <Component.TapComponent components={videoComponents} />
        )}
      </Component.BasePageComponent>
    </div>
  );
};

export default ContentEdit;
