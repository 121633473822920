import { FormInstance } from 'antd';
import { TransferDataSourceInterface } from 'components/transfer/Transfer';
import { seasonStore } from 'content/store';
import { SeasonInterface } from 'content/type';
import { NavigateFunction } from 'react-router-dom';

interface UseSeasonDetailProps {
  id: number;
  form: FormInstance;
  navigate: NavigateFunction;
}

interface UseSeasonDetail {
  handleOnClickAddButtonClick: () => Promise<void>;
  handleOnClickEditButtonClick: () => Promise<void>;
}

const detailHandlers = ({ form, navigate, id }: UseSeasonDetailProps): UseSeasonDetail => {
  const handleOnClickAddButtonClick = async (): Promise<void> => {
    const newSeason: SeasonInterface = {
      id: -1,
      title: form.getFieldValue('title'),
      ...(form.getFieldValue('synopsis') && { synopsis: form.getFieldValue('synopsis') }),

      ...(form.getFieldValue('season_number') && {
        // eslint-disable-next-line camelcase
        season_number: form.getFieldValue('season_number'),
      }),

      ...(form.getFieldValue('release_date_start') && {
        // eslint-disable-next-line camelcase
        release_date_start: form.getFieldValue('release_date_start').format('YYYY-MM-DD'),
      }),

      ...(form.getFieldValue('release_date_end') && {
        // eslint-disable-next-line camelcase
        release_date_end: form.getFieldValue('release_date_end').format('YYYY-MM-DD'),
      }),
      ...(form.getFieldValue('poster') && { poster: form.getFieldValue('poster') }),
      contents: form.getFieldValue('contents'),
    };

    await seasonStore.create(newSeason);
    navigate('/season');
  };

  const handleOnClickEditButtonClick = async (): Promise<void> => {
    const newSeason: SeasonInterface = form.getFieldsValue();
    newSeason.contents = form
      .getFieldValue('contents')
      .map((content: TransferDataSourceInterface) => content.id);
    await seasonStore.update(id, newSeason);
    navigate('/season');
  };

  return {
    handleOnClickAddButtonClick,
    handleOnClickEditButtonClick,
  };
};

export default detailHandlers;
