import { FormInstance, message } from 'antd';
import axios from 'axios';
import { contentStore } from 'content/store';
import { ContentInterface } from 'content/type';

interface DetailHandlersProps {
  contentId: number;
  form: FormInstance;
  selectedCategories: number[];
  setIsModalVisible: (value: boolean) => void;
}

interface DetailHandlers {
  handleUpdateButtonClick: (contentId: number) => Promise<void>;
  handleEditCategories: () => void;
  handleModalOk: () => void;
  handleModalCancel: () => void;
}

const handleUpdateButtonClick =
  (props: DetailHandlersProps) =>
  async (contentId: number): Promise<void> => {
    const { form } = props;
    try {
      const values = form.getFieldsValue();
      const updatedContent: ContentInterface = {
        id: contentId,
        title: values.title,
        description: values.description,
        genre: values.genre,
        status: values.status,
        // eslint-disable-next-line camelcase
        episode_number: values.episode_number,
        season: values.season,
        cast: values.cast,
        categories: values.categories,
        // eslint-disable-next-line camelcase
        content_type: values.content_type,
        // eslint-disable-next-line camelcase
        age_rating: values.age_rating,
        // eslint-disable-next-line camelcase
        contents_file: values.contents_file,
        thumbnail: values.thumbnail,
        contract: values.contract,
        // eslint-disable-next-line camelcase
        mature_content: values.mature_content,
        // eslint-disable-next-line camelcase
        on_air: values.on_air,
        // eslint-disable-next-line camelcase
        drm_source: values.drm_source,
        // eslint-disable-next-line camelcase
        content_stream_manifests: values.content_stream_manifests,
        created: values.created,
        modified: values.modified,
      };

      try {
        await contentStore.updateContent(updatedContent);
        message.success('Updated successfully');
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status === 400) {
          message.error('Failed to update : ' + JSON.stringify(error.response.data));
        }
      }
    } catch (error) {
      message.error('Failed to update : ' + error);
    }
  };

const handleEditCategories = (props: DetailHandlersProps) => (): void => {
  const { setIsModalVisible } = props;
  setIsModalVisible(true);
};

const handleModalOk = (props: DetailHandlersProps) => (): void => {
  const { form, selectedCategories, setIsModalVisible } = props;
  form.setFieldsValue({ categories: selectedCategories });
  setIsModalVisible(false);
};

const handleModalCancel = (props: DetailHandlersProps) => (): void => {
  const { setIsModalVisible } = props;
  setIsModalVisible(false);
};

const detailHandlers = (props: DetailHandlersProps): DetailHandlers => ({
  handleUpdateButtonClick: handleUpdateButtonClick(props),
  handleEditCategories: handleEditCategories(props),
  handleModalOk: handleModalOk(props),
  handleModalCancel: handleModalCancel(props),
});

export default detailHandlers;
