import React from 'react';
import { InputNumber, InputNumberProps } from 'antd';

interface CustomInputNumberProps extends InputNumberProps {
  readonly?: boolean;
}

const CustomInputNumber: React.FC<CustomInputNumberProps> = ({
  readonly,
  precision = 0,
  style = { width: '100px' },
  ...props
}) => {
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (
      !/[0-9]/.test(e.key) &&
      e.key !== 'Backspace' &&
      e.key !== 'Delete' &&
      e.key !== 'ArrowLeft' &&
      e.key !== 'ArrowRight' &&
      e.key !== '.' &&
      e.key !== 'Tab'
    ) {
      e.preventDefault();
    }
  };

  return (
    <InputNumber
      {...props}
      disabled={readonly}
      min={0}
      precision={precision}
      style={style}
      onKeyDown={handleKeyDown}
      stringMode={false}
    />
  );
};

export default CustomInputNumber;
