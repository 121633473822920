import { BaseStore } from 'store/baseStore';
import { contentProviderApi } from '../apis/contentProviderApi';
import { ContentProviderInterface } from '../type/contentProviderType';

export class ContentProviderStore extends BaseStore<ContentProviderInterface> {
  public async init(): Promise<void> {
    const queryParams = {
      page_size: '100',
    };
    const { results: firstPageResults, page_size: pageSize } =
      await contentProviderApi.fetch(queryParams);

    const pagePromises = Array.from({ length: pageSize - 1 }, (_, index) => {
      const page = index + 2;
      return contentProviderApi.fetch({ ...queryParams, page: page.toString() });
    });

    const responses = await Promise.all(pagePromises);
    const allResults = [...firstPageResults, ...responses.flatMap(response => response.results)];
    this.setItems(allResults);
  }

  public async fetchContentProvider(): Promise<{ id: number; title: string }[]> {
    const queryParams = {
      page_size: '100',
      type: 'cp',
    };
    const result = await contentProviderApi.fetch(queryParams);
    return result.results.map(item => ({
      id: item.id,
      title: item.title,
    }));
  }
}

export const contentProviderStore = new ContentProviderStore(contentProviderApi);
