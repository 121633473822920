import { Layout, Menu } from 'antd';
import { ItemType } from 'antd/es/menu/interface';
import { REVENUE_MENU_ITEMS } from 'constants/menu';
import { observer } from 'mobx-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Sidebar.css';

export interface SidebarProps {
  contentMenuList: ItemType[];
  contractMenuList: ItemType[];
  currentPath: string;
  defaultOpenKeys: string[];
  children: React.ReactNode;
}

const { Content, Footer, Sider } = Layout;

const Sidebar: React.FC<SidebarProps> = observer(
  ({ children, contentMenuList, contractMenuList, currentPath, defaultOpenKeys }) => {
    const navigate = useNavigate();
    return (
      <Layout className="main-layout" hasSider>
        <Sider className="sider" width={200}>
          <button
            className="logo-button"
            onClick={() => navigate('/')}
            style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}
          >
            <img
              src="https://cdn.prod.website-files.com/63d1b32e1095b82ab63eb84b/63d1b8c103906d9498644888_logo_white.svg"
              className="logo"
              alt="main_logo"
            />
          </button>
          <div className="menu-header"></div>
          <div className="content-menu-header">
            <div>Content</div>
          </div>
          <Menu
            className="menu"
            theme="dark"
            mode="inline"
            items={contentMenuList}
            selectedKeys={[currentPath]}
            defaultOpenKeys={defaultOpenKeys}
          />
          {contractMenuList.length > 0 && (
            <div>
              <div className="contract-menu-header">
                <div>Contract</div>
              </div>
              <Menu
                className="menu"
                theme="dark"
                mode="inline"
                items={contractMenuList}
                selectedKeys={[currentPath]}
                defaultOpenKeys={defaultOpenKeys}
              />
            </div>
          )}
          <div className="revenue-menu-header">
            <div>Revenue</div>
          </div>
          <Menu className="menu" theme="dark" mode="inline" items={REVENUE_MENU_ITEMS} />
        </Sider>
        <Layout className="layout-content">
          <Content className="content">{children}</Content>
          <Footer className="footer">ODKMedia, Inc</Footer>
        </Layout>
      </Layout>
    );
  }
);

export default React.memo(Sidebar);
