import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export interface BreadcrumbItem {
  title: string;
  url: string;
}

export default function Breadcrumb(): BreadcrumbItem[] {
  const location = useLocation();
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItem[]>([]);

  useEffect(() => {
    const savedBreadcrumbs = JSON.parse(sessionStorage.getItem('breadcrumbs') || '[]');
    const pathSnippets = location.pathname.split('/').filter(i => i);
    const title = pathSnippets.length ? pathSnippets[pathSnippets.length - 1] : 'Home';
    const currentBreadcrumb = { title, url: location.pathname };

    if (pathSnippets.length < 2) {
      const newBreadcrumbs = [currentBreadcrumb];
      sessionStorage.setItem('breadcrumbs', JSON.stringify(newBreadcrumbs));
      setBreadcrumbs(newBreadcrumbs);
    } else if (
      !savedBreadcrumbs.some(
        (breadcrumb: { url: string }) => breadcrumb.url === currentBreadcrumb.url
      )
    ) {
      const newBreadcrumbs = [...savedBreadcrumbs, currentBreadcrumb];
      sessionStorage.setItem('breadcrumbs', JSON.stringify(newBreadcrumbs));
      setBreadcrumbs(newBreadcrumbs);
    } else {
      setBreadcrumbs(savedBreadcrumbs);
    }
  }, [location]);

  return breadcrumbs;
}
