import { DeleteOutlined, SettingOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import { TableColumnInterface } from 'components/table/Table';
import { ContractInterface } from 'contract/type';
import React from 'react';

interface TableColumnProps {
  handleOnClickTitle: (id: number) => void;
  handleOnClickSetting: (id: number) => void;
  handleOnClickDeleteButton: (id: number) => void;
}

export const getContractColumns = ({
  handleOnClickTitle,
  handleOnClickSetting,
  handleOnClickDeleteButton,
}: TableColumnProps): TableColumnInterface<ContractInterface>[] => {
  return [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (value: string, record: ContractInterface): React.ReactNode => (
        <a href="#" className="contract-title" onClick={() => handleOnClickTitle(record.id)}>
          {value}
        </a>
      ),
    },
    {
      title: 'Licensee',
      dataIndex: 'licensee_title',
      key: 'licensee_title',
    },

    {
      title: 'Content Provider',
      dataIndex: 'licensor_title',
      key: 'licensor_title',
    },
    {
      title: 'Platforms',
      dataIndex: 'platforms',
      key: 'platforms',
      render: (_text: string, record: ContractInterface): React.ReactNode => (
        <span>
          {record.platforms && record.platforms.length > 0 ? record.platforms.join(', ') : 'N/A'}
        </span>
      ),
    },
    {
      title: 'Service Start Date',
      dataIndex: 'service_start_date',
      key: 'service_start_date',
    },
    {
      title: 'Service Expire Date',
      dataIndex: 'service_expire_date',
      key: 'service_expire_date',
    },
    {
      title: 'ACTIONS',
      key: 'actions',
      dataIndex: 'actions',
      render: (_text: string, record: ContractInterface) => (
        <Space>
          <Button
            icon={<SettingOutlined />}
            onClick={() => handleOnClickSetting(Number(record.id) || 0)}
            className="action-btn"
          />
          <Button
            icon={<DeleteOutlined />}
            onClick={() => handleOnClickDeleteButton(Number(record.id) || 0)}
            className="action-btn delete-btn"
          />
        </Space>
      ),
    },
  ];
};
