import { BaseStore } from 'store/baseStore';
import { contractApi } from '../api/contractApi';
import { ContractInterface } from '../type';

class ContractStore extends BaseStore<ContractInterface> {
  public async init(): Promise<void> {
    const result = await contractApi.fetch();
    this.setItems(result.results);
    this.setPageInfo(result.count, result.page_size);
  }

  public async fetchContractById(id: number): Promise<ContractInterface> {
    if (this.getItems().some(contract => contract.id === id)) {
      return this.getItems().find(contract => contract.id === id) as ContractInterface;
    } else {
      const contract = await contractApi.fetchById(id);
      this.addItem(contract);
      return contract;
    }
  }

  public async getContractById(id: number): Promise<ContractInterface | null> {
    return this.getItems().find(contract => contract.id === id) || null;
  }
}

export const contractStore = new ContractStore(contractApi);
