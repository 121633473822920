import { DatePicker, Form, FormInstance, Input, InputNumber, Modal, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import * as Component from '../../../../../../../shared/components';
import { SeasonInterface } from '../../../../../type/seasonType';
import { PeopleInterface } from 'content/type';
import { peopleStore } from 'content/store';

interface SeasonDetailProps {
  form: FormInstance;
  season?: SeasonInterface;
  actionTitle: string;
  handleOnClickButtonClick: () => void;
  readonly?: boolean;
}

const SeasonDetail: React.FC<SeasonDetailProps> = ({
  form,
  season,
  actionTitle,
  handleOnClickButtonClick,
  readonly = false,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [peoples, setPeoples] = React.useState<PeopleInterface[]>([]);
  const [] = useState([]);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    const init = async () => {
      if (!season) {
        return;
      }

      const data = await peopleStore.fetch({
        page: '1',
        page_size: '100',
        seasons__id: season.id.toString(),
      });

      form.setFieldsValue({ cast: data.map((people: PeopleInterface) => people.id.toString()) });
      setPeoples(data);
    };
    init();
  }, [season]);

  const searchPeople = async (value: string) => {
    const response = await peopleStore.fetch({
      page: '1',
      page_size: '10',
      search: value,
    });
    setPeoples(response);
  };

  return (
    <div>
      <div className="season-details-page">
        <h1>Season: {season?.title}</h1>
        <Form form={form} layout="vertical" className="season-details-form">
          <Form.Item
            name="title"
            label="Title"
            rules={[{ required: true, message: 'Please enter a title' }]}
            initialValue={season?.title || ''}
          >
            <Input disabled={readonly} />
          </Form.Item>
          <Form.Item label="Title En" name="title_en">
            <Input disabled={readonly} />
          </Form.Item>
          <Form.Item name="synopsis" label="Synopsis" initialValue={season?.synopsis}>
            <Input.TextArea disabled={readonly} />
          </Form.Item>
          <Form.Item
            name="season_number"
            label="Season Number"
            initialValue={season?.season_number}
          >
            <InputNumber disabled={readonly} />
          </Form.Item>
          <Form.Item
            name="release_date_start"
            label="Release Start Date"
            initialValue={season?.release_date_start}
          >
            <DatePicker disabled={readonly} />
          </Form.Item>
          <Form.Item
            name="release_date_end"
            label="Release End Date"
            initialValue={season?.release_date_end}
          >
            <DatePicker disabled={readonly} />
          </Form.Item>
          <Form.Item name="cast" label="Cast">
            <Select
              onSearch={value => {
                searchPeople(value);
              }}
              mode="multiple"
              options={peoples.map(people => ({
                label: people.name,
                value: people.id.toString(),
              }))}
              filterOption={false}
            ></Select>
          </Form.Item>
          <Form.Item name="series" label="Series" initialValue={season?.series}>
            <Input disabled={readonly} />
          </Form.Item>
          <Modal
            width={2000}
            title="Select Contents"
            open={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            asd
          </Modal>
          <Form.Item name="categories" label="Categories" initialValue={season?.categories || []}>
            <Select mode="tags" style={{ width: '100%' }} disabled={readonly} />
          </Form.Item>
          {season?.program_meta && (
            <>
              <h2>ODX Meta</h2>
              <Form.Item name={['program_meta', 'program_id']} label="Program ID">
                <Input readOnly />
              </Form.Item>
              <Form.Item name={['program_meta', 'program_slug']} label="Program Slug">
                <Input readOnly />
              </Form.Item>
              {season.program_meta.program_image.map((image, index) => (
                <div key={index}>
                  <img src={image['@url']} alt="program_image" style={{ width: '100px' }} />
                  <Form.Item
                    key={index}
                    name={['program_meta', 'program_image', index, '@url']}
                    label={`Program Image - ${image['@type']}`}
                  >
                    <Input readOnly />
                  </Form.Item>
                </div>
              ))}
              <Form.Item name={['program_meta', 'program_enable']} label="Program Enable">
                <Input readOnly />
              </Form.Item>
              <Form.Item name={['program_meta', 'ingest_source']} label="Ingest Source">
                <Input readOnly />
              </Form.Item>
              <Form.Item
                name={['program_meta', 'program_release_start']}
                label="Program Release Start"
              >
                <Input readOnly />
              </Form.Item>
              <Form.Item name={['program_meta', 'program_release_end']} label="Program Release End">
                <Input readOnly />
              </Form.Item>
              <Form.Item
                name={['program_meta', 'program_publish_start']}
                label="Program Publish Start"
              >
                <Input readOnly />
              </Form.Item>
              <Form.Item name={['program_meta', 'program_publish_end']} label="Program Publish End">
                <Input readOnly />
              </Form.Item>
            </>
          )}
        </Form>
      </div>
      <div className="season-detail-update-button">
        {!readonly && (
          <Component.ColorButtonComponent text={actionTitle} onClick={handleOnClickButtonClick} />
        )}
      </div>
    </div>
  );
};

export default SeasonDetail;
