import { Flex, Form, message, Switch, TableColumnsType } from 'antd';
import * as Component from 'components';
import {
  TransferDataHandlerInterface,
  TransferDataSourceInterface,
} from 'components/transfer/Transfer';
import { contentStore } from 'content/store';
import { ContentInterface } from 'content/type';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Content.css';

interface ContentProps {
  seasonId: number;
}

const Content: React.FC<ContentProps> = ({ seasonId }) => {
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const [refreshCounter, setRefreshCounter] = useState(0);

  const [editMode, setEditMode] = useState<boolean>(false);
  const [] = Form.useForm();

  const handleOnClickTitle = (id: number) => {
    window.open(`/video/detail/${id}`, '_blank');
  };

  const columns = [
    {
      title: 'THUMBNAIL',
      dataIndex: 'thumbnail',
      key: 'thumbnail',
      width: '5%',
      render: (_text: string, record: ContentInterface) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            style={{ marginLeft: '10px', width: '100%', height: 'auto' }}
            src={record.thumbnail?.file ?? ''}
            alt=""
          />
        </div>
      ),
    },
    {
      title: 'TITLE',
      dataIndex: 'title',
      key: 'title',
      width: '40%',
      render: (text: string, record: ContentInterface) => (
        <div className="content-title" onClick={() => handleOnClickTitle(record.id || 0)}>
          <div
            className="title-content"
            style={{
              width: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {text}
          </div>
        </div>
      ),
    },
    {
      title: 'status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'created (UTC)',
      dataIndex: 'created',
      key: 'created',
      render: (text: string, _: ContentInterface) => moment(text).format('YYYY-MM-DD HH:mm:ss'),
    },
  ];

  const fetchContent = useCallback(
    async (
      page: number,
      pageSize: number
    ): Promise<{ data: ContentInterface[]; count: number }> => {
      const contentList = await contentStore.fetch({
        page: page.toString(),
        page_size: pageSize.toString(),
        search: searchTerm,
        season__id: seasonId.toString(),
      });
      return { data: contentList, count: contentStore.getTotalCount() };
    },
    [searchTerm]
  );

  useEffect(() => {
    setRefreshCounter(prev => prev + 1);
  }, [searchTerm]);

  const transferColumns: TableColumnsType<TransferDataSourceInterface> = [
    {
      dataIndex: 'id',
      title: 'ID',
      render: (id: number) => (
        <span>
          <Link to={`/video/detail/${id}`} target="_blank">
            {id}
          </Link>
        </span>
      ),
    },
    {
      dataIndex: 'name',
      title: 'Name',
    },
  ];

  const dataHandler: TransferDataHandlerInterface = {
    fetchSourceData: async (
      query: Record<string, string>
    ): Promise<{ data: TransferDataSourceInterface[]; count: number }> => {
      const extendedQuery = { ...query };
      const contents = await contentStore.fetch(extendedQuery);
      const transferDataSource = contents.map(content => ({
        id: content.id,
        key: content.id,
        name: content.title,
        disabled: false,
      }));
      return {
        data: transferDataSource,
        count: contentStore.getTotalCount(),
      };
    },
    fetchTargetData: async (
      query: Record<string, string>
    ): Promise<{ data: TransferDataSourceInterface[]; count: number }> => {
      const extendedQuery = { ...query, season__id: seasonId.toString() };
      const contents = await contentStore.fetch(extendedQuery);
      return {
        data: contents.map(content => ({
          id: content.id,
          key: content.id,
          name: content.title,
          disabled: false,
        })),
        count: contentStore.getTotalCount(),
      };
    },
    createWithParentId: async (itemIds: number[]): Promise<void> => {
      for (const itemId of itemIds) {
        await contentStore.updateContent({ id: itemId, season: seasonId });
      }
      message.success('Successfully added to the season');
    },
    deleteWithParentId: async (itemIds: number[]): Promise<void> => {
      for (const itemId of itemIds) {
        await contentStore.updateContent({ id: itemId, season: null });
      }
      message.success('Successfully removed from the season');
    },
  };

  return (
    <>
      <div style={{ marginBottom: '16px' }}>
        <Switch
          checkedChildren="Edit Mode"
          unCheckedChildren="View Mode"
          onChange={(checked: boolean) => setEditMode(checked)}
          checked={editMode}
        />
      </div>
      {editMode ? (
        <>
          <Flex align="start" gap="middle" vertical>
            <Component.TransferComponent dataHandler={dataHandler} columns={transferColumns} />
          </Flex>
        </>
      ) : (
        <Component.TableComponent<ContentInterface>
          key={refreshCounter}
          tableName="Season Contents"
          searchTerm={searchTerm}
          dataFetch={fetchContent}
          columns={columns}
          handleSearchTerm={setSearchTerm}
        />
      )}
    </>
  );
};

export default observer(Content);
