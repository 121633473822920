import React from 'react';
import { List, Card, Avatar, Form, Input, Checkbox, Button } from 'antd';
import { ThumbnailInterface } from 'content/type';
import { DeleteOutlined } from '@ant-design/icons';

interface ThumbnailListProps {
  thumbnails: ThumbnailInterface[];
  selectedThumbnail: ThumbnailInterface | null;
  onThumbnailSelect: (thumbnail: ThumbnailInterface | null) => void;
  onThumbnailRemove: (thumbnail: ThumbnailInterface) => void;
}

const ThumbnailList: React.FC<ThumbnailListProps> = ({
  thumbnails,
  selectedThumbnail,
  onThumbnailSelect,
  onThumbnailRemove,
}) => {
  return (
    <List
      itemLayout="vertical"
      dataSource={thumbnails}
      renderItem={(thumbnail: ThumbnailInterface) => (
        <Card className="upload-video-list-item" hoverable>
          <div className="video-meta">
            <Form.Item>
              <Checkbox
                checked={selectedThumbnail?.id === thumbnail.id}
                onChange={e => {
                  if (e.target.checked) {
                    onThumbnailSelect(thumbnail);
                  } else {
                    onThumbnailSelect(null);
                  }
                }}
              />
            </Form.Item>
            <Avatar
              shape="square"
              style={{ width: '100px', height: '100px', paddingLeft: '10px' }}
              src={thumbnail.file ? thumbnail.file : ''}
            />
            <div className="video-info">
              <Form.Item label="Name">
                <Input type="text" value={thumbnail.file || ''} />
              </Form.Item>
              <Button
                type="text"
                danger
                icon={<DeleteOutlined />}
                onClick={() => onThumbnailRemove(thumbnail)}
              >
                delete
              </Button>
            </div>
          </div>
        </Card>
      )}
    />
  );
};

export default ThumbnailList;
