import React from 'react';
import { Space, Button } from 'antd';
import { SettingOutlined, DeleteOutlined } from '@ant-design/icons';
import { SeriesInterface } from '../../type/seriesType';
import { TableColumnInterface } from 'components/table/Table';
interface SeriesColumnsProps {
  handleOnClickTitle: (id: number) => void;
  handleOnClickSetting: (id: number) => void;
  onClickDeleteButton: (id: number) => void;
}

export const getSeriesColumns = ({
  handleOnClickTitle,
  handleOnClickSetting,
  onClickDeleteButton,
}: SeriesColumnsProps): TableColumnInterface<SeriesInterface>[] => {
  return [
    {
      title: 'TITLE',
      dataIndex: 'title',
      key: 'title',
      render: (text: string, record: SeriesInterface) => (
        <a href="#" className="series-title" onClick={() => handleOnClickTitle(record.id)}>
          {text}
        </a>
      ),
    },
    {
      title: 'Synopsis',
      dataIndex: 'synopsis',
      key: 'synopsis',
    },
    {
      title: 'Release Date Start',
      dataIndex: 'release_date_start',
      key: 'release_date_start',
    },
    {
      title: 'Release Date End',
      dataIndex: 'release_date_end',
      key: 'release_date_end',
    },
    {
      title: 'Cast',
      dataIndex: 'cast',
      key: 'cast',
    },
    {
      title: 'Production Country',
      dataIndex: 'production_country',
      key: 'production_country',
    },
    {
      title: 'ACTIONS',
      key: 'actions',
      dataIndex: 'actions',
      render: (text: string, record: SeriesInterface) => (
        <Space>
          <Button
            icon={<SettingOutlined />}
            onClick={() => handleOnClickSetting(record.id)}
            className="action-btn"
          />
          <Button
            icon={<DeleteOutlined />}
            onClick={() => onClickDeleteButton(record.id)}
            className="action-btn delete-btn"
          />
        </Space>
      ),
    },
  ];
};
