import { PlaylistInterface } from '../type/playlistType';
import { playlistApi } from '../apis/playlistApi';
import { BaseStore } from 'store/baseStore';

class PlaylistStore extends BaseStore<PlaylistInterface> {
  public async init(): Promise<void> {
    const response = await playlistApi.fetch();
    this.setItems(response.results);
    this.setPageInfo(response.count, response.page_size);
  }

  public getPlaylists(page: number, size: number): PlaylistInterface[] {
    return this.getItems().slice((page - 1) * size, page * size);
  }

  public getPlaylist(id: number): PlaylistInterface | undefined {
    return this.getItems().find(playlist => playlist.id === id);
  }

  public async update(id: number, playlist: Partial<PlaylistInterface>): Promise<void> {
    const response = await playlistApi.update(id, playlist);
    this.updateItem(response);
  }
}

export const playlistStore = new PlaylistStore(playlistApi);
