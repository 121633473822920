import moment from 'moment';
import { BaseStore } from 'store/baseStore';
import { playlistItemApi } from '../apis/playlistItemApi';
import { PlaylistItemInterface } from '../type/playlistType';

class PlaylistItemStore extends BaseStore<PlaylistItemInterface> {
  public async fetchByIds(ids: number[]): Promise<PlaylistItemInterface[]> {
    const playlistItems = await Promise.all(ids.map(async id => this.fetchById(id)));
    return playlistItems;
  }

  public async createWithParentId(parentId: number, itemIds: number[]): Promise<void> {
    await Promise.all(
      itemIds.map(async id =>
        this.create({
          id: id,
          playlist: parentId,
          content_type: 'content',
          object_id: id,
          from_date: moment().format('YYYY-MM-DD'),
          to_date: moment().format('YYYY-MM-DD'),
        })
      )
    );
  }

  public async deleteIds(itemIds: number[]): Promise<void> {
    await Promise.all(itemIds.map(async id => this.delete(id)));
  }
}

export const playlistItemStore = new PlaylistItemStore(playlistItemApi);
