import { observer } from 'mobx-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as Component from '../../../../shared/components';
import { userStore } from '../../store/userStore';
import { UserInterface } from '../../type/userType';
import { tableHandlers } from './handlers';
import { getUserColumns } from './UserColumns';

const UserPage: React.FC = () => {
  const navigate = useNavigate();
  const { onClickEditButton, onClickDeleteButton, handleAddUserClick } = tableHandlers({
    navigate,
  });

  const columns = getUserColumns({
    onClickEditButton,
    onClickDeleteButton,
  });

  const fetchUser = async (
    page: number,
    pageSize: number
  ): Promise<{ data: UserInterface[]; count: number }> => {
    const userList = await userStore.fetch({
      page: page.toString(),
      page_size: pageSize.toString(),
    });
    return { data: userList, count: userStore.getTotalCount() };
  };

  return (
    <Component.BasePageComponent>
      <Component.TableComponent<UserInterface>
        tableName="User"
        columns={columns}
        dataFetch={fetchUser}
        handleAddClick={handleAddUserClick}
      />
    </Component.BasePageComponent>
  );
};

export default observer(UserPage);
