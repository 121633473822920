import { BaseApi } from 'api/baseApi';
import axios from 'axios';
import { BASE_URL, CONTENT_PROVIDER_API_PATH } from 'constants/api';
import { ContentProviderInterface } from '../type/contentProviderType';

class ContentProviderApi extends BaseApi<ContentProviderInterface> {
  public async fetchById(id: number): Promise<ContentProviderInterface> {
    const response = await axios.get(`${BASE_URL}${CONTENT_PROVIDER_API_PATH}${id}/`);
    return response.data;
  }
}

export const contentProviderApi = new ContentProviderApi(CONTENT_PROVIDER_API_PATH);
