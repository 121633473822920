import { UploadChangeParam, UploadFile } from 'antd/es/upload';
import { uploadVideoStore } from '../store/uploadVideoStore';
import { UploadVideoInterface } from '../type/uploadVideoType';

interface FormHandlers {
  handleChange: (info: UploadChangeParam<UploadFile<UploadVideoInterface>>) => void;
  handleRemove: (file: UploadVideoInterface) => void;
  handleChangeMetadata: (
    uploadVideo: UploadVideoInterface,
    targetKey: string,
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

const formHandlers = (): FormHandlers => {
  const handleChange = (info: UploadChangeParam<UploadFile<UploadVideoInterface>>): void => {
    const uniqueFiles = Array.from(new Set(info.fileList.map(file => file.uid))).map(uid => {
      const file = info.fileList.find(file => file.uid === uid);
      return { ...file, title: file?.name };
    });
    uploadVideoStore.setUploadVideoList(uniqueFiles as UploadVideoInterface[]);
  };

  const handleRemove = (file: UploadVideoInterface): void => {
    uploadVideoStore.removeUploadVideoList(file.uid);
  };

  const handleChangeMetadata = (
    uploadVideo: UploadVideoInterface,
    targetKey: string,
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const updatedVideo = { ...uploadVideo, [targetKey]: e.target.value };
    uploadVideoStore.updateUploadVideo(updatedVideo);
  };

  return {
    handleChange,
    handleRemove,
    handleChangeMetadata,
  };
};

export default formHandlers;
