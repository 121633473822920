import { FormInstance } from 'antd';
import { NavigateFunction } from 'react-router-dom';
import { contractStore } from '../../../store/contractStore';

interface DetailHandlersProps {
  id: string;
  form: FormInstance;
  navigate: NavigateFunction;
}

interface DetailHandlers {
  handleOnClickAddButtonClick: () => Promise<void>;
  handleOnClickEditButtonClick: () => Promise<void>;
  handleOnClickDeleteButtonClick: (id: number) => Promise<void>;
}

const detailHandlers = ({ id, form, navigate }: DetailHandlersProps): DetailHandlers => {
  const handleOnClickAddButtonClick = async (): Promise<void> => {
    const contract = form.getFieldsValue();
    await contractStore.create(contract);
    navigate('/contract');
  };

  const handleOnClickEditButtonClick = async (): Promise<void> => {
    const contract = form.getFieldsValue();
    await contractStore.update(Number(id), contract);
    navigate('/contract');
  };

  const handleOnClickDeleteButtonClick = async (id: number): Promise<void> => {
    await contractStore.delete(id);
    navigate('/contract');
  };

  return {
    handleOnClickAddButtonClick,
    handleOnClickEditButtonClick,
    handleOnClickDeleteButtonClick,
  };
};

export default detailHandlers;
