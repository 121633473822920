import React from 'react';
import SelectContentItem from './SelectContentItem';
import Flatpickr from 'react-flatpickr';
import { Button, Form, FormInstance, Input } from 'antd';
import { ContentRuleInterface } from 'content/type/contentRuleType';
import 'flatpickr/dist/themes/material_blue.css';

interface ContentRuleItemProps {
  form: FormInstance;
  contentRule: ContentRuleInterface;
  index: number;
  handleEditContentId: () => void;
}

const ContentRuleItem = ({
  form,
  contentRule,
  index,
  handleEditContentId,
}: ContentRuleItemProps) => {
  console.log(contentRule);
  return (
    <>
      <Form.Item name={['playlistItems', index, 'id']}>
        <Input type="hidden" />
      </Form.Item>
      <Form.Item name={['playlistItems', index, 'object_id']} label="Content Id" required={true}>
        <div>
          <Input
            type="text"
            style={{ width: '30%', marginRight: '10' }}
            value={contentRule.title}
          />
          <Button type="link" onClick={handleEditContentId}>
            search
          </Button>
        </div>
      </Form.Item>
      <SelectContentItem index={index} contentRule={contentRule} />
      <Form.Item name={['playlistItems', index, 'from_date']} label="From Date">
        <Flatpickr
          style={{ width: '30%', backgroundColor: 'white', borderWidth: '1px' }}
          onChange={([date]) => {
            const formattedDate = date.toISOString().slice(0, 10);
            // eslint-disable-next-line camelcase
            form.setFieldValue(['playlistItems', index, 'from_date'], formattedDate);
          }}
        />
      </Form.Item>
      <Form.Item name={['playlistItems', index, 'to_date']} label="To Date">
        <Flatpickr
          style={{ width: '30%', backgroundColor: 'white', borderWidth: '1px' }}
          onChange={([date]) => {
            const formattedDate = date.toISOString().slice(0, 10);
            // eslint-disable-next-line camelcase
            form.setFieldValue(['playlistItems', index, 'to_date'], formattedDate);
          }}
        />
      </Form.Item>
    </>
  );
};

export default ContentRuleItem;
