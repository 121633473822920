import { makeAutoObservable } from 'mobx';
import { UploadVideoInterface } from '../type/uploadVideoType';

class UploadVideoStore {
  private videoList: UploadVideoInterface[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  public init(): void {
    this.videoList = [];
  }

  // 업로드 진행 상태를 업데이트하는 메서드 추가
  public updateProgress(uid: string, percent: number, speed: number): void {
    this.videoList = this.videoList.map((item: UploadVideoInterface) => {
      if (item.uid === uid) {
        return { ...item, percent, speed }; // 진행률 업데이트
      }
      return item;
    });
  }

  // 업로드가 필요한 비디오 리스트를 가져오는 메서드
  public getUploadVideoList(): UploadVideoInterface[] {
    return this.videoList;
  }

  // 업로드가 필요한 비디오 리스트를 초기화하는 메서드
  public clearUploadVideoList(): void {
    this.videoList = [];
  }

  // 업로드가 필요한 비디오 리스트를 설정하는 메서드
  public setUploadVideoList(videoList: UploadVideoInterface[]): void {
    if (this.videoList.length === 0) {
      this.videoList = videoList;
    } else {
      const uniqueVideoList = videoList.filter(
        newVideo => !this.videoList.some(existingVideo => existingVideo.uid === newVideo.uid)
      );
      this.videoList = [...this.videoList, ...uniqueVideoList];
    }
  }

  public removeUploadVideoList(id: string): void {
    this.videoList = this.videoList.filter(video => video.uid !== id);
  }

  public updateUploadVideo(uploadVideo: UploadVideoInterface): void {
    this.videoList = this.videoList.map(video => {
      if (video.uid === uploadVideo.uid) {
        return uploadVideo;
      }
      return video;
    });
  }
}

export const uploadVideoStore = new UploadVideoStore();
