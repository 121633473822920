import { Form } from 'antd';
import { contractStore } from 'contract/store/contractStore';
import { ContractInterface } from 'contract/type';
import React, { memo, useEffect, useState } from 'react';
import { DetailComponent as ContractDetailComponent } from 'src/domain/contract/page/contract/components';

interface ContractProps {
  contractId: number;
}

const Contract: React.FC<ContractProps> = ({ contractId }) => {
  const url = `${window.location.origin}/contract`;
  const [form] = Form.useForm();
  const [contract, setContract] = useState<ContractInterface | null>(null);

  useEffect(() => {
    const fetchContract = async (): Promise<void> => {
      if (contractId) {
        console.log('contractId', contractId);
        const contract = await contractStore.fetchById(contractId);
        setContract(contract);
        form.setFieldsValue(contract);
      }
    };
    fetchContract();
  }, [contractId]);

  return (
    <div>
      <Form form={form} layout="vertical" name="contentContract">
        <div>
          {contractId ? (
            <>
              <a href={`${url}/edit/${contractId}`}>{contract?.title}</a>
              <ContractDetailComponent
                readonly
                form={form}
                contentProviderList={[]}
                licenseeList={[]}
              />
            </>
          ) : (
            <div>
              There is no selected contract. <br />
              <a href={`${url}`}>Move to Contract</a>
            </div>
          )}
        </div>
      </Form>
    </div>
  );
};

export default memo(Contract);
