import { playlistStore } from 'content/store/playlistStore';
import { NavigateFunction } from 'react-router-dom';

interface TableHooksProps {
  navigate: NavigateFunction;
}

const tableHandlers = ({
  navigate,
}: TableHooksProps): {
  handleOnClickTitle: (id: number) => void;
  handleOnClickSetting: (id: number) => void;
  onClickDeleteButton: (id: number) => Promise<void>;
  handleAddPlaylistClick: () => void;
} => {
  const handleOnClickTitle = (id: number): void => {
    navigate(`/playlists/edit/${id}`);
  };

  const handleOnClickSetting = (id: number): void => {
    navigate(`/playlists/edit/${id}`);
  };

  const onClickDeleteButton = async (id: number): Promise<void> => {
    // message.info('You do not have permission');
    // return;
    const isConfirmed = window.confirm('Are you sure you want to delete the selected items?');
    if (!isConfirmed) return;
    await playlistStore.delete(id);
    window.location.reload();
  };

  const handleAddPlaylistClick = (): void => {
    navigate('/playlists/add');
  };

  return {
    handleOnClickTitle,
    handleOnClickSetting,
    onClickDeleteButton,
    handleAddPlaylistClick,
  };
};

export default tableHandlers;
