import { BaseApi } from 'api/baseApi';
import axios from 'axios';
import { ADMIN_USER_API_PATH, BASE_URL, USER_API_PATH } from 'constants/api';
import { FetchResult } from 'type/baseApiType';
import { AccountInterface, UserProjectRoleInterface } from '../type/accountType';

class AccountApi extends BaseApi<AccountInterface> {
  public async fetch(): Promise<FetchResult<AccountInterface>> {
    const response = await axios.get(`${BASE_URL}${USER_API_PATH}`);
    return {
      results: [response.data] as AccountInterface[],
      count: response.data.length,
      next: null,
      previous: null,
      // eslint-disable-next-line camelcase
      page_next: null,
      // eslint-disable-next-line camelcase
      page_previous: null,
      // eslint-disable-next-line camelcase
      page_size: response.data.length,
    };
  }

  public async create(account: AccountInterface): Promise<AccountInterface> {
    const response = await axios.post(`${BASE_URL}${USER_API_PATH}`, account);
    return response.data;
  }

  public async fetchById(id: number): Promise<AccountInterface> {
    const response = await axios.get(`${BASE_URL}${USER_API_PATH}${id}`);
    return response.data;
  }

  public async update(id: number, account: AccountInterface): Promise<AccountInterface> {
    const response = await axios.patch(`${BASE_URL}${USER_API_PATH}${id}`, account);
    return response.data;
  }

  public async updateByAccount(account: AccountInterface): Promise<AccountInterface> {
    const response = await axios.patch(`${BASE_URL}${USER_API_PATH}`, account);
    return response.data;
  }

  public async updatePassword(oldPassword: string, newPassword: string): Promise<AccountInterface> {
    const response = await axios.patch(`${BASE_URL}${USER_API_PATH}password/`, {
      // eslint-disable-next-line camelcase
      old_password: oldPassword,
      // eslint-disable-next-line camelcase
      new_password: newPassword,
    });
    return response.data;
  }

  public async delete(id: number): Promise<void> {
    await axios.delete(`${BASE_URL}${USER_API_PATH}${id}`);
  }

  public async getUserProjectRoles(id: number): Promise<UserProjectRoleInterface> {
    const response = await axios.get(`${BASE_URL}${ADMIN_USER_API_PATH}${id}`);
    return response.data;
  }
}

export default new AccountApi(USER_API_PATH);
