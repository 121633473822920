import { message } from 'antd';
import { contractStore } from 'content/store';
import { NavigateFunction } from 'react-router-dom';

interface TableHandlersProps {
  navigate: NavigateFunction;
}

interface TableHandlers {
  handleOnClickDeleteButton: (id: number) => Promise<void>;
  handleOnClickTitle: (id: number) => void;
  handleOnClickSetting: (id: number) => void;
  handleAddClick: () => void;
  handleBulkDelete: (ids: number[]) => Promise<void>;
}

const tableHandlers = ({ navigate }: TableHandlersProps): TableHandlers => {
  const handleOnClickDeleteButton = async (id: number): Promise<void> => {
    try {
      // message.info('You do not have permission');
      // return;
      const isConfirmed = window.confirm('Are you sure you want to delete the selected items?');
      if (!isConfirmed) return;
      await contractStore.delete(id);
    } catch (error) {
      console.error('삭제 중 오류 발생:', error);
    }
  };

  const handleOnClickTitle = (id: number): void => {
    navigate(`/contract/edit/${id.toString()}`);
  };

  const handleOnClickSetting = (id: number): void => {
    navigate(`/contract/edit/${id.toString()}`);
  };

  const handleAddClick = (): void => {
    navigate('/contract/add');
  };

  const handleBulkDelete = async (): Promise<void> => {
    try {
      message.info('You do not have permission');
      return;
      // await Promise.all(
      //   ids.map(async id => {
      //     await contractStore.delete(id);
      //   })
      // );
    } catch (error) {
      console.error('삭제 중 오류 발생:', error);
    }
  };
  return {
    handleOnClickDeleteButton,
    handleOnClickTitle,
    handleOnClickSetting,
    handleAddClick,
    handleBulkDelete,
  };
};

export default tableHandlers;
