import { BaseApi } from 'api/baseApi';
import axios from 'axios';
import { BASE_URL, SERIES_API_PATH } from 'constants/api';
import { SeriesInterface } from '../type/seriesType';
import { PosterInterface } from 'content/type';

class SeriesApi extends BaseApi<SeriesInterface> {
  public async fetchById(id: number): Promise<SeriesInterface> {
    const response = await axios.get(`${BASE_URL}${SERIES_API_PATH}${id}/`);
    return response.data;
  }

  public async create(series: SeriesInterface): Promise<SeriesInterface> {
    const response = await axios.post(`${BASE_URL}${SERIES_API_PATH}`, series);
    return response.data;
  }

  public async update(id: number, series: Partial<SeriesInterface>): Promise<SeriesInterface> {
    const response = await axios.put(`${BASE_URL}${SERIES_API_PATH}${id}/`, series);
    return response.data;
  }

  public async delete(id: number): Promise<void> {
    await axios.delete(`${BASE_URL}${SERIES_API_PATH}${id}/`);
  }

  async uploadPoster(seriesId: number, formData: FormData): Promise<void> {
    const response = await axios.post(`${BASE_URL}${SERIES_API_PATH}${seriesId}/poster/`, formData);
    return response.data;
  }

  async removePoster(seriesId: number, posterId: number): Promise<void> {
    const response = await axios.delete(
      `${BASE_URL}${SERIES_API_PATH}${seriesId}/poster/${posterId}/`
    );
    return response.data;
  }

  async updatePoster(seriesId: number, poster: PosterInterface): Promise<void> {
    const response = await axios.patch(
      `${BASE_URL}${SERIES_API_PATH}${seriesId}/poster/${poster.id}/`,
      poster
    );
    return response.data;
  }
}

export const seriesApi = new SeriesApi(SERIES_API_PATH);
