import { categoryStore, contentStore, peopleStore, seasonStore } from 'content/store';
import {
  CategoryInterface,
  DubbingInterface,
  ContentInterface,
  PeopleInterface,
  SeasonInterface,
  SubtitleInterface,
  ThumbnailInterface,
} from 'content/type';

interface FetchContentDetailDataReturn {
  fetchedContent: ContentInterface | undefined;
  fetchedCategories: CategoryInterface[];
  fetchedPeople: PeopleInterface[];
  fetchedSubtitleList: SubtitleInterface[];
  fetchedThumbnailList: ThumbnailInterface[];
  fetchedDubbingList: DubbingInterface[];
}

export const fetchContent = async (id: number): Promise<ContentInterface | undefined> => {
  return await contentStore.fetchContent(id);
};

export const fetchCategories = async (categories: number[]): Promise<CategoryInterface[]> => {
  return await categoryStore.fetchByIds(categories);
};

export const fetchSeasons = async (): Promise<SeasonInterface[]> => {
  const response = await seasonStore.fetchByWords(1, '');
  return response.results;
};

export const fetchPeople = async (): Promise<PeopleInterface[]> => {
  return await peopleStore.fetch();
};

export const fetchSubtitleList = async (id: number): Promise<SubtitleInterface[]> => {
  return await contentStore.getSubtitleList(id);
};

export const fetchThumbnailList = async (id: number): Promise<ThumbnailInterface[]> => {
  return await contentStore.fetchThumbnail(id);
};

export const fetchDubbingList = async (id: number): Promise<DubbingInterface[]> => {
  return await contentStore.getDubbingList(id);
};

export const fetchContentDetailData = async (id: number): Promise<FetchContentDetailDataReturn> => {
  const fetchedContent = await fetchContent(id);
  const [
    fetchedCategories,
    fetchedPeople,
    fetchedSubtitleList,
    fetchedThumbnailList,
    fetchedDubbingList,
  ] = await Promise.all([
    fetchCategories(fetchedContent?.categories || []),
    fetchPeople(),
    fetchSubtitleList(id),
    fetchThumbnailList(id),
    fetchDubbingList(id),
  ]);

  return {
    fetchedContent,
    fetchedCategories,
    fetchedPeople,
    fetchedSubtitleList,
    fetchedThumbnailList,
    fetchedDubbingList,
  };
};
