import { PeopleInterface } from 'content/type';
import { peopleApi } from '../apis/peopleApi';
import { BaseStore } from 'store/baseStore';

class PeopleStore extends BaseStore<PeopleInterface> {
  public init = async (): Promise<void> => {
    const people = await peopleApi.fetch();
    this.setItems(people.results);
  };

  public createFormData = async (formData: FormData): Promise<void> => {
    await peopleApi.createFormData(formData);
  };

  public updateFormData = async (id: number, formData: FormData): Promise<void> => {
    await peopleApi.updateFormData(id, formData);
  };

  public delete = async (id: number): Promise<void> => {
    await peopleApi.delete(id);
  };
}

export const peopleStore = new PeopleStore(peopleApi);
