import React from 'react';
import { observer } from 'mobx-react';
import { Button, Card, Form, Input, List, Progress } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { uploadVideoStore } from '../../../store/uploadVideoStore';
import { UploadVideoInterface } from '../../../type/uploadVideoType'; // 추가된 CSS 파일
import './List.css';

interface UploadVideoListProps {
  onRemove: (uploadVideo: UploadVideoInterface) => void; // 파일 삭제를 처리하기 위한 핸들러 추가
  onChangeMetadata: (
    uploadVideo: UploadVideoInterface,
    targetKey: string,
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

const UploadVideoList: React.FC<UploadVideoListProps> = ({ onRemove, onChangeMetadata }) => {
  return (
    <List
      itemLayout="vertical"
      dataSource={uploadVideoStore.getUploadVideoList()}
      renderItem={uploadVideo => (
        <Card className="upload-video-list-item" hoverable>
          <div className="file-info">
            <div className="file-name">{uploadVideo.name}</div>
            <div className="progress-bar">
              <Progress
                percent={uploadVideo.percent || 0}
                status={'active'}
                format={percent =>
                  `${(percent || 0).toFixed(2)}% / ${((uploadVideo.speed || 0) / 1024 / 1024).toFixed(2)} MB/s`
                }
              />
            </div>
          </div>
          <div className="actions">
            <Button
              type="text"
              icon={<DeleteOutlined />}
              onClick={() => onRemove(uploadVideo)} // 삭제 버튼 클릭 시 onRemove 함수 호출
            />
          </div>
          <div className="video-meta">
            <div className="video-info">
              <Form.Item label="Title">
                <Input
                  type="text"
                  value={uploadVideo.title}
                  onChange={e => onChangeMetadata(uploadVideo, 'title', e)}
                />
              </Form.Item>
              <Form.Item label="Description">
                <Input.TextArea
                  value={uploadVideo.description}
                  onChange={e => onChangeMetadata(uploadVideo, 'description', e)}
                />
              </Form.Item>
            </div>
          </div>
        </Card>
      )}
    />
  );
};

export default observer(UploadVideoList);
