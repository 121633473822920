import React from 'react';
import { FormInstance, message } from 'antd';
import { DubbingInterface } from 'content/type';
import { RcFile } from 'antd/es/upload';
import { contentStore } from 'content/store';
import axios from 'axios';

interface DubbingHandlersProps {
  form: FormInstance;
  contentId: number;
  setDataSource: React.Dispatch<React.SetStateAction<DubbingInterface[]>>;
}

interface DubbingHandlers {
  handleLanguageChange: (value: string, record: { id: string }) => void;
  handleFileChange: (info: RcFile, record: { id: string }) => void;
  handleActiveChange: (value: boolean, record: { id: string }) => void;
  handleAddDubbing: (id: number) => void;
  handleUpdateDubbing: () => void;
}

const dubbingHandlers = ({
  form,
  contentId,
  setDataSource,
}: DubbingHandlersProps): DubbingHandlers => {
  const handleLanguageChange = (value: string, record: { id: string }): void => {
    setDataSource((prev: DubbingInterface[]) => {
      const newDubbing = prev.map(dubbing =>
        dubbing.id === Number(record.id) ? { ...dubbing, lang: value } : dubbing
      );
      form.setFieldValue('dubbingList', newDubbing);
      return newDubbing;
    });
  };

  const handleFileChange = (info: RcFile, record: { id: string }): void => {
    if (info) {
      setDataSource((prev: DubbingInterface[]) => {
        const newDubbing = prev.map(dubbing =>
          dubbing.id === Number(record.id) ? { ...dubbing, file: info } : dubbing
        );
        form.setFieldValue('dubbingList', newDubbing);
        return newDubbing;
      });
    }
  };

  const handleActiveChange = (value: boolean, record: { id: string }): void => {
    setDataSource((prev: DubbingInterface[]) => {
      const newDubbing = prev.map(dubbing =>
        dubbing.id === Number(record.id) ? { ...dubbing, active: value } : dubbing
      );
      form.setFieldValue('dubbingList', newDubbing);
      return newDubbing;
    });
  };

  const handleAddDubbing = (id: number) => {
    setDataSource(prev => {
      const newDubbing = [
        ...prev,
        {
          id,
          lang: '',
          file: undefined,
          created: new Date().toISOString(),
          modified: new Date().toISOString(),
          // eslint-disable-next-line camelcase
          content_id: contentId,
          // eslint-disable-next-line camelcase
          external_url: '',
        },
      ];
      form.setFieldValue('dubbingList', newDubbing);
      return newDubbing;
    });
  };

  const handleUpdateDubbing = async (): Promise<void> => {
    const dubbingList = form.getFieldValue('dubbingList') || [];
    try {
      await contentStore.uploadDubbing(contentId, dubbingList);
      message.success('upload success');
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 400) {
        message.error('Failed to update : ' + JSON.stringify(error.response.data));
      }
    }
  };

  return {
    handleUpdateDubbing,
    handleLanguageChange,
    handleFileChange,
    handleActiveChange,
    handleAddDubbing,
  };
};

export default dubbingHandlers;
