import { peopleStore } from 'content/store';
import { PeopleInterface } from 'content/type';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Component from '../../../../shared/components';
import tableHandlers from './handlers/tableHandlers';
import { getPeopleColumns } from './PeopleColumns';

export default function People(): React.JSX.Element {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const [refreshCounter, setRefreshCounter] = useState(0);

  const { handleOnClickDeleteButton, handleOnClickTitle, handleOnClickSetting, handleAddClick } =
    tableHandlers({
      navigate,
    });

  const columns = getPeopleColumns(
    handleOnClickTitle,
    handleOnClickDeleteButton,
    handleOnClickSetting
  );

  const fetchPeopleTableData = useCallback(
    async (page: number, pageSize: number): Promise<{ data: PeopleInterface[]; count: number }> => {
      const peopleList = await peopleStore.fetch({
        page: page.toString(),
        page_size: pageSize.toString(),
        search: searchTerm,
      });
      const totalCount = peopleStore.getTotalCount();
      return { data: peopleList, count: totalCount };
    },
    [searchTerm]
  );

  useEffect(() => {
    setRefreshCounter(prev => prev + 1);
  }, [searchTerm]);

  return (
    <Component.BasePageComponent>
      <Component.TableComponent<PeopleInterface>
        key={refreshCounter}
        tableName="People"
        searchTerm={searchTerm}
        dataFetch={fetchPeopleTableData}
        columns={columns}
        handleAddClick={handleAddClick}
        handleSearchTerm={setSearchTerm}
      />
    </Component.BasePageComponent>
  );
}
