import { BaseApi } from 'api/baseApi';
import { RevenueInterface } from 'content/type';
import { revenueApi } from '../api';

class RevenueStore {
  private items: RevenueInterface[] = [];
  constructor(private revenueApi: BaseApi<RevenueInterface>) {}

  public async init(): Promise<void> {
    const response = await this.revenueApi.fetch();
    this.setItems(response.results);
  }
  public async fetch(
    queryParams?: Record<string, string>
  ): Promise<{ data: RevenueInterface[]; count: number }> {
    const { results, count } = await this.revenueApi.fetch(queryParams);
    return { data: results, count };
  }

  public async setItems(items: RevenueInterface[]): Promise<void> {
    this.items = items;
  }

  public getItems(): RevenueInterface[] {
    return this.items;
  }
}

export const revenueStore = new RevenueStore(revenueApi);
