import { DeleteOutlined, SettingOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import { TableColumnInterface } from 'components/table/Table';
import { SeasonInterface } from 'content/type';
import React from 'react';

interface SeasonColumnsProps {
  handleOnClickTitle: (id: number) => void;
  handleOnClickSetting: (id: number) => void;
  onClickDeleteButton: (id: number) => void;
}

export const getSeasonColumns = ({
  handleOnClickTitle,
  handleOnClickSetting,
  onClickDeleteButton,
}: SeasonColumnsProps): TableColumnInterface<SeasonInterface>[] => {
  return [
    {
      title: 'POSTER',
      dataIndex: 'poster',
      key: 'poster',
      width: '5%',
      render: (_text: string, record: SeasonInterface) => (
        <img
          style={{ marginLeft: '10px', width: '100%', height: 'auto' }}
          src={record.poster?.file}
          alt=""
        />
      ),
    },
    {
      title: 'TITLE',
      dataIndex: 'title',
      key: 'title',
      width: '15%',
      render: (text: string, record: SeasonInterface) => (
        <a href="#" className="season-title" onClick={() => handleOnClickTitle(record.id)}>
          {text}
        </a>
      ),
    },
    {
      title: 'SYNOPSIS',
      dataIndex: 'synopsis',
      key: 'synopsis',
      width: '30%',
      render: (text: string) => (
        <span>{text ? (text.length > 100 ? text.slice(0, 100) + '...' : text) : ''}</span>
      ),
    },
    {
      title: 'SEASON NUMBER',
      dataIndex: 'season_number',
      key: 'season_number',
      width: '5%',
    },
    {
      title: 'CAST',
      dataIndex: 'cast',
      key: 'cast',
    },
    {
      title: 'REALEASE DATE START',
      dataIndex: 'release_date_start',
      key: 'release_date_start',
    },
    {
      title: 'REALEASE DATE END',
      dataIndex: 'release_date_end',
      key: 'release_date_end',
    },
    {
      title: 'ACTIONS',
      key: 'actions',
      dataIndex: 'actions',
      render: (_text: string, record: SeasonInterface) => (
        <Space>
          <Button
            icon={<SettingOutlined />}
            onClick={() => handleOnClickSetting(record.id)}
            className="action-btn"
          />
          <Button
            icon={<DeleteOutlined />}
            onClick={() => onClickDeleteButton(record.id)}
            className="action-btn delete-btn"
          />
        </Space>
      ),
    },
  ];
};
