import { Form } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as Component from '../../../../shared/components';
import * as CategoryComponent from './components';
import { detailHandlers } from './handlers';

const CategoryAdd: React.FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { handleOnClickAddButtonClick } = detailHandlers({ id: '0', form, navigate });

  return (
    <Component.BasePageComponent>
      <CategoryComponent.DetailComponent
        form={form}
        actionTitle="ADD"
        handleOnClickButtonClick={handleOnClickAddButtonClick}
      />
    </Component.BasePageComponent>
  );
};

export default observer(CategoryAdd);
