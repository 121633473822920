import React, { useEffect, useState } from 'react';
import { THUMBNAIL_UPLOAD_STATE } from 'constants/index';
import { Button, Form, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { ThumbnailInterface } from 'content/type';
import { thumbnailHandlers } from 'content/page/content/handlers';
import { ThumbnailListComponent } from './components';
import './Thumbnail.css';

interface ThumbnailProps {
  contentId: number;
  uploadedThumbnailList: ThumbnailInterface[];
}

const Thumbnail: React.FC<ThumbnailProps> = ({ uploadedThumbnailList, contentId }) => {
  const [form] = Form.useForm();
  const [thumbnailList, setThumbnailList] = useState<ThumbnailInterface[]>([]);
  const [selectedThumbnail, setSelectedThumbnail] = useState<ThumbnailInterface | null>(null);
  const { handleChange, handleThumbnailRemove, handleThumbnailSelect, handleThumbnailUpload } =
    thumbnailHandlers({
      form,
      contentId,
      selectedThumbnail,
      setThumbnailList,
      setSelectedThumbnail,
    });

  useEffect(() => {
    const initThumbnailList: ThumbnailInterface[] = uploadedThumbnailList.map(
      (thumbnail: ThumbnailInterface) => {
        return {
          ...thumbnail,
          state: THUMBNAIL_UPLOAD_STATE.UPLOADED,
          originFileObj: undefined,
        };
      }
    );
    form.setFieldValue('uploadThumbnailList', []);
    form.setFieldValue('uploadedThumbnailList', initThumbnailList);
    form.setFieldValue('thumbnailList', initThumbnailList);
    setThumbnailList(initThumbnailList);

    const selectedThumbnail = initThumbnailList.find(
      (thumbnail: ThumbnailInterface) => thumbnail.is_representative
    );
    setSelectedThumbnail(selectedThumbnail || null);
  }, [form, uploadedThumbnailList]);

  return (
    <div className="thumbnail-container">
      <div style={{ display: 'flex' }}>
        <div className="thumbnail-preview-container">
          <span> Selected Thumbnail </span>
          <img
            src={selectedThumbnail ? selectedThumbnail.file : ''}
            alt=""
            className="thumbnail-preview"
          />
        </div>
        <div className="thumbnail-uploaded-container">
          <Upload
            key={'upload-thumbnail'}
            accept="image/*"
            multiple={true}
            showUploadList={false}
            beforeUpload={() => {
              return false; // 자동 업로드 방지
            }}
            onChange={handleChange}
            listType="text"
          >
            <Button icon={<UploadOutlined />} style={{ width: '100%', marginBottom: '10px' }}>
              {`Thumbnail Upload`}
            </Button>
          </Upload>
          <ThumbnailListComponent
            thumbnails={thumbnailList}
            selectedThumbnail={selectedThumbnail}
            onThumbnailSelect={handleThumbnailSelect}
            onThumbnailRemove={handleThumbnailRemove}
          />
        </div>
      </div>
      <Form.Item style={{ marginTop: 16, textAlign: 'center' }}>
        <Button type="primary" onClick={() => handleThumbnailUpload()}>
          Save
        </Button>
      </Form.Item>
    </div>
  );
};

export default Thumbnail;
