import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, FormInstance, Switch, Upload, UploadFile } from 'antd';
import { UploadChangeParam } from 'antd/es/upload';
import React, { useEffect } from 'react';
import { uploadVideoStore } from '../../../store/uploadVideoStore';
import { UploadVideoInterface } from '../../../type/uploadVideoType';
import UploadVideoList from '../list/List';
import './Form.css';

interface UploadVideoFormProps {
  form: FormInstance<UploadVideoInterface>;
  handleChange: (info: UploadChangeParam<UploadFile<UploadVideoInterface>>) => void;
  handleRemove: (file: UploadVideoInterface) => void;
  onChangeMetadata: (
    uploadVideo: UploadVideoInterface,
    targetKey: string,
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

const UploadVideoForm: React.FC<UploadVideoFormProps> = ({
  form,
  handleChange,
  handleRemove,
  onChangeMetadata,
}) => {
  useEffect(() => {
    uploadVideoStore.clearUploadVideoList();
  }, []);

  return (
    <Form form={form} layout="vertical" className="upload-video-form">
      <Form.Item name="autoTranscode">
        <Switch checkedChildren="Auto Transcode" unCheckedChildren="Auto Transcode" />
      </Form.Item>
      <Upload
        multiple
        accept="video/*"
        onChange={handleChange}
        beforeUpload={() => false}
        fileList={uploadVideoStore.getUploadVideoList()}
        showUploadList={false}
      >
        <Button style={{ marginBottom: '16px' }} icon={<UploadOutlined />}>
          Select File
        </Button>
      </Upload>
      <UploadVideoList onRemove={handleRemove} onChangeMetadata={onChangeMetadata} />
      <p>
        Get started by selecting a video file to upload. After your upload is finished you can add
        metadata to your video.
      </p>
    </Form>
  );
};

export default UploadVideoForm;
