import {
  ContentInterface,
  DubbingInterface,
  RevenueInterface,
  SubtitleInterface,
  ThumbnailInterface,
  UploadThumbnailInterface,
} from 'content/type';
import { BaseStore } from 'store/baseStore';
import { contentApi } from '../apis/contentApi';

class ContentStore extends BaseStore<ContentInterface> {
  private thumbnailFileList: ThumbnailInterface[] = [];

  public async getContentListFromIds(ids: number[]): Promise<ContentInterface[]> {
    if (!ids.every(id => this.getItems().some(content => content.id === id)))
      return (await this.service.fetchByIds!(ids)).results || [];
    return this.getItems().filter(content => ids.includes(content.id || -1));
  }

  public async fetchByIds(ids: number[]): Promise<ContentInterface[]> {
    return (await contentApi.fetchByIds(ids)).results || [];
  }

  public async fetchContent(id: number): Promise<ContentInterface | undefined> {
    const content = await contentApi.fetchById(id);
    if (content) {
      const existingContentIndex = this.getItems().findIndex(item => item.id === content.id);
      if (existingContentIndex !== -1) {
        this.updateItem(content);
      } else {
        this.addItem(content);
      }
    }
    return content;
  }

  public async updateContent(content: Partial<ContentInterface>): Promise<void> {
    await contentApi.update(content.id!, content);
  }

  public async getSubtitleList(contentId: number): Promise<SubtitleInterface[]> {
    return await contentApi.fetchSubtitleList(contentId);
  }

  public async uploadSubtitle(contentId: number, subtitles: SubtitleInterface[]): Promise<void> {
    await Promise.all(
      subtitles.map((subtitle: SubtitleInterface) => {
        const formData = new FormData();
        if (subtitle.file) {
          formData.append('file', subtitle.file);
        }
        return contentApi.uploadSubtitle(contentId, formData);
      })
    );
  }

  public async getDubbingList(contentId: number): Promise<DubbingInterface[]> {
    const dubbingList = await contentApi.fetchDubbingList(contentId);
    return dubbingList.results;
  }

  public async uploadDubbing(contentId: number, dubbings: DubbingInterface[]): Promise<void> {
    await Promise.all(
      dubbings.map((dubbing: DubbingInterface) => {
        const formData = new FormData();
        if (dubbing.file) {
          formData.append('file', dubbing.file);
        }
        return contentApi.uploadDubbing(contentId, formData);
      })
    );
  }

  public async fetchThumbnail(contentId: number): Promise<ThumbnailInterface[]> {
    this.thumbnailFileList = await contentApi.fetchThumbnailList(contentId);
    return this.thumbnailFileList;
  }

  public async uploadThumbnail(
    contentId: number,
    thumbnails: UploadThumbnailInterface[]
  ): Promise<void> {
    await Promise.all(
      thumbnails.map((thumbnail: UploadThumbnailInterface) => {
        const formData = new FormData();
        if (thumbnail.file) {
          formData.append('file', thumbnail.file);
        }
        if (thumbnail.is_representative) {
          formData.append('is_representative', thumbnail.is_representative.toString());
        }
        return contentApi.uploadThumbnail(contentId, formData);
      })
    );
  }

  public async removeThumbnail(contentId: number, thumbnails: ThumbnailInterface[]): Promise<void> {
    await Promise.all(
      thumbnails.map(thumbnail => contentApi.removeThumbnail(contentId, thumbnail.id))
    );
  }

  public async updateThumbnail(contentId: number, thumbnail: ThumbnailInterface): Promise<void> {
    await contentApi.updateThumbnail(contentId, thumbnail);
  }

  public async videoTranscode(id: number): Promise<void> {
    await contentApi.videoTranscode(id);
  }

  public async fetchRevenueShareData(
    queryString: Record<string, string>
  ): Promise<{ data: RevenueInterface[]; count: number }> {
    const { results, count } = await contentApi.fetchRevenueShareData(queryString);
    return { data: results, count };
  }
}

export const contentStore = new ContentStore(contentApi);
