import { BaseApi } from 'api/baseApi';
import axios from 'axios';
import { BASE_URL, PEOPLE_API_PATH } from 'constants/api';
import { PeopleInterface } from 'content/type';

class PeopleApi extends BaseApi<PeopleInterface> {
  public fetchById = async (id: number): Promise<PeopleInterface> => {
    const response = await axios.get(`${BASE_URL}${PEOPLE_API_PATH}${id}/`);
    return response.data;
  };

  public create = async (people: PeopleInterface): Promise<PeopleInterface> => {
    const response = await axios.post(`${BASE_URL}${PEOPLE_API_PATH}`, people);
    return response.data;
  };

  public update = async (id: number, people: PeopleInterface): Promise<PeopleInterface> => {
    const response = await axios.patch(`${BASE_URL}${PEOPLE_API_PATH}${id}/`, people);
    return response.data;
  };

  public delete = async (id: number): Promise<void> => {
    const response = await axios.delete(`${BASE_URL}${PEOPLE_API_PATH}${id}/`);
    return response.data;
  };

  public createFormData = async (formData: FormData): Promise<PeopleInterface> => {
    const response = await axios.post(`${BASE_URL}${PEOPLE_API_PATH}`, formData);
    return response.data;
  };

  public updateFormData = async (id: number, formData: FormData): Promise<PeopleInterface> => {
    const response = await axios.patch(`${BASE_URL}${PEOPLE_API_PATH}${id}/`, formData);
    return response.data;
  };
}

export const peopleApi = new PeopleApi(PEOPLE_API_PATH);
