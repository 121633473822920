export const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:8001';

export const PLAYLIST_API_PATH = '/api/v1/playlist/';

export const PLAYLIST_ITEM_API_PATH = '/api/v1/playlist-item/';

export const CONTENT_API_PATH = '/api/v1/content/';
export const REVENUE_API_PATH = '/api/v1/revenues/';

export const DIRECT_UPLOAD_MULTIPART_START_API_PATH =
  '/api/v1/content/direct-upload-multipart/start/';
export const DIRECT_UPLOAD_MULTIPART_COMPLETE_API_PATH =
  '/api/v1/content/direct-upload-multipart/complete/';
export const DIRECT_UPLOAD_MULTIPART_PRESIGNED_URL_API_PATH =
  '/api/v1/content/direct-upload-multipart/pre-signed-url/';

export const VIDEO_SOURCE_API_PATH = '/api/v1/video-source/';

export const CATEGORY_API_PATH = '/api/v1/category/';

export const SEASON_API_PATH = '/api/v1/season/';

export const SERIES_API_PATH = '/api/v1/series/';

export const SEASON_SUB_PATH = '/seasons/';

export const PEOPLE_API_PATH = '/api/v1/cast/';

export const CONTRACT_API_PATH = '/api/v1/contracts/';

export const CONTRACT_UPLOAD_API_PATH = '/api/v1/contracts/upload/';

export const CONTENT_SUB_PATH = '/contents/';

export const REFRESH_API_PATH = '/api/v1/accounts/token/refresh/';

export const ADMIN_USER_API_PATH = '/api/v1/accounts/admin/users/';

export const USER_API_PATH = '/api/v1/accounts/user/';

export const LOGIN_API_PATH = '/api/v1/accounts/login/';

export const LOGOUT_API_PATH = '/api/v1/accounts/logout/';

export const CONTENT_PROVIDER_API_PATH = '/api/v1/projects/';

export const LICENSEE_API_PATH = '/api/v1/projects/';
