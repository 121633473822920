import { ContentInterface } from 'content/type';

interface ContentListHook {
  filterOption: (input: string, item: ContentInterface) => boolean;
}

const contentListHandlers = (): ContentListHook => {
  const filterOption = (input: string, item: ContentInterface): boolean =>
    item.title?.includes(input);

  return {
    filterOption,
  };
};

export default contentListHandlers;
