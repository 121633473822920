import axios from 'axios';
import authService from '../shared/service/authService';
import { REFRESH_API_PATH } from 'constants/api';

axios.interceptors.request.use(config => {
  const token = localStorage.getItem('authToken');
  if (config.url!.includes('s3')) return config;

  config.withCredentials = true;
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

axios.interceptors.response.use(
  async response => {
    return response;
  },
  async error => {
    const originalRequest = error.config;
    if (error.response.status === 401) {
      originalRequest._retry = true;
      if (!(originalRequest.url as string).includes(REFRESH_API_PATH)) {
        try {
          const refreshResponse = await authService.refreshToken();
          localStorage.setItem('authToken', refreshResponse.access);
          return axios(originalRequest);
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (refreshError) {
          await authService.clearAuthInfo();
          window.location.href = '/login';
        }
      }
      await authService.clearAuthInfo();
      window.location.href = '/login';
    }
    throw error;
  }
);
