import { BaseApi } from 'api/baseApi';
import axios from 'axios';
import { BASE_URL, PLAYLIST_API_PATH } from 'constants/api';
import { PlaylistInterface } from '../type/playlistType';

class PlaylistApi extends BaseApi<PlaylistInterface> {
  public async fetchById(id: number): Promise<PlaylistInterface> {
    const response = await axios.get(`${BASE_URL}${PLAYLIST_API_PATH}${id}/`);
    return response.data;
  }

  public async create(playlist: PlaylistInterface): Promise<PlaylistInterface> {
    const response = await axios.post(`${BASE_URL}${PLAYLIST_API_PATH}`, playlist);
    return response.data;
  }

  public async delete(id: number): Promise<void> {
    await axios.delete(`${BASE_URL}${PLAYLIST_API_PATH}${id}/`);
  }

  public async update(
    id: number,
    playlist: Partial<PlaylistInterface>
  ): Promise<PlaylistInterface> {
    const response = await axios.patch(`${BASE_URL}${PLAYLIST_API_PATH}${id}/`, playlist);
    return response.data;
  }
}

export const playlistApi = new PlaylistApi(PLAYLIST_API_PATH);
