import { FormInstance } from 'antd';
import { categoryStore } from 'content/store/categoryStore';
import { NavigateFunction } from 'react-router-dom';

interface DetailHandlersProps {
  id: string;
  form: FormInstance;
  navigate: NavigateFunction;
}

interface DetailHandlers {
  handleOnClickAddButtonClick: () => Promise<void>;
  handleOnClickEditButtonClick: () => Promise<void>;
  handleOnClickDeleteButtonClick: (id: number) => Promise<void>;
}

const detailHandlers = ({ id, form, navigate }: DetailHandlersProps): DetailHandlers => {
  const handleOnClickAddButtonClick = async (): Promise<void> => {
    const category = form.getFieldsValue();
    category.name = category.name.toLowerCase();
    await categoryStore.create(category);
    navigate('/category');
  };

  const handleOnClickEditButtonClick = async (): Promise<void> => {
    const category = form.getFieldsValue();
    category.name = category.name.toLowerCase();
    await categoryStore.update(Number(id), category);
    navigate('/category');
  };

  const handleOnClickDeleteButtonClick = async (id: number): Promise<void> => {
    await categoryStore.delete(id);
  };

  return {
    handleOnClickAddButtonClick,
    handleOnClickEditButtonClick,
    handleOnClickDeleteButtonClick,
  };
};

export default detailHandlers;
