import { BaseApi } from 'api/baseApi';
import axios from 'axios';
import { BASE_URL, CATEGORY_API_PATH } from 'constants/api';
import { CategoryInterface } from 'content/type';
import { FetchResult } from 'type/baseApiType';

class CategoryApi extends BaseApi<CategoryInterface> {
  public fetch = async (): Promise<FetchResult<CategoryInterface>> => {
    const fetchPage = async (page: number): Promise<FetchResult<CategoryInterface>> => {
      const response = await axios.get(`${BASE_URL}${CATEGORY_API_PATH}?page=${page}`);
      return response.data;
    };
    let page = 1;
    let hasNextPage = true;

    const allResults = [];

    while (hasNextPage) {
      const data = await fetchPage(page);
      allResults.push(...data.results);
      hasNextPage = data.next !== null;
      page++;
    }
    return {
      count: allResults.length,
      next: null,
      previous: null,
      // eslint-disable-next-line camelcase
      page_next: null,
      // eslint-disable-next-line camelcase
      page_previous: null,
      // eslint-disable-next-line camelcase
      page_size: allResults.length,
      results: allResults,
    };
  };

  public fetchById = async (id: number): Promise<CategoryInterface> => {
    const response = await axios.get(`${BASE_URL}${CATEGORY_API_PATH}${id}/`);
    return response.data;
  };

  public create = async (category: CategoryInterface): Promise<CategoryInterface> => {
    const response = await axios.post(`${BASE_URL}${CATEGORY_API_PATH}`, category);
    return response.data;
  };

  public update = async (id: number, category: CategoryInterface): Promise<CategoryInterface> => {
    const response = await axios.patch(`${BASE_URL}${CATEGORY_API_PATH}${id}/`, category);
    return response.data;
  };

  public delete = async (categoryId: number): Promise<void> => {
    await axios.delete(`${BASE_URL}${CATEGORY_API_PATH}${categoryId}/`);
  };

  public getCategoryList = async (categoryIds: number[]): Promise<CategoryInterface[]> => {
    const response = await Promise.all(
      categoryIds.map(id => axios.get(`${BASE_URL}${CATEGORY_API_PATH}${id}/`))
    );
    return response.map(res => res.data);
  };
}

export const categoryApi = new CategoryApi(CATEGORY_API_PATH);
