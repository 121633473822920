import React, { useEffect, useState } from 'react';
import { THUMBNAIL_UPLOAD_STATE } from 'constants/index';
import { Button, Form, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { PosterInterface } from 'content/type';
import { posterHandlers } from 'content/page/season/handlers';
import { PosterListComponent } from './components';
import './Poster.css';

interface PosterProps {
  seasonId: number;
  uploadedPosterList: PosterInterface[];
}

const Poster: React.FC<PosterProps> = ({ uploadedPosterList, seasonId }) => {
  const [form] = Form.useForm();
  const [posterList, setPosterList] = useState<PosterInterface[]>([]);
  const [selectedPoster, setSelectedPoster] = useState<PosterInterface | null>(null);
  const { handleChange, handlePosterRemove, handlePosterSelect, handlePosterUpload } =
    posterHandlers({
      form,
      seasonId,
      selectedPoster,
      setPosterList,
      setSelectedPoster,
    });

  useEffect(() => {
    const initPosterList: PosterInterface[] = uploadedPosterList.map((poster: PosterInterface) => {
      return {
        ...poster,
        state: THUMBNAIL_UPLOAD_STATE.UPLOADED,
        originFileObj: undefined,
      };
    });
    form.setFieldValue('uploadPosterList', []);
    form.setFieldValue('uploadedPosterList', initPosterList);
    form.setFieldValue('posterList', initPosterList);
    setPosterList(initPosterList);

    const selectedPoster = initPosterList.find(
      (poster: PosterInterface) => poster.is_representative
    );
    setSelectedPoster(selectedPoster || null);
  }, [form, uploadedPosterList]);

  return (
    <div className="poster-container">
      <div style={{ display: 'flex' }}>
        <div className="poster-preview-container">
          <span> Selected Poster </span>
          <img src={selectedPoster ? selectedPoster.file : ''} alt="" className="poster-preview" />
        </div>
        <div className="poster-uploaded-container">
          <Upload
            key={'upload-poster'}
            accept="image/*"
            multiple={true}
            showUploadList={false}
            beforeUpload={() => {
              return false; // 자동 업로드 방지
            }}
            onChange={handleChange}
            listType="text"
          >
            <Button icon={<UploadOutlined />} style={{ width: '100%', marginBottom: '10px' }}>
              {`Poster Upload`}
            </Button>
          </Upload>
          <PosterListComponent
            posters={posterList}
            selectedPoster={selectedPoster}
            onPosterSelect={handlePosterSelect}
            onPosterRemove={handlePosterRemove}
          />
        </div>
      </div>
      <Form.Item style={{ marginTop: 16, textAlign: 'center' }}>
        <Button type="primary" onClick={() => handlePosterUpload()}>
          Save
        </Button>
      </Form.Item>
    </div>
  );
};

export default Poster;
