import { contentProviderStore } from 'account/store/contentProviderStore';
import { Form } from 'antd';
import { TransferDataHandlerInterface } from 'components/transfer/Transfer';
import { contentStore, contractStore } from 'content/store';
import { contractContentItemStore } from 'content/store/contractContentStore';
import { ContentInterface } from 'content/type';
import { ContractInterface } from 'contract/type';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Component from '../../../../shared/components';
import * as ContractComponent from './components';
import { contentListHandlers, detailHandlers } from './handlers';
import { LicenseeInterface } from 'account/type/licenseeType';
import { licenseeStore } from 'account/store/licenseeStore';

const ContractEdit: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [licenseeList, setLicenseeList] = useState<LicenseeInterface[]>([]);
  const [contentProviderList, setContentProviderList] = useState<{ id: number; title: string }[]>(
    []
  );
  const [loading, setLoading] = useState(true); // 로딩 상태 추가
  const [contract, setContract] = useState<ContractInterface>();
  const { filterOption } = contentListHandlers();
  const { handleOnClickEditButtonClick } = detailHandlers({ id: id || '0', form, navigate });

  const fetchContentProvider = async (): Promise<void> => {
    const contentProviderData = await contentProviderStore.fetchContentProvider();
    setContentProviderList(contentProviderData);
  };

  const fetchLicensee = async (): Promise<void> => {
    const licenseeData = await licenseeStore.fetchLicensee();
    setLicenseeList(licenseeData);
  };

  const dataHandler: TransferDataHandlerInterface = {
    fetchSourceData: async (query: Record<string, string>) => {
      const extendedQuery = { ...query, has_contract: 'false' };
      const contents = await contentStore.fetch(extendedQuery);
      const transferDataSource = contents.map(content => ({
        id: content.id,
        key: content.id,
        name: content.title,
        disabled: false,
      }));
      return {
        data: transferDataSource,
        count: contentStore.getTotalCount(),
      };
    },
    fetchTargetData: async (query: Record<string, string>) => {
      const contractContentItems: ContentInterface[] =
        await contractContentItemStore.fetchByParentId(Number(id), query);

      return {
        data: contractContentItems.map(contractContentItem => ({
          id: contractContentItem.id,
          key: contractContentItem.id,
          name: contractContentItem.title,
          disabled: false,
        })),
        count: contractContentItemStore.getTotalCount(),
      };
    },
    createWithParentId: async (ids: number[]) => {
      await contractContentItemStore.createWithParentId(Number(id), ids);
    },
    deleteWithParentId: async (ids: number[]) => {
      await contractContentItemStore.deleteWithParentId(Number(id), ids);
    },
  };

  useEffect(() => {
    fetchContentProvider().then();
    fetchLicensee().then();
  }, []);

  useEffect(() => {
    const fetchContract = async (): Promise<void> => {
      await contractStore.fetchContractById(Number(id));
      const contract = await contractStore.getContractById(Number(id));
      if (contract) {
        setContract(contract);
        fetchContentProvider().then();
      }
      setLoading(false);
    };

    fetchContract().then();
  }, [id, form]);

  const contractComponents = [
    {
      key: 'detail',
      title: 'Detail',
      component: (
        <div>
          <ContractComponent.DetailComponent
            form={form}
            contentProviderList={contentProviderList}
            licenseeList={licenseeList}
            contract={contract}
            actionTitle="SAVE"
            handleOnClickButtonClick={handleOnClickEditButtonClick}
          />
        </div>
      ),
    },
    {
      key: 'videos',
      title: 'Videos',
      component: (
        <ContractComponent.ContractContentListComponent
          dataHandler={dataHandler}
          disabled={false}
          filterOption={filterOption}
        />
      ),
    },
  ];

  return (
    <Component.BasePageComponent>
      {loading ? (
        <Component.LoadingSpinnerComponent />
      ) : (
        <Component.TapComponent components={contractComponents} />
      )}
    </Component.BasePageComponent>
  );
};

export default ContractEdit;
