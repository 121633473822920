import { TableColumnInterface } from 'components/table/Table';
import { RevenueInterface } from 'content/type';
import React from 'react';

export const getRevenueShareColumns = (
  groupBy: string
): TableColumnInterface<RevenueInterface>[] => {
  const idColumn =
    groupBy === 'content'
      ? [
          {
            title: 'Episode ID',
            dataIndex: 'episode_id',
            key: 'episode_id',
            width: '5%',
          },
        ]
      : [];
  return [
    ...idColumn,
    {
      title: 'Year/Month',
      dataIndex: 'yearmon',
      key: 'yearmon',
      width: '8%',
    },
    {
      title: 'Content Provider',
      dataIndex: 'content_provider',
      key: 'content_provider',
      width: '10%',
    },
    {
      title: 'Title (KO)',
      dataIndex: 'title_ko',
      key: 'title_ko',
      width: '10%',
      render: (_, record): React.ReactNode => {
        return (
          <a
            href={
              groupBy === 'content'
                ? `/video/detail/${record.content_id}`
                : `/season/edit/${record.season_id}`
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {record.title_ko}
          </a>
        );
      },
    },
    {
      title: 'Title (EN)',
      dataIndex: 'title_en',
      key: 'title_en',
      width: '10%',
    },
    {
      title: 'Kocowa',
      dataIndex: 'is_kocowa',
      key: 'is_kocowa',
      width: '5%',
      render: (_text: string, record: RevenueInterface) => (record.is_kocowa ? 'True' : 'False'),
    },
    {
      title: 'Membership',
      dataIndex: 'membership',
      key: 'membership',
      width: '8%',
    },
    {
      title: 'Service',
      dataIndex: 'service_name',
      key: 'service_name',
      width: '8%',
    },
    {
      title: 'Box',
      dataIndex: 'is_box',
      key: 'is_box',
      width: '5%',
      render: (_text: string, record: RevenueInterface) => (record.is_box ? 'True' : 'False'),
    },
    {
      title: 'SVOD Revenue',
      dataIndex: 'svod_rev',
      key: 'svod_rev',
      width: '8%',
    },
    {
      title: 'AVOD Revenue',
      dataIndex: 'avod_rev',
      key: 'avod_rev',
      width: '8%',
    },
  ];
};
