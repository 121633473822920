import { BaseStore } from 'store/baseStore';
import { videoSourceApi } from '../apis/videoSourceApi';
import {
  VideoSourceInterface,
  VideoSourceSelectItemInterface,
  VideoSourceVideoListInterface,
} from '../type';

class VideoSourceStore extends BaseStore<VideoSourceInterface> {
  public async generate(id: number): Promise<void> {
    await videoSourceApi.generate(id);
  }

  public async bulkOdkSync(startFromBeginning: boolean): Promise<void> {
    await videoSourceApi.bulkOdkSync(startFromBeginning);
  }

  public async getODKItem(): Promise<VideoSourceInterface> {
    return await videoSourceApi.getODKItem();
  }

  public async getVideoSourceSelectItemList(): Promise<VideoSourceSelectItemInterface[]> {
    if (this.items.length === 0) {
      await this.fetch();
    }
    const items = this.items.map(item => ({
      id: item.id,
      name: item.name,
    }));
    return items;
  }

  public async fetchContentList(
    id: number,
    queryParams: Record<string, string>
  ): Promise<VideoSourceVideoListInterface[]> {
    if (id === 0) {
      const {
        results: videoList,
        count,
        page_size: pageSize,
      } = await videoSourceApi.getContentProviderVideoList(queryParams);
      this.setPageInfo(count, pageSize);
      // TODO: 현재는 Content Item List를 가져오는 것이 아니라, 비디오 리스트를 가져오는 것이라서 이렇게 처리함.
      // 추후 Content Item List를 가져오는 것으로 변경 필요
      return videoList.map(item => ({
        id: item.id,
        guid: 0,
        original_content_url: '',
        status: 'completed',
        video_source: 0,
        content: item,
      })) as VideoSourceVideoListInterface[];
    }
    const {
      results: videoList,
      count,
      page_size: pageSize,
    } = await videoSourceApi.getVideoList(id, queryParams);
    this.setPageInfo(count, pageSize);
    return videoList;
  }
}

export const videoSourceStore = new VideoSourceStore(videoSourceApi);
