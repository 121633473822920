import { ContentInterface } from 'content/type';
import { contractContentItemApi } from 'contract/api/contractContentItemApi';
import { BaseStore } from 'store/baseStore';

class ContractItemStore extends BaseStore<ContentInterface> {
  public async fetchByParentId(
    parentId: number,
    queryParams?: Record<string, string>
  ): Promise<ContentInterface[]> {
    // eslint-disable-next-line camelcase
    const { results, count, page_size } = await contractContentItemApi.fetchByParentId(
      parentId,
      queryParams
    );
    this.setItems(results);
    this.setPageInfo(count, page_size);
    return this.getItems();
  }

  public async createWithParentId(parentId: number, itemIds: number[]): Promise<void> {
    await contractContentItemApi.createWithParentId(parentId, itemIds);
  }

  public async deleteWithParentId(parentId: number, ids: number[]): Promise<void> {
    await contractContentItemApi.deleteWithParentId(parentId, ids);
  }
}

export const contractContentItemStore = new ContractItemStore(contractContentItemApi);
