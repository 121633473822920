import { DeleteOutlined, SettingOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import { TableColumnInterface } from 'components/table/Table';
import { PeopleInterface } from 'content/type';
import React from 'react';

export const getPeopleColumns = (
  handleOnClickTitle: (id: number) => void,
  handleOnClickDeleteButton: (id: number) => void,
  handleOnClickSetting: (id: number) => void
): TableColumnInterface<PeopleInterface>[] => {
  return [
    {
      title: 'THUMBNAIL',
      dataIndex: 'thumbnail',
      key: 'thumbnail',
      width: '10%',
      render: (_text: string, record: PeopleInterface) => (
        <img
          src={record.thumbnail || ''}
          alt=""
          className="people-thumbnail"
          style={{ width: '80%', height: 'auto' }}
        />
      ),
    },
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      render: (text: string, record: PeopleInterface) => (
        <a href="#" className="people-title" onClick={() => handleOnClickTitle(record.id)}>
          {text}
        </a>
      ),
    },
    {
      title: 'NAME_EN',
      dataIndex: 'name_en',
      key: 'name_en',
      render: (text: string, record: PeopleInterface) => (
        <a href="#" className="people-title" onClick={() => handleOnClickTitle(record.id)}>
          {text}
        </a>
      ),
    },
    {
      title: 'TYPE',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'BIRTHDATE',
      dataIndex: 'birthdate',
      key: 'birthdate',
    },
    {
      title: 'NATIONALITY',
      dataIndex: 'nationality',
      key: 'nationality',
    },
    {
      title: 'ACTIONS',
      key: 'actions',
      dataIndex: 'actions',
      render: (_text: string, record: PeopleInterface) => (
        <Space>
          <Button
            icon={<SettingOutlined />}
            onClick={() => handleOnClickSetting(record.id)}
            className="action-btn"
          />
          <Button
            icon={<DeleteOutlined />}
            onClick={() => handleOnClickDeleteButton(record.id)}
            className="action-btn delete-btn"
          />
        </Space>
      ),
    },
  ];
};
