import React from 'react';
import Upload, { RcFile } from 'antd/es/upload';
import { Button, Select, Switch } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { DUBBING_FILE_EXTENSION, LANGUAGE_LIST } from 'constants/index';
import { DubbingInterface } from 'content/type';
import { ColumnType } from 'antd/es/table';

interface DubbingColumnsProps {
  handleLanguageChange: (value: string, record: { id: string }) => void;
  handleFileChange: (file: RcFile, record: { id: string }) => void;
  handleActiveChange: (value: boolean, record: { id: string }) => void;
}

export const getDubbingColumns = ({
  handleLanguageChange,
  handleFileChange,
  handleActiveChange,
}: DubbingColumnsProps): ColumnType<DubbingInterface>[] => {
  return [
    {
      title: 'Lang',
      dataIndex: 'lang',
      key: 'lang',
      width: '10%',
      render: (text: string, record: DubbingInterface) => (
        <Select
          options={LANGUAGE_LIST.map(language => ({ label: language, value: language }))}
          style={{ width: '100%', marginBottom: '10px' }}
          onChange={value => handleLanguageChange(value, { id: record.id.toString() })}
          value={record.lang}
        />
      ),
    },
    {
      title: 'File',
      dataIndex: 'file',
      key: 'file',
      render: (file: RcFile | undefined, record: DubbingInterface) => (
        <Upload
          key={file?.uid || record.id.toString()}
          accept={DUBBING_FILE_EXTENSION.join(',')}
          beforeUpload={() => {
            return false; // 자동 업로드 방지
          }}
          onChange={info => handleFileChange(info.file as RcFile, { id: record.id.toString() })}
          listType="text"
          fileList={
            record.file
              ? typeof record.file === 'string'
                ? [{ uid: record.id.toString(), name: record.file, status: 'done' }]
                : [record.file]
              : []
          }
          maxCount={1}
        >
          <Button icon={<UploadOutlined />} style={{ width: '100%' }}>
            {`Dubbing file upload`}
          </Button>
        </Upload>
      ),
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      render: (checked: boolean, record: DubbingInterface) => (
        <Switch
          checked={checked}
          onChange={value => handleActiveChange(value, { id: record.id.toString() })}
        />
      ),
    },
    {
      title: 'Uploaded',
      dataIndex: 'size',
      key: 'size',
      render: (size: number, record: DubbingInterface): string => {
        if (record.file && size) {
          return `${(size / 1024).toFixed(2)} KB`;
        }
        return '-';
      },
    },
  ];
};
