import { message } from 'antd';
import { ContentStreamManifestInterface } from 'content/type';
import { contentStore } from 'content/store/contentStore';

interface TranscodeHandlersProps {
  id: number;
  setContent: React.Dispatch<React.SetStateAction<ContentStreamManifestInterface[]>>;
}

interface TranscodeHandlers {
  handleRefresh: () => Promise<void>;
  handleStartTranscode: () => Promise<void>;
}

const transcodeHandlers = ({ id, setContent }: TranscodeHandlersProps): TranscodeHandlers => {
  const handleRefresh = async (): Promise<void> => {
    const content = await contentStore.fetchById(id);
    setContent(content.content_stream_manifests || []);
  };

  const handleStartTranscode = async (): Promise<void> => {
    await contentStore.videoTranscode(id);
    message.success('Transcode started');
  };

  return { handleRefresh, handleStartTranscode };
};

export default transcodeHandlers;
