import React, { useCallback } from 'react';
import { Navigate, Outlet, Route, RouteObject, Routes } from 'react-router-dom';
import authService from 'service/authService';
import * as AccountPage from '../domain/account';
import * as ContentPage from '../domain/content';
import * as RevenuePage from '../domain/revenue';
import * as UploadPage from '../domain/upload';

const AppRoutes: React.FC = () => {
  const ProtectedRoute: React.FC = () => {
    const [isAuthenticated, setIsAuthenticated] = React.useState<boolean | null>(null);
    React.useEffect(() => {
      authService.isAuthenticated().then(isAuth => {
        setIsAuthenticated(isAuth);
      });
    }, []);

    if (isAuthenticated === null) {
      return null;
    }

    return isAuthenticated ? <Outlet /> : <Navigate to="/login" replace />;
  };

  const contentRoutes: RouteObject[] = [
    {
      path: '/',
      element: <ContentPage.ContentPage />,
    },
    {
      path: '/main',
      element: <ContentPage.ContentPage />,
    },
    {
      path: '/content',
      element: <ContentPage.ContentPage />,
    },
    {
      path: '/playlists',
      element: <ContentPage.PlaylistPage />,
    },
    {
      path: '/playlists/edit/:id',
      element: <ContentPage.PlaylistEditPage />,
    },
    {
      path: '/playlists/add',
      element: <ContentPage.PlaylistAddPage />,
    },
    {
      path: '/video/detail/:id',
      element: <ContentPage.ContentEditPage />,
    },
  ];
  const uploadRoutes: RouteObject[] = [
    {
      path: '/import-upload',
      element: <UploadPage.ImportUploadPage />,
    },
    {
      path: '/upload/video',
      element: <UploadPage.UploadVideoPage />,
    },
    {
      path: '/video-source',
      element: <UploadPage.VideoSourcePage />,
    },
    {
      path: '/video-source/add',
      element: <UploadPage.VideoSourceAddPage />,
    },
    {
      path: '/video-source/odk',
      element: <UploadPage.VideoSourceODKPage />,
    },
    {
      path: '/video-source/edit/:id',
      element: <UploadPage.VideoSourceEditPage />,
    },
    {
      path: '/video-source/content',
      element: <UploadPage.VideoSourceContentListPage />,
    },
  ];
  const userRoutes: RouteObject[] = [
    {
      path: '/user',
      element: <AccountPage.UserPage />,
    },
    {
      path: '/user/add',
      element: <AccountPage.UserAddPage />,
    },
    {
      path: '/user/edit/:id',
      element: <AccountPage.UserEditPage />,
    },
    {
      path: '/account',
      element: <AccountPage.AccountPage />,
    },
    {
      path: '/content-provider',
      element: <AccountPage.ContentProviderPage />,
    },
    {
      path: '/content-provider/add',
      element: <AccountPage.ContentProviderAddPage />,
    },
    {
      path: '/content-provider/edit/:id',
      element: <AccountPage.ContentProviderEditPage />,
    },
  ];
  const metadataStructureRoutes: RouteObject[] = [
    {
      path: '/series',
      element: <ContentPage.SeriesPage />,
    },
    {
      path: '/series/edit/:id',
      element: <ContentPage.SeriesEditPage />,
    },
    {
      path: '/series/add',
      element: <ContentPage.SeriesAddPage />,
    },
    {
      path: '/season',
      element: <ContentPage.SeasonPage />,
    },
    {
      path: '/season/add',
      element: <ContentPage.SeasonAddPage />,
    },
    {
      path: '/season/edit/:id',
      element: <ContentPage.SeasonEditPage />,
    },
    {
      path: '/people',
      element: <ContentPage.PeoplePage />,
    },
    {
      path: '/people/add',
      element: <ContentPage.PeopleAddPage />,
    },
    {
      path: '/people/edit/:id',
      element: <ContentPage.PeopleEditPage />,
    },
    {
      path: '/category',
      element: <ContentPage.CategoryPage />,
    },
    {
      path: '/category/add',
      element: <ContentPage.CategoryAddPage />,
    },
    {
      path: '/category/edit/:id',
      element: <ContentPage.CategoryEditPage />,
    },
    {
      path: '/contract',
      element: <ContentPage.ContractPage />,
    },
    {
      path: '/contract/add',
      element: <ContentPage.ContractAddPage />,
    },
    {
      path: '/contract/edit/:id',
      element: <ContentPage.ContractEditPage />,
    },
  ];

  const revenueRoutes: RouteObject[] = [
    {
      path: '/revenue-share',
      element: <RevenuePage.RevenueSharePage />,
    },
  ];

  const renderRoutes = useCallback(
    (routes: RouteObject[]) =>
      routes.map((route, index) => <Route key={index} path={route.path} element={route.element} />),
    []
  );

  return (
    <Routes>
      <Route path="/login" element={<AccountPage.LoginPage />} />
      <Route element={<ProtectedRoute />}>
        {renderRoutes(contentRoutes)}
        {renderRoutes(uploadRoutes)}
        {renderRoutes(userRoutes)}
        {renderRoutes(metadataStructureRoutes)}
        {renderRoutes(revenueRoutes)}
      </Route>
    </Routes>
  );
};

export default AppRoutes;
