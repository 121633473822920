import { Flex, TableColumnsType, TransferProps } from 'antd';
import {
  TransferDataHandlerInterface,
  TransferDataSourceInterface,
} from 'components/transfer/Transfer';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import * as Component from '../../../../../../shared/components';
import './ContentList.css';

interface ContractVideoListProps {
  disabled: boolean;
  dataHandler: TransferDataHandlerInterface;
  filterOption: TransferProps['filterOption'];
}

const columns: TableColumnsType<TransferDataSourceInterface> = [
  {
    dataIndex: 'id',
    title: 'ID',
    render: (id: number) => (
      <span>
        <Link to={`/video/detail/${id}`} target="_blank">
          {id}
        </Link>
      </span>
    ),
  },
  {
    dataIndex: 'name',
    title: 'Name',
  },
];

const ContractVideoList: React.FC<ContractVideoListProps> = ({
  disabled,
  dataHandler,
  filterOption,
}) => {
  return (
    <Flex align="start" gap="middle" vertical>
      <Component.TransferComponent
        dataHandler={dataHandler}
        disabled={disabled}
        filterOption={filterOption}
        columns={columns}
      />
    </Flex>
  );
};

export default observer(ContractVideoList);
