import { FormInstance } from 'antd';
import { NavigateFunction } from 'react-router-dom';
import { peopleStore } from '../../../store/peopleStore';

interface DetailHandlersProps {
  navigate: NavigateFunction;
  form: FormInstance;
}

interface DetailHandlers {
  handleOnClickAddButtonClick: () => Promise<void>;
  handleOnClickEditButtonClick: () => Promise<void>;
  handleOnClickDeleteButtonClick: (id: number) => Promise<void>;
  handleOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const detailHandlers = ({ form, navigate }: DetailHandlersProps): DetailHandlers => {
  const handleOnClickAddButtonClick = async (): Promise<void> => {
    const formData = new FormData();
    formData.append('name', form.getFieldValue('name'));
    if (form.getFieldValue('name_en')) {
      formData.append('name_en', form.getFieldValue('name_en'));
    }
    if (form.getFieldValue('birthdate')) {
      formData.append('birthdate', form.getFieldValue('birthdate'));
    }
    if (form.getFieldValue('nationality')) {
      formData.append('nationality', form.getFieldValue('nationality'));
    }
    if (form.getFieldValue('thumbnail')) {
      formData.append('thumbnail', form.getFieldValue('thumbnail'));
    }
    if (form.getFieldValue('type')) {
      formData.append('type', form.getFieldValue('type'));
    }

    await peopleStore.createFormData(formData);
    navigate('/people');
  };

  const handleOnClickEditButtonClick = async (): Promise<void> => {
    const formData = new FormData();
    formData.append('name', form.getFieldValue('name'));
    if (form.getFieldValue('name_en')) {
      formData.append('name_en', form.getFieldValue('name_en'));
    }
    if (form.getFieldValue('birthdate')) {
      formData.append('birthdate', form.getFieldValue('birthdate'));
    }
    if (form.getFieldValue('nationality')) {
      formData.append('nationality', form.getFieldValue('nationality'));
    }
    if (form.getFieldValue('thumbnail')) {
      formData.append('thumbnail', form.getFieldValue('thumbnail'));
    }
    if (form.getFieldValue('type')) {
      formData.append('type', form.getFieldValue('type'));
    }

    await peopleStore.updateFormData(form.getFieldValue('id'), formData);
    navigate('/people');
  };

  const handleOnClickDeleteButtonClick = async (id: number): Promise<void> => {
    await peopleStore.delete(id);
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files?.[0]) {
      form.setFieldsValue({ thumbnail: e.target.files[0] });
    }
  };

  return {
    handleOnClickAddButtonClick,
    handleOnClickEditButtonClick,
    handleOnClickDeleteButtonClick,
    handleOnChange,
  };
};

export default detailHandlers;
