import { Flex, TableColumnsType, TransferProps } from 'antd';
import * as Component from 'components';
import {
  TransferDataHandlerInterface,
  TransferDataSourceInterface,
} from 'components/transfer/Transfer';
import { observer } from 'mobx-react';
import React from 'react';
import './ContentList.css';

interface PlaylistVideoListProps {
  filterOption: TransferProps['filterOption'];
  dataHandler: TransferDataHandlerInterface;
}

const columns: TableColumnsType<TransferDataSourceInterface> = [
  {
    dataIndex: 'name',
    title: 'Name',
  },
];

const PlaylistVideoList: React.FC<PlaylistVideoListProps> = ({ filterOption, dataHandler }) => {
  return (
    <Flex align="start" gap="middle" vertical>
      <Component.TransferComponent
        disabled={false}
        dataHandler={dataHandler}
        filterOption={filterOption}
        columns={columns}
      />
    </Flex>
  );
};

export default observer(PlaylistVideoList);
