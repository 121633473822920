import type { GetProp, TableColumnsType, TableProps, TransferProps } from 'antd';
import { Modal, Table, Transfer } from 'antd';
import React, { useEffect, useState } from 'react';
import { TransferSearch } from './TransferSearch';

type TransferItem = GetProp<TransferProps, 'dataSource'>[number];
type TableRowSelection<T extends object> = TableProps<T>['rowSelection'];

export interface TransferDataSourceInterface {
  id: number;
  key: number;
  name: string;
  disabled: boolean;
}

export interface TransferDataHandlerInterface {
  fetchSourceData: (
    query: Record<string, string>
  ) => Promise<{ data: TransferDataSourceInterface[]; count: number }>;
  fetchTargetData: (
    query: Record<string, string>
  ) => Promise<{ data: TransferDataSourceInterface[]; count: number }>;
  createWithParentId: (ids: number[]) => Promise<void>;
  deleteWithParentId: (ids: number[]) => Promise<void>;
}

interface TableTransferProps extends TransferProps<TransferItem> {
  dataHandler: TransferDataHandlerInterface;
  columns: TableColumnsType<TransferDataSourceInterface>;
  size?: 'small' | 'middle' | 'large';
}

const CustomTransfer = (props: TableTransferProps): React.ReactElement => {
  const { columns, dataHandler, size = 'large', ...restProps } = props;
  const [pageSize, setPageSize] = useState(10);
  const [leftSelectedKeys, setLeftSelectedKeys] = useState<number[]>([]);
  const [rightSelectedKeys, setRightSelectedKeys] = useState<number[]>([]);

  const [transferDataSource, setTransferDataSource] = useState<TransferDataSourceInterface[]>([]);
  const [transferDataSourceTotal, setTransferDataSourceTotal] = useState(0);
  const [targetSource, setTargetSource] = useState<TransferDataSourceInterface[]>([]);
  const [targetSourceTotal, setTargetSourceTotal] = useState(0);

  const [targetPage, setTargetPage] = React.useState<number>(1);
  const [sourcePage, setSourcePage] = React.useState<number>(1);

  const [targetSearchTerm, setTargetSearchTerm] = React.useState<string>('');
  const [sourceSearchTerm, setSourceSearchTerm] = React.useState<string>('');

  const [refreshKey, setRefreshKey] = useState(0);

  const onPageChange = async (
    direction: 'left' | 'right',
    page: number,
    pageSize: number
  ): Promise<void> => {
    setPageSize(pageSize);
    if (direction === 'right') {
      setTargetPage(page);
      return;
    } else {
      setSourcePage(page);
    }
  };

  const onSearchHandler = async (direction: 'left' | 'right', value: string): Promise<void> => {
    if (direction === 'right') {
      setTargetSearchTerm(value);
      setTargetPage(1);
    } else {
      setSourceSearchTerm(value);
      setSourcePage(1);
    }
  };

  const onChange: TransferProps['onChange'] = async (
    _nextTargetKeys: TransferProps['targetKeys'],
    direction: string,
    moveKeys: TransferProps['targetKeys']
  ) => {
    const isMovingRight = direction === 'right';
    const confirmMessage = isMovingRight
      ? 'Would you like to register the selected items?'
      : 'Would you like to unregister the selected items?';

    Modal.confirm({
      title: 'Confirm',
      content: confirmMessage,
      okText: 'Confirm',
      cancelText: 'Cancel',
      onOk: async () => {
        if (isMovingRight) {
          await dataHandler.createWithParentId(moveKeys as number[]);
          setLeftSelectedKeys([]);
        } else {
          await dataHandler.deleteWithParentId(moveKeys as number[]);
          setRightSelectedKeys([]);
        }
        setRefreshKey(prev => prev + 1);
      },
    });
  };

  useEffect(() => {
    const currentSelectedKeys = leftSelectedKeys;
    const query = {
      page: sourcePage.toString(),
      page_size: pageSize.toString(),
      search: sourceSearchTerm,
    };

    dataHandler.fetchSourceData(query).then(({ data, count }) => {
      setTransferDataSource(data);
      setTransferDataSourceTotal(count);
    });
    setLeftSelectedKeys(currentSelectedKeys);
  }, [sourcePage, sourceSearchTerm, pageSize]);

  useEffect(() => {
    const currentSelectedKeys = rightSelectedKeys;
    const query = {
      page: targetPage.toString(),
      page_size: pageSize.toString(),
      search: targetSearchTerm,
    };
    dataHandler.fetchTargetData(query).then(({ data, count }) => {
      setTargetSource(data);
      setTargetSourceTotal(count);
    });
    setRightSelectedKeys(currentSelectedKeys);
  }, [targetPage, targetSearchTerm, pageSize, refreshKey]);

  return (
    <Transfer
      className="transfer-container"
      {...restProps}
      onSearch={onSearchHandler}
      onChange={onChange}
      showSelectAll={false}
      selectAllLabels={[' ', ' ']}
      titles={[
        `${leftSelectedKeys.length}/${transferDataSourceTotal} Total Items`,
        `${rightSelectedKeys.length}/${targetSourceTotal} Registered Items`,
      ]}
    >
      {({
        direction,
        onItemSelect,
        onItemSelectAll,
        selectedKeys: listSelectedKeys,
        disabled: listDisabled,
      }) => {
        const filteredDataSource = direction === 'left' ? transferDataSource : targetSource;
        const total = direction === 'left' ? transferDataSourceTotal : targetSourceTotal;
        const rowSelection: TableRowSelection<TransferItem> = {
          getCheckboxProps: () => ({ disabled: listDisabled }),
          onChange(selectedRowKeys) {
            const currentPageItems = filteredDataSource.map(item => item.id);

            if (direction === 'left') {
              const otherPagesSelected = leftSelectedKeys.filter(
                key => !currentPageItems.includes(key)
              );

              const newLeftKeys = [...otherPagesSelected, ...(selectedRowKeys as number[])];

              setLeftSelectedKeys(newLeftKeys);
              const currentPageSelected = selectedRowKeys.filter(key =>
                currentPageItems.includes(key as number)
              );
              onItemSelectAll(currentPageSelected, currentPageSelected.length > 0);
            } else {
              const otherPagesSelected = rightSelectedKeys.filter(
                key => !currentPageItems.includes(key)
              );

              const newRightKeys = [...otherPagesSelected, ...(selectedRowKeys as number[])];

              setRightSelectedKeys(newRightKeys);
              const currentPageSelected = selectedRowKeys.filter(key =>
                currentPageItems.includes(key as number)
              );
              onItemSelectAll(currentPageSelected, currentPageSelected.length > 0);
            }
          },
          selectedRowKeys:
            direction === 'left'
              ? leftSelectedKeys.filter(key => filteredDataSource.some(item => item.id === key))
              : rightSelectedKeys.filter(key => filteredDataSource.some(item => item.id === key)),
          selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],
        };

        return (
          <>
            <TransferSearch direction={direction} handleSearch={onSearchHandler} />
            <Table
              rowSelection={rowSelection}
              columns={columns}
              dataSource={filteredDataSource}
              size={size}
              pagination={{
                pageSize: pageSize,
                total: total,
                onChange: (page, pageSize) => {
                  onPageChange(direction, page, pageSize);
                },
              }}
              style={{ pointerEvents: listDisabled ? 'none' : undefined }}
              onRow={({ id }) => ({
                onClick: (): void => {
                  onItemSelect(id, listSelectedKeys.includes(id));
                },
              })}
            />
          </>
        );
      }}
    </Transfer>
  );
};

export default CustomTransfer;
