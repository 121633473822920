import { message } from 'antd';
import { contentStore } from 'content/store';
import { ContentInterface } from 'content/type';
import { NavigateFunction } from 'react-router-dom';

interface ContentHandlersProps {
  navigate: NavigateFunction;
  setContentList?: (contentList: ContentInterface[]) => void;
  setIsModalVisible?: (isVisible: boolean) => void;
  setSelectedRecord?: (record: ContentInterface | null) => void;
}

interface ContentHandlers {
  onClickDeleteButton: (id: number) => Promise<void>;
  handleAddVideoClick: () => void;
  handleOnClickTitle: (id: number) => void;
  handleOnClickSetting: (id: number) => void;
  handleShowModal: (record: ContentInterface) => void;
  handleCancelModal: () => void;
  handleBulkDelete: (ids: number[]) => Promise<void>;
}

const contentHandlers = ({
  navigate,
  setIsModalVisible = (): void => {},
  setSelectedRecord = (): void => {},
}: ContentHandlersProps): ContentHandlers => {
  const onClickDeleteButton = async (id: number): Promise<void> => {
    try {
      // message.info('You do not have permission');
      // return;
      const isConfirmed = window.confirm('Are you sure you want to delete the selected items?');
      if (!isConfirmed) return;
      await contentStore.delete(id);
      window.location.reload();
    } catch (error) {
      console.log(error);
      //TODO something
    }
  };

  // My Local Files 클릭 시 이동 처리
  const handleAddVideoClick = (): void => {
    navigate('/upload/video');
  };

  const handleOnClickTitle = (id: number): void => {
    navigate(`/video/detail/${id}`);
  };

  const handleOnClickSetting = (id: number): void => {
    navigate(`/video/detail/${id}`);
  };

  const handleShowModal = (record: ContentInterface): void => {
    setSelectedRecord(record);
    setIsModalVisible(true);
  };

  const handleCancelModal = (): void => {
    setIsModalVisible(false);
  };

  const handleBulkDelete = async (): Promise<void> => {
    message.info('You do not have permission');
    return;
    // const isConfirmed = window.confirm(`Are you sure you want to delete the selected items?`);
    // if (!isConfirmed) return;

    // try {
    // await Promise.all(ids.map(id => contentStore.delete(id)));
    // window.location.reload();
    // } catch (error) {
    //   console.log(error);
    // }
  };

  return {
    onClickDeleteButton,
    handleAddVideoClick,
    handleOnClickTitle,
    handleOnClickSetting,
    handleShowModal,
    handleCancelModal,
    handleBulkDelete,
  };
};

export default contentHandlers;
