import { ContentProviderInterface } from 'account/type/contentProviderType';
import { Button, Form, FormInstance, Input } from 'antd';
import React from 'react';

interface ContentProviderDetailProps {
  form: FormInstance;
  contentProvider?: ContentProviderInterface;
  actionTitle: string;
  onSubmit: () => void;
}

const ContentProviderDetail: React.FC<ContentProviderDetailProps> = ({
  form,
  contentProvider,
  actionTitle,
  onSubmit,
}) => {
  return (
    <Form form={form} onFinish={onSubmit} layout="vertical">
      <Form.Item
        name="title"
        label="Title"
        initialValue={contentProvider?.title}
        rules={[{ required: true, message: 'Please enter a title' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="title_en" label="Title_en" initialValue={contentProvider?.title_en}>
        <Input />
      </Form.Item>
      <Form.Item
        name="description"
        label="Description"
        initialValue={contentProvider?.description}
        rules={[{ required: true, message: 'Please enter a description' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="slug" label="Slug" initialValue={contentProvider?.slug}>
        <Input />
      </Form.Item>
      <Form.Item name="country" label="Country" initialValue={contentProvider?.country}>
        <Input />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          {actionTitle}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ContentProviderDetail;
