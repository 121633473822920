import { seriesSeasonItemApi } from 'content/apis/seriesSeasonItemApi';
import { BaseStore } from 'store/baseStore';
import { SeasonInterface } from 'content/type/seasonType';

class SeriesSeasonItemStore extends BaseStore<SeasonInterface> {
  public async fetchByParentId(
    parentId: number,
    queryParams?: Record<string, string>
  ): Promise<SeasonInterface[]> {
    // eslint-disable-next-line camelcase
    const { results, count, page_size } = await seriesSeasonItemApi.fetchByParentId(
      parentId,
      queryParams
    );
    this.setItems(results);
    this.setPageInfo(count, page_size);
    return this.getItems();
  }

  public async createWithParentId(parentId: number, itemIds: number[]): Promise<void> {
    await seriesSeasonItemApi.createWithParentId(parentId, itemIds);
  }

  public async deleteWithParentId(parentId: number, ids: number[]): Promise<void> {
    await seriesSeasonItemApi.deleteWithParentId(parentId, ids);
  }
}

export const seriesSeasonItemStore = new SeriesSeasonItemStore(seriesSeasonItemApi);
