import { seriesStore } from 'content/store';
import { NavigateFunction } from 'react-router-dom';

interface TableHandlers {
  onClickDeleteButton: (id: number) => void;
  handleOnClickTitle: (id: number) => void;
  handleOnClickSetting: (id: number) => void;
  handleAddSeriesClick: () => void;
}

interface TableHandlersProps {
  navigate: NavigateFunction;
}

export default function tableHandlers({ navigate }: TableHandlersProps): TableHandlers {
  const onClickDeleteButton = async (id: number): Promise<void> => {
    // message.info('You do not have permission');
    // return;
    const isConfirmed = window.confirm('Are you sure you want to delete the selected items?');
    if (!isConfirmed) return;
    await seriesStore.delete(id);
    navigate(`/series`);
  };

  const handleOnClickTitle = (id: number): void => {
    navigate(`/series/edit/${id}`);
  };

  const handleOnClickSetting = (id: number): void => {
    navigate(`/series/edit/${id}`);
  };

  const handleAddSeriesClick = (): void => {
    navigate('/series/add');
  };

  return {
    onClickDeleteButton,
    handleOnClickTitle,
    handleOnClickSetting,
    handleAddSeriesClick,
  };
}
