import React from 'react';
import ReactPlayer from 'react-player';

interface PreviewProps {
  videoUrl: string;
}

const Preview: React.FC<PreviewProps> = ({ videoUrl }: PreviewProps) => {
  return (
    <div className="preview-container">
      {videoUrl ? (
        <ReactPlayer
          url={videoUrl}
          controls
          width="100%"
          height="auto"
          config={{
            file: {
              attributes: {
                controlsList: 'nodownload',
              },
            },
          }}
        />
      ) : (
        <p>비디오를 로딩 중입니다...</p>
      )}
    </div>
  );
};

export default Preview;
