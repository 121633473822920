import React from 'react';
import { VideoSourceInterface } from '../type/videoSourceType';
import { Switch, Space, Button } from 'antd';
import { ReloadOutlined, SettingOutlined, DeleteOutlined } from '@ant-design/icons';
import { TableColumnInterface } from 'components/table/Table';

interface VideoSourceColumnsProps {
  onClickTitleButton: (id: number) => void;
  onClickSettingButton: (id: number) => void;
  onClickDeleteButton: (id: number) => void;
  onClickReloadButton: (id: number) => void;
}

export const getVideoSourceColumns = ({
  onClickTitleButton,
  onClickSettingButton,
  onClickDeleteButton,
  onClickReloadButton,
}: VideoSourceColumnsProps): TableColumnInterface<VideoSourceInterface>[] => {
  return [
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      render: (text: string, record: VideoSourceInterface) => (
        <div className="video-source-title" onClick={() => onClickTitleButton(record.id || 0)}>
          {text}
        </div>
      ),
    },
    {
      title: 'category',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'mrss_url',
      dataIndex: 'mrss_url',
      key: 'mrss_url',
    },
    {
      title: 'created',
      dataIndex: 'created',
      key: 'created',
    },
    {
      title: 'auto-activate-videos',
      dataIndex: 'auto_activate_videos',
      key: 'auto_activate_videos',
      render: (_: string, record: VideoSourceInterface) => (
        <Switch
          checked={record.auto_activate_videos}
          checkedChildren="켜짐"
          unCheckedChildren="꺼짐"
          disabled
        />
      ),
    },
    {
      title: 'auto-add-videos',
      dataIndex: 'auto_add_videos',
      key: 'auto_add_videos',
      render: (_: string, record: VideoSourceInterface) => (
        <Switch
          checked={record.auto_add_videos}
          checkedChildren="켜짐"
          unCheckedChildren="꺼짐"
          disabled
        />
      ),
    },
    {
      title: 'ACTIONS',
      key: 'actions',
      dataIndex: 'actions',
      render: (text: string, record: VideoSourceInterface) => (
        <Space>
          <Button
            icon={<ReloadOutlined />}
            onClick={() => onClickReloadButton(record.id || 0)}
            className="action-btn play-btn"
          />
          <Button
            icon={<SettingOutlined />}
            onClick={() => onClickSettingButton(record.id || 1)}
            className="action-btn"
          />
          <Button
            icon={<DeleteOutlined />}
            className="action-btn delete-btn"
            onClick={() => onClickDeleteButton(record.id || 0)}
          />
        </Space>
      ),
    },
  ];
};
