import { NavigateFunction } from 'react-router-dom';
import { videoSourceStore } from '../store/videoSourceStore';

interface TableHandlersProps {
  navigate: NavigateFunction;
}

interface TableHandlersReturn {
  onClickDeleteButton: (id: number) => Promise<void>;
  onClickTitleButton: (id: number) => void;
  onClickSettingButton: (id: number) => void;
  onClickReloadButton: (id: number) => void;
  handleAddClick: () => void;
}

const tableHandlers = ({ navigate }: TableHandlersProps): TableHandlersReturn => {
  const onClickDeleteButton = async (id: number): Promise<void> => {
    // message.info(`You do not have permission to delete video source with id ${id}`);
    const isConfirmed = window.confirm('Are you sure you want to delete the selected items?');
    if (!isConfirmed) return;
    await videoSourceStore.delete(id);
    window.location.reload();
  };

  const onClickTitleButton = (id: number): void => {
    navigate(`/video-source/edit/${id}`);
  };

  const onClickSettingButton = (id: number): void => {
    navigate(`/video-source/edit/${id}`);
  };

  const onClickReloadButton = (id: number): void => {
    videoSourceStore.generate(id);
  };

  const handleAddClick = (): void => {
    navigate('/video-source/edit');
  };

  return {
    onClickDeleteButton,
    onClickTitleButton,
    onClickSettingButton,
    onClickReloadButton,
    handleAddClick,
  };
};

export default tableHandlers;
