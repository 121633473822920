import { Form } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as Component from '../../../../shared/components';
import * as PeopleComponent from './components';
import detailHandlers from './handlers/detailHandlers';

const PeopleAdd: React.FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { handleOnClickAddButtonClick, handleOnChange } = detailHandlers({ form, navigate });

  return (
    <Component.BasePageComponent>
      <PeopleComponent.DetailComponent
        form={form}
        actionTitle="ADD"
        handleOnClickButtonClick={handleOnClickAddButtonClick}
        handleOnChange={handleOnChange}
      />
    </Component.BasePageComponent>
  );
};

export default observer(PeopleAdd);
