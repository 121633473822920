import { BaseApi } from 'api/baseApi';
import axios from 'axios';
import { BASE_URL, SEASON_API_PATH } from 'constants/api';
import { PosterInterface, SeasonInterface } from 'content/type';
import { FetchResult } from 'type/baseApiType';

class SeasonApi extends BaseApi<SeasonInterface> {
  public async fetchById(id: number): Promise<SeasonInterface> {
    const response = await axios.get(`${BASE_URL}${SEASON_API_PATH}${id}/`);
    return response.data;
  }

  async fetchByWord(page: number, word: string): Promise<FetchResult<SeasonInterface>> {
    const response = await axios.get(`${BASE_URL}${SEASON_API_PATH}`, {
      params: {
        page: page,
        search: word,
      },
    });
    return response.data;
  }

  public async create(season: SeasonInterface): Promise<SeasonInterface> {
    const response = await axios.post(`${BASE_URL}${SEASON_API_PATH}`, season);
    return response.data;
  }

  public async update(id: number, season: Partial<SeasonInterface>): Promise<SeasonInterface> {
    const response = await axios.patch(`${BASE_URL}${SEASON_API_PATH}${id}/`, season);
    console.log(response.data);
    return response.data;
  }

  public async delete(id: number): Promise<void> {
    await axios.delete(`${BASE_URL}${SEASON_API_PATH}${id}/`);
  }

  async fetchPosterList(seasonId: number): Promise<PosterInterface[]> {
    const response = await axios.get(`${BASE_URL}${SEASON_API_PATH}${seasonId}/poster/`);
    return response.data;
  }

  async uploadPoster(seasonId: number, formData: FormData): Promise<void> {
    const response = await axios.post(`${BASE_URL}${SEASON_API_PATH}${seasonId}/poster/`, formData);
    return response.data;
  }

  async removePoster(seasonId: number, posterId: number): Promise<void> {
    const response = await axios.delete(
      `${BASE_URL}${SEASON_API_PATH}${seasonId}/poster/${posterId}/`
    );
    return response.data;
  }

  async updatePoster(seasonId: number, poster: PosterInterface): Promise<void> {
    const response = await axios.patch(
      `${BASE_URL}${SEASON_API_PATH}${seasonId}/poster/${poster.id}/`,
      poster
    );
    return response.data;
  }
}

export const seasonApi = new SeasonApi(SEASON_API_PATH);
