import { BaseApi } from 'api/baseApi';
import axios from 'axios';
import { BASE_URL, SEASON_SUB_PATH, SERIES_API_PATH } from 'constants/api';
import { SeasonInterface } from 'content/type';
import { FetchResult } from 'type/baseApiType';

class SeriesSeasonItemApi extends BaseApi<SeasonInterface> {
  private constructUrl(seriesId: number): string {
    return `${BASE_URL}${SERIES_API_PATH}${seriesId}${SEASON_SUB_PATH}`;
  }

  public async fetchByParentId(
    seriesId: number,
    queryParams?: Record<string, string>
  ): Promise<FetchResult<SeasonInterface>> {
    const params = new URLSearchParams(queryParams);
    const response = await axios.get(this.constructUrl(seriesId), { params });
    return response.data;
  }

  public async fetchExceptParentId(
    seriesId: number,
    queryParams?: Record<string, string>
  ): Promise<FetchResult<SeasonInterface>> {
    const params = new URLSearchParams(queryParams);
    const response = await axios.get(this.constructUrl(seriesId), { params });
    return response.data;
  }

  public async fetchByParentIdAndItemId(
    seriesId: number,
    seasonId: number
  ): Promise<SeasonInterface> {
    const response = await axios.get(`${this.constructUrl(seriesId)}${seasonId}/`);
    return response.data;
  }

  public async createWithParentId(parentId: number, itemIds: number[]): Promise<void> {
    const payload = { ids: itemIds };
    await axios.post(this.constructUrl(parentId), payload);
  }

  public async deleteWithParentId(parentId: number, itemIds: number[]): Promise<void> {
    const payload = { ids: itemIds };
    await axios.patch(this.constructUrl(parentId), payload);
  }

  public async fetch(): Promise<FetchResult<SeasonInterface>> {
    throw new Error('fetch method not supported for hierarchical API');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async create(_item: SeasonInterface): Promise<SeasonInterface> {
    throw new Error(
      'create method not supported for hierarchical API. Use createWithParentId instead.'
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async update(_id: number, _item: SeasonInterface): Promise<SeasonInterface> {
    throw new Error('update method not supported for hierarchical API');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async delete(_id: number): Promise<void> {
    throw new Error('delete method not supported for hierarchical API');
  }
}

export const seriesSeasonItemApi = new SeriesSeasonItemApi(SERIES_API_PATH);
