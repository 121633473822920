import { Form } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import * as Component from '../../../../shared/components';
import * as SeriesComponent from './components';

const SeriesAdd: React.FC = () => {
  const [form] = Form.useForm();

  return (
    <Component.BasePageComponent>
      <SeriesComponent.SeriesDetailComponent form={form} actionTitle="ADD" />
    </Component.BasePageComponent>
  );
};

export default observer(SeriesAdd);
