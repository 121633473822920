import { BaseApi } from 'api/baseApi';
import axios from 'axios';
import { ADMIN_USER_API_PATH, BASE_URL } from 'constants/api';
import { UserInterface } from '../type/userType';

class UserApi extends BaseApi<UserInterface> {
  public async update(id: number, user: UserInterface): Promise<UserInterface> {
    return await axios.patch(`${BASE_URL}${ADMIN_USER_API_PATH}${id}/`, {
      ...user,
      // eslint-disable-next-line camelcase
      project_id: user.project_id,
      // eslint-disable-next-line camelcase
      role: user.role,
    });
  }

  public async delete(id: number): Promise<void> {
    await axios.delete(`${BASE_URL}${ADMIN_USER_API_PATH}${id}/`);
  }

  public async create(user: UserInterface): Promise<UserInterface> {
    return await axios.post(`${BASE_URL}${ADMIN_USER_API_PATH}`, {
      ...user,
      // eslint-disable-next-line camelcase
      project_id: user.project_id,
      // eslint-disable-next-line camelcase
      role: user.role,
    });
  }

  public async fetchById(id: number): Promise<UserInterface> {
    const response = await axios.get(`${BASE_URL}${ADMIN_USER_API_PATH}${id}/`);
    return response.data;
  }
}

export const userApi = new UserApi(ADMIN_USER_API_PATH);
