import React from 'react';
import { FormInstance, message } from 'antd';
import { SubtitleInterface } from 'content/type';
import { RcFile } from 'antd/es/upload';
import { contentStore } from 'content/store';
import axios from 'axios';

interface SubtitleHandlersProps {
  contentId: number;
  form: FormInstance;
  setDataSource: React.Dispatch<React.SetStateAction<SubtitleInterface[]>>;
}

interface SubtitleHandlers {
  handleLanguageChange: (value: string, record: { id: string }) => void;
  handleFileChange: (info: RcFile, record: { id: string }) => void;
  handleActiveChange: (value: boolean, record: { id: string }) => void;
  handleAddSubtitle: (id: number) => void;
  handleUpdateSubtitle: () => void;
}

const subtitleHandlers = ({
  form,
  contentId,
  setDataSource,
}: SubtitleHandlersProps): SubtitleHandlers => {
  const handleLanguageChange = (value: string, record: { id: string }): void => {
    setDataSource((prev: SubtitleInterface[]) => {
      const newDataSource = prev.map(subtitle =>
        subtitle.id === Number(record.id) ? { ...subtitle, lang: value } : subtitle
      );
      form.setFieldValue('subtitleList', newDataSource);
      return newDataSource;
    });
  };

  const handleFileChange = (info: RcFile, record: { id: string }): void => {
    if (info) {
      setDataSource((prev: SubtitleInterface[]) => {
        const newDataSource = prev.map(subtitle =>
          subtitle.id === Number(record.id) ? { ...subtitle, file: info } : subtitle
        );
        form.setFieldValue('subtitleList', newDataSource);
        return newDataSource;
      });
    }
  };

  const handleActiveChange = (value: boolean, record: { id: string }): void => {
    setDataSource((prev: SubtitleInterface[]) => {
      const newDataSource = prev.map(subtitle =>
        subtitle.id === Number(record.id) ? { ...subtitle, active: value } : subtitle
      );
      form.setFieldValue('subtitleList', newDataSource);
      return newDataSource;
    });
  };

  const handleAddSubtitle = (id: number): void => {
    setDataSource((prev: SubtitleInterface[]) => {
      const newSubtitle = [
        ...prev,
        {
          id,
          lang: '',
          file: undefined,
          created: new Date().toISOString(),
          modified: new Date().toISOString(),
          // eslint-disable-next-line camelcase
          content_id: contentId,
          // eslint-disable-next-line camelcase
          external_url: '',
        },
      ];
      form.setFieldValue('subtitleList', newSubtitle);
      return newSubtitle;
    });
  };

  const handleUpdateSubtitle = async (): Promise<void> => {
    const subtitleList = form.getFieldValue('subtitleList');
    if (subtitleList) {
      try {
        await contentStore.uploadSubtitle(contentId, subtitleList);
        message.success('upload success');
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status === 400) {
          message.error('Failed to update : ' + JSON.stringify(error.response.data));
        }
      }
    }
  };

  return {
    handleLanguageChange,
    handleFileChange,
    handleActiveChange,
    handleAddSubtitle,
    handleUpdateSubtitle,
  };
};

export default subtitleHandlers;
