import { categoryStore, peopleStore, seriesStore } from 'content/store';
import { CategoryInterface, PeopleInterface } from 'content/type';
import { SeriesInterface } from 'content/type/seriesType';

interface FetchSeriesDetailDataReturn {
  fetchedSeries: SeriesInterface | undefined;
  fetchedCategories: CategoryInterface[];
  fetchedPeople: PeopleInterface[];
}

export const fetchSeries = async (id: number): Promise<SeriesInterface | undefined> => {
  return await seriesStore.fetchById(id);
};

export const fetchCategories = async (categories: number[]): Promise<CategoryInterface[]> => {
  return await categoryStore.fetchByIds(categories);
};

export const fetchPeople = async (): Promise<PeopleInterface[]> => {
  return await peopleStore.fetch();
};

export const fetchSeriesDetailData = async (id: number): Promise<FetchSeriesDetailDataReturn> => {
  const fetchedSeries = await fetchSeries(id);
  const [fetchedCategories, fetchedPeople] = await Promise.all([
    fetchCategories(fetchedSeries?.categories || []),
    fetchPeople(),
  ]);

  return {
    fetchedSeries: fetchedSeries,
    fetchedCategories,
    fetchedPeople,
  };
};
