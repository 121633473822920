import { Button, Form, Input } from 'antd';
import { FormInstance } from 'antd/es/form';
import { LoadingSpinnerComponent } from 'components';
import React, { useEffect, useState } from 'react';
import { accountStore } from 'store/accountStore';

interface DetailProps {
  form: FormInstance;
  handleUpdate: () => Promise<void>;
}

const Detail: React.FC<DetailProps> = ({ form, handleUpdate }) => {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchAccount = async () => {
      await accountStore.init();
      const myAccount = accountStore.getAccount();
      if (myAccount) {
        form.setFieldsValue(myAccount);
      }
    };
    fetchAccount().then(() => {
      setLoading(false);
    });
  }, [form]);

  return (
    <>
      {loading ? (
        <LoadingSpinnerComponent />
      ) : (
        <Form
          form={form}
          name="account"
          autoComplete="off"
          style={{
            display: 'flex',
            flexDirection: 'column',
            margin: '0 auto',
            width: '30%',
          }}
        >
          <Form.Item label="id" name="username" key="username">
            <Input readOnly />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            key="email"
            rules={[{ required: true, message: 'Please enter your email' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="First Name"
            name="first_name"
            key="first_name"
            rules={[{ required: true, message: 'Please enter your name' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Last Name"
            name="last_name"
            key="last_name"
            rules={[{ required: true, message: 'Please enter your last name' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Old Password"
            name="old_password"
            key="old_password"
            rules={[{ required: false, message: 'Please enter your old password' }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="New Password"
            name="new_password"
            key="new_password"
            rules={[{ required: false, message: 'Please enter your new password' }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit" loading={loading} onClick={handleUpdate}>
              Save
            </Button>
          </Form.Item>
        </Form>
      )}
    </>
  );
};

export default Detail;
