import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import AppRoutes from './router/router';
import './config/axiosConfig';
import 'antd/dist/reset.css';

const rootElement = document.getElementById('root');

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement as HTMLElement);
  root.render(
    <React.StrictMode>
      <Router>
        <AppRoutes />
      </Router>
    </React.StrictMode>
  );
} else {
  throw new Error('Root Element not found');
}
