import { BaseApi } from 'api/baseApi';
import axios from 'axios';
import { BASE_URL, CONTENT_SUB_PATH, CONTRACT_API_PATH } from 'constants/api';
import { ContentInterface } from 'content/type';
import { FetchResult } from 'type/baseApiType';

class ContractContentItemApi extends BaseApi<ContentInterface> {
  private constructUrl(contractId: number): string {
    return `${BASE_URL}${CONTRACT_API_PATH}${contractId}${CONTENT_SUB_PATH}`;
  }

  public async fetchByParentId(
    contractId: number,
    queryParams?: Record<string, string>
  ): Promise<FetchResult<ContentInterface>> {
    const params = new URLSearchParams(queryParams);
    const response = await axios.get(this.constructUrl(contractId), { params });
    return response.data;
  }

  public async fetchByParentIdAndItemId(
    contractId: number,
    contentId: number
  ): Promise<ContentInterface> {
    const response = await axios.get(`${this.constructUrl(contractId)}${contentId}/`);
    return response.data;
  }

  public async createWithParentId(parentId: number, itemIds: number[]): Promise<void> {
    const payload = { ids: itemIds };
    await axios.post(this.constructUrl(parentId), payload);
  }

  public async deleteWithParentId(parentId: number, itemIds: number[]): Promise<void> {
    const params = new URLSearchParams();
    itemIds.forEach(id => params.append('ids', id.toString()));

    await axios.delete(this.constructUrl(parentId), { params });
  }

  public async fetch(): Promise<FetchResult<ContentInterface>> {
    throw new Error('fetch method not supported for hierarchical API');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async create(_item: ContentInterface): Promise<ContentInterface> {
    throw new Error(
      'create method not supported for hierarchical API. Use createWithParentId instead.'
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async update(_id: number, _item: ContentInterface): Promise<ContentInterface> {
    throw new Error('update method not supported for hierarchical API');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async delete(_id: number): Promise<void> {
    throw new Error('delete method not supported for hierarchical API');
  }
}

export const contractContentItemApi = new ContractContentItemApi(CONTRACT_API_PATH);
