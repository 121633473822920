import { action, makeObservable, observable } from 'mobx';
import { BaseApiInterface } from 'type/baseApiType';

interface PageInfo {
  totalCount: number;
  pageSize: number;
}

export interface BaseStoreInterface<T extends { id: number }> {
  fetch(queryParams?: Record<string, string>, page?: number): Promise<T[]>;

  fetchById(id: number): Promise<T>;

  create(item: T): Promise<T>;

  update(id: number, item: T): Promise<void>;

  delete(id: number): Promise<void>;
}

export abstract class BaseStore<T extends { id: number }> implements BaseStoreInterface<T> {
  protected items: T[] = [];
  protected pageInfo: PageInfo = {
    totalCount: 0,
    pageSize: 0,
  };

  constructor(protected service: BaseApiInterface<T>) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    makeObservable<this, any>(this, {
      items: observable,
      pageInfo: observable,
      setItems: action,
      addItem: action,
      updateItem: action,
      removeItem: action,
      setPageInfo: action,
    });
  }

  public async fetch(queryParams?: Record<string, string>): Promise<T[]> {
    // eslint-disable-next-line camelcase
    const { results, count, page_size } = await this.service.fetch(queryParams);
    this.items = results;
    this.setPageInfo(count, page_size);
    return this.items;
  }

  public async fetchById(id: number, queryParams?: Record<string, string>): Promise<T> {
    const item = this.getItem(id);
    if (!item) {
      const fetchedItem = await this.service.fetchById!(id, queryParams);
      this.addItem(fetchedItem);
      return fetchedItem;
    }

    return item;
  }

  public async fetchByParentId(
    parentId: number,
    queryParams: Record<string, string>
  ): Promise<T[]> {
    const items = await this.service.fetchByParentId!(parentId, queryParams);
    this.setItems(items.results);
    return items.results;
  }

  public async create(item: T): Promise<T> {
    const newItem = await this.service.create(item);
    this.addItem(newItem);
    return newItem;
  }

  public async createFormData(formData: FormData): Promise<void> {
    const newItem = await this.service.createFormData?.(formData);
    if (newItem) {
      this.addItem(newItem);
    }
  }

  public async createWithParentId(parentId: number, itemIds: number[]): Promise<void> {
    await this.service.createWithParentId?.(parentId, itemIds);
  }

  public async update(id: number, item: T): Promise<void> {
    const updatedItem = await this.service.update(id, item);
    if (updatedItem) {
      this.updateItem(updatedItem);
    }
  }

  public async updateFormData(id: number, formData: FormData): Promise<void> {
    const updatedItem = await this.service.updateFormData?.(id, formData);
    if (updatedItem) {
      this.updateItem(updatedItem);
    }
  }

  public async delete(id: number): Promise<void> {
    await this.service.delete(id);
    this.removeItem(id);
  }

  public async deleteWithParentId(parentId: number, itemIds: number[]): Promise<void> {
    await this.service.deleteWithParentId?.(parentId, itemIds);
    itemIds.forEach(id => this.removeItem(id));
  }

  public getItems(): T[] {
    return this.items;
  }

  public getItem(id: number): T | undefined {
    return this.items.find(item => item.id === id);
  }

  public setItems(items: T[]): void {
    this.items = items;
  }

  public addItem(item: T): void {
    this.items.push(item);
  }

  public updateItem(item: T): void {
    const index = this.items.findIndex(i => i.id === item.id);
    if (index !== -1) {
      this.items[index] = item;
    }
  }

  public removeItem(id: number): void {
    this.items = this.items.filter(item => item.id !== id);
  }

  public getTotalCount(): number {
    return this.pageInfo.totalCount;
  }

  public setPageInfo(totalCount: number, pageSize: number): void {
    this.pageInfo = {
      totalCount,
      pageSize,
    };
  }
}
