import { Form } from 'antd';
import { peopleStore } from 'content/store';
import { PeopleInterface } from 'content/type';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Component from '../../../../shared/components';
import * as PeopleComponent from './components';
import detailHandlers from './handlers/detailHandlers';

const PeopleEdit: React.FC = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const navigate = useNavigate();
  const [people, setPeople] = useState<PeopleInterface | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { handleOnClickEditButtonClick, handleOnChange } = detailHandlers({ form, navigate });

  useEffect(() => {
    const fetchPeople = async (): Promise<void> => {
      await peopleStore.init();
      const people = await peopleStore.fetchById(Number(id));
      setPeople(people);
      form.setFieldsValue(people);
      setIsLoading(false);
    };

    fetchPeople().then();
  }, [form, id]);

  return (
    <Component.BasePageComponent>
      {isLoading ? (
        <Component.LoadingSpinnerComponent />
      ) : (
        <PeopleComponent.DetailComponent
          form={form}
          people={people || undefined}
          actionTitle="SAVE"
          handleOnClickButtonClick={handleOnClickEditButtonClick}
          handleOnChange={handleOnChange}
        />
      )}
    </Component.BasePageComponent>
  );
};

export default observer(PeopleEdit);
