import React from 'react';
import { Avatar, Breadcrumb, Dropdown, Layout } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { ItemType } from 'antd/es/menu/interface';
import { BreadcrumbItemType } from 'antd/es/breadcrumb/Breadcrumb';
import './PageHeader.css';

const { Header } = Layout;

interface PageHeaderProps {
  userName: string;
  breadcrumbItems: BreadcrumbItemType[];
  adminMenuList: ItemType[];
}

const PageHeader: React.FC<PageHeaderProps> = ({ userName, breadcrumbItems, adminMenuList }) => {
  return (
    <Header className="content-page-header">
      <div className="header-content">
        <div className="breadcrumb-container">
          <Breadcrumb items={breadcrumbItems} />
        </div>
        <div>
          <Dropdown menu={{ items: adminMenuList }} placement="bottomRight">
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px', cursor: 'pointer' }}>
              <Avatar icon={<UserOutlined />} />
              <span>{userName}</span>
            </div>
          </Dropdown>
        </div>
      </div>
    </Header>
  );
};

export default React.memo(PageHeader);
