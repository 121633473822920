import axios from 'axios';
import { BASE_URL } from 'constants/api';
import { BaseApiInterface, FetchResult } from '../type/baseApiType';

export class BaseApi<T> implements BaseApiInterface<T> {
  constructor(public apiPath: string) {}

  public async create(item: T): Promise<T> {
    const response = await axios.post(`${BASE_URL}${this.apiPath}`, item);
    return response.data;
  }

  public async update(id: number, data: Partial<T>): Promise<T> {
    const response = await axios.patch(`${BASE_URL}${this.apiPath}${id}/`, data);
    return response.data;
  }

  public async delete(id: number): Promise<void> {
    await axios.delete(`${BASE_URL}${this.apiPath}${id}/`);
  }

  public async fetch(queryParams?: Record<string, string>): Promise<FetchResult<T>> {
    let url = `${BASE_URL}${this.apiPath}`;
    if (queryParams) {
      const params = Object.entries(queryParams)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
      url = `${url}?${params}`;
    }
    const response = await axios.get(url);
    return response.data;
  }
}
