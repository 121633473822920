import { BaseStore } from 'store/baseStore';
import { CategoryInterface } from 'content/type';
import { categoryApi } from '../apis/categoryApi';

class CategoryStore extends BaseStore<CategoryInterface> {
  public init = async (): Promise<void> => {
    const categoryList = await categoryApi.fetch();
    this.setItems(categoryList.results);
    this.setPageInfo(categoryList.count, categoryList.page_size);
  };

  public getCategoryById = async (categoryId: number): Promise<CategoryInterface | undefined> => {
    return this.getItems().find(category => category.id === categoryId);
  };

  public async fetchByIds(ids: number[]): Promise<CategoryInterface[]> {
    return await Promise.all(ids.map(id => this.fetchById(id)));
  }
}

export const categoryStore = new CategoryStore(categoryApi);
