import { Button, Form, Input, Modal, Select, Tag } from 'antd';
import { AGE_RATING, CONTENT_TYPE, STATUS } from 'constants/index';
import detailHandlers from 'content/page/content/handlers/detailHandlers';
import { categoryStore, peopleStore, seasonStore, seriesStore } from 'content/store';
import { CategoryInterface } from 'content/type/categoryType';
import { ContentInterface } from 'content/type/contentType';
import { PeopleInterface } from 'content/type/peopleType';
import { SeasonInterface } from 'content/type/seasonType';
import { SeriesInterface } from 'content/type/seriesType';
import React, { memo, useEffect } from 'react';
import './Detail.css';

interface ContentDetailProps {
  content: ContentInterface;
  peopleList: PeopleInterface[];
  contentCategoryList: CategoryInterface[];
}

const ContentDetail: React.FC<ContentDetailProps> = ({
  content,
  peopleList,
  contentCategoryList,
}) => {
  const url = `${window.location.origin}`;
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [categoryList, setCategoryList] = React.useState<CategoryInterface[]>([]);
  const [selectedCategories, setSelectedCategories] = React.useState<number[]>([]);
  const [seasonUrl, setSeasonUrl] = React.useState<string>(`${url}/season`);
  const [seriesUrl, setSeriesUrl] = React.useState<string>(`${url}/series`);
  const [season, setSeason] = React.useState<SeasonInterface>();
  const [series, setSeries] = React.useState<SeriesInterface>();
  const [peoples, setPeoples] = React.useState<PeopleInterface[]>(peopleList);
  const currentContentType = Form.useWatch('content_type', form);

  useEffect(() => {
    const fetchCategoryList = async (): Promise<CategoryInterface[]> => {
      return await categoryStore.fetch();
    };
    const fetchSeason = async (seasonId: number): Promise<SeasonInterface> => {
      return await seasonStore.fetchById(seasonId);
    };
    setSelectedCategories(contentCategoryList.map((category: CategoryInterface) => category.id));
    if (content.season) {
      fetchSeason(content.season).then(season => {
        setSeason(season);
        if (season?.series) {
          seriesStore.fetchById(Number(season.series)).then(data => {
            setSeries(data);
            setSeriesUrl(`${url}/series/edit/${data.id}`);
          });
        }
      });
    }
    fetchCategoryList().then(setCategoryList);
    form.setFieldsValue(content);
    if (content.season) {
      setSeasonUrl(`${url}/season/edit/${content.season}`);
    }
  }, [form, content, contentCategoryList]);

  useEffect(() => {
    const init = async () => {
      const data = await peopleStore.fetch({
        page: '1',
        page_size: '100',
        contents__id: content.id.toString(),
      });

      form.setFieldsValue({ cast: data.map((people: PeopleInterface) => people.id.toString()) });
      const result = [...peoples, ...data];
      const unique = result.filter((v, i, a) => a.findIndex(t => t.id === v.id) === i);
      setPeoples(unique);
    };
    init();
  }, []);

  const searchPeople = async (value: string) => {
    const response = await peopleStore.fetch({
      page: '1',
      page_size: '10',
      search: value,
    });
    setPeoples(response);
  };

  const { handleUpdateButtonClick, handleModalCancel, handleModalOk, handleEditCategories } =
    detailHandlers({
      contentId: content.id,
      form,
      selectedCategories,
      setIsModalVisible,
    });

  return (
    <div>
      <div className="content-details-page" style={{ display: 'flex' }}>
        <div className="content-details-edit">
          <h1>{content && content.title}</h1>
          <Form
            form={form}
            layout="vertical"
            className="content-details-form"
            initialValues={content}
          >
            <Form.Item
              label="Title"
              name="title"
              rules={[{ required: true, message: 'Please enter a title!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Title En" name="title_en">
              <Input />
            </Form.Item>
            <Form.Item label="Content Type" name="content_type">
              <Select>
                {CONTENT_TYPE.map(contentType => (
                  <Select.Option key={contentType} value={contentType}>
                    {contentType}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Status" name="status">
              <Select>
                {STATUS.map(status => (
                  <Select.Option key={status} value={status}>
                    {status}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form layout="inline">
              <Form.Item
                name="categories"
                style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}
              >
                <span style={{ marginRight: 8, cursor: 'pointer' }}>Categories</span>
                <Button type="link" onClick={handleEditCategories}>
                  Edit
                </Button>
                <div style={{ marginTop: '4px', marginBottom: '8px' }}>
                  {categoryList
                    .filter(category => selectedCategories.includes(category.id))
                    .map(category => (
                      <Tag
                        key={category.id}
                        color="blue"
                        style={{ margin: '4px', cursor: 'pointer' }}
                        onClick={() =>
                          window.open(
                            `/category/edit/${category.id}`,
                            '_blank',
                            'noopener,noreferrer'
                          )
                        }
                      >
                        {category.name}
                      </Tag>
                    ))}
                </div>
              </Form.Item>
            </Form>
            <Form.Item
              label="Description"
              name="description"
              rules={[{ required: true, message: 'Please enter a description!' }]}
            >
              <Input.TextArea />
            </Form.Item>
            <Form.Item label="Short Description" name="short_description">
              <Input.TextArea />
            </Form.Item>
            <Form.Item label="Genre" name="genre">
              <Input />
            </Form.Item>
            <Form.Item label="Episode Number" name="episode_number">
              <Input />
            </Form.Item>
            {currentContentType !== 'movie' && (
              <Form layout="inline">
                <Form.Item
                  name="season"
                  style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}
                >
                  <span style={{ marginRight: 8, cursor: 'pointer' }}>Season</span>
                  {!season ? (
                    <Button
                      type="link"
                      onClick={() => window.open('/season', '_blank', 'noopener,noreferrer')}
                    >
                      Edit
                    </Button>
                  ) : null}
                  <div style={{ marginTop: '8px' }}>
                    {season ? (
                      <Tag
                        color="blue"
                        className="season-tag"
                        onClick={() => window.open(seasonUrl, '_blank', 'noopener,noreferrer')}
                      >
                        {season.title}
                      </Tag>
                    ) : null}
                  </div>
                </Form.Item>
              </Form>
            )}
            {currentContentType !== 'movie' && (
              <Form layout="inline">
                <Form.Item
                  name="series"
                  style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}
                >
                  <span style={{ marginRight: 8, cursor: 'pointer' }}>Series</span>
                  {!series ? (
                    <Button
                      type="link"
                      onClick={() => window.open('/series', '_blank', 'noopener,noreferrer')}
                    >
                      Edit
                    </Button>
                  ) : null}
                  <div style={{ marginTop: '8px' }}>
                    {series ? (
                      <Tag
                        color="blue"
                        className="season-tag"
                        onClick={() => window.open(seriesUrl, '_blank', 'noopener,noreferrer')}
                      >
                        {series.title}
                      </Tag>
                    ) : null}
                  </div>
                </Form.Item>
              </Form>
            )}
            <Form.Item label="Cast" name="cast">
              <Select
                onSearch={value => {
                  searchPeople(value);
                }}
                mode="multiple"
                options={peoples.map(people => ({
                  label: people.name,
                  value: people.id.toString(),
                }))}
                filterOption={false}
              ></Select>
            </Form.Item>
            <Form.Item label="Age Rating" name="age_rating">
              <Select>
                {AGE_RATING.map(ageRating => (
                  <Select.Option key={ageRating} value={ageRating}>
                    {ageRating}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Produce Country" name="produce_country">
              <Input />
            </Form.Item>
            <Form.Item label="Language" name="language">
              <Input />
            </Form.Item>
            <Form.Item label="Release Date Start" name="release_date_start">
              <Input />
            </Form.Item>
            <Form.Item label="Release Date End" name="release_date_end">
              <Input />
            </Form.Item>
            <Form.Item label="On Air Date" name="on_air_date">
              <Input />
            </Form.Item>
          </Form>
        </div>
      </div>
      <Form.Item style={{ marginTop: 16, textAlign: 'center' }}>
        <Button type="primary" onClick={() => handleUpdateButtonClick(content.id)}>
          Save
        </Button>
      </Form.Item>
      <Modal
        title="Edit Categories"
        open={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        <Select
          mode="multiple"
          value={selectedCategories}
          onChange={setSelectedCategories}
          style={{ width: '100%' }}
        >
          {categoryList.map(category => (
            <Select.Option key={category.id} value={category.id}>
              {category.name}
            </Select.Option>
          ))}
        </Select>
      </Modal>
    </div>
  );
};

export default memo(ContentDetail);
