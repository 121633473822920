import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { ColorButtonComponent } from 'components';
import React, { useState } from 'react';
import './TableHeader.css';
interface SearchProps {
  tableName: string;
  selectedIds?: number[];
  optionalButton?: JSX.Element[];
  searchTerm?: string;
  handleSearchTerm?: (value: string) => void;
  handleAddClick?: () => void;
  handleBulkDelete?: (ids: number[]) => Promise<void>;
}

const getSearchTermComponent = (
  searchValue: string,
  setSearchValue: (value: string) => void,
  handleSearchTerm: (value: string) => void
): JSX.Element => {
  return (
    <>
      <div className="search-input-wrapper">
        <Input
          className="search-input"
          placeholder="Search"
          prefix={<SearchOutlined />}
          defaultValue={searchValue}
          onChange={e => setSearchValue(e.target.value)}
          onKeyUp={e => {
            if (e.key === 'Enter') {
              handleSearchTerm(searchValue);
            }
          }}
        />
      </div>
      <ColorButtonComponent
        icon={<SearchOutlined />}
        text="Search"
        size="small"
        onClick={() => handleSearchTerm(searchValue)}
      />
    </>
  );
};

const getAddButtonComponent = (handleAddClick: () => void, tableName: string): JSX.Element => {
  return (
    <ColorButtonComponent text={`+ Add ${tableName}`} onClick={handleAddClick} size={'small'} />
  );
};

const getBulkDeleteButtonComponent = (
  selectedIds: number[],
  handleBulkDelete: (ids: number[]) => Promise<void>
): JSX.Element => {
  return (
    <ColorButtonComponent
      color="whte"
      text="Bulk Delete"
      onClick={() => handleBulkDelete(selectedIds)}
      size={'small'}
    />
  );
};

const TableHeader = ({
  tableName,
  selectedIds = [],
  optionalButton = [],
  searchTerm,
  handleSearchTerm,
  handleAddClick,
  handleBulkDelete,
}: SearchProps): JSX.Element => {
  const [searchValue, setSearchValue] = useState<string>(searchTerm || '');
  const searchTermComponent = handleSearchTerm
    ? getSearchTermComponent(searchValue, setSearchValue, handleSearchTerm)
    : null;

  const addButtonComponent = handleAddClick
    ? getAddButtonComponent(handleAddClick, tableName)
    : null;

  const bulkDeleteButtonComponent = handleBulkDelete
    ? getBulkDeleteButtonComponent(selectedIds, handleBulkDelete)
    : null;

  return (
    <>
      <div className="table-name">
        <h1>{tableName}</h1>
      </div>
      <div className="table-header">
        <div className="table-header-left">{searchTermComponent}</div>
        <div className="table-header-right">
          {addButtonComponent}
          {optionalButton}
          <div className="bulk-delete-button-wrapper">{bulkDeleteButtonComponent}</div>
        </div>
      </div>
    </>
  );
};

export default TableHeader;
