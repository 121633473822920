import { Form } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as Component from '../../../../shared/components';
import * as SeasonComponent from './components';
import { detailHandlers } from './handlers';

const SeasonAdd: React.FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { handleOnClickAddButtonClick } = detailHandlers({ form, navigate, id: -1 });

  return (
    <Component.BasePageComponent>
      <SeasonComponent.SeasonDetailComponent
        form={form}
        actionTitle="ADD"
        handleOnClickButtonClick={handleOnClickAddButtonClick}
      />
    </Component.BasePageComponent>
  );
};

export default observer(SeasonAdd);
