import {
  directUploadComplete,
  directUploadPreSignedUrl,
  directUploadStart,
  putPreSignedUrl,
  videoTranscode,
} from './api/UploadVideoApi';
import { UploadedPartsInterface, UploadFileProps } from '../type/uploadVideoType';
import { UPLOAD_CHUNK_SIZE } from 'constants/index';
import { accountStore } from 'store/accountStore';

class UploadVideoService {
  async uploadVideoFiles(uploadFiles: UploadFileProps[], autoTranscode: boolean): Promise<boolean> {
    try {
      await Promise.all(
        uploadFiles.map(async ({ file, onProgress }) => {
          if (file) {
            const { size, name, type, title } = file;
            if (!size || !name || !type) {
              return;
            }
            const response = await directUploadStart(
              title || name,
              type,
              size,
              accountStore.getAccount()?.username || ''
            );
            const uploadId = response.data.uploadId as unknown as number;
            const contentId = response.data.contentId as unknown as number;
            const totalParts = Math.ceil(size / UPLOAD_CHUNK_SIZE);
            const uploadedParts: UploadedPartsInterface[] = [];

            const chunkProgressArray = new Array(totalParts).fill(0);
            let lastUploadedBytes = 0; // 마지막으로 기록된 업로드된 바이트 수
            let lastTime = performance.now();

            // Pre-sign URLs for all parts at once
            const preSignedUrls = await Promise.all(
              Array.from({ length: totalParts }, (_, partNumber) =>
                directUploadPreSignedUrl({
                  directVideoUploadFile: file,
                  contentId,
                  uploadId,
                  partNumber: partNumber + 1,
                })
              )
            );
            // Upload parts in parallel
            await Promise.all(
              preSignedUrls.map(async (urlResponse, partNumber) => {
                const start = partNumber * UPLOAD_CHUNK_SIZE;
                const end = Math.min((partNumber + 1) * UPLOAD_CHUNK_SIZE, size);
                const chunk = file.originFileObj?.slice(start, end);
                const preSignedUrl = urlResponse.data as unknown as string;

                const preSignedUrlResponse = await putPreSignedUrl({
                  preSignedUrl,
                  chunk: chunk as Blob,
                  onChunkProgress: progress => {
                    chunkProgressArray[partNumber] = progress;
                    const uploadedBytes = chunkProgressArray.reduce((acc, val) => acc + val, 0);
                    const overallProgress = (uploadedBytes / size) * 100;
                    const currentTime = performance.now();
                    const elapsedSeconds = (currentTime - lastTime) / 1000;

                    // 전송된 바이트 수와 속도 계산 (초당 전송된 바이트 수)
                    const bytesUploadedSinceLastCheck = uploadedBytes - lastUploadedBytes;
                    const uploadSpeedBytesPerSecond = bytesUploadedSinceLastCheck / elapsedSeconds;

                    onProgress(overallProgress, uploadSpeedBytesPerSecond);

                    lastUploadedBytes = uploadedBytes; // 마지막으로 업로드된 바이트 수 업데이트
                    lastTime = currentTime; // 마지막 시간 업데이트
                  },
                });
                uploadedParts.push({
                  ETag: preSignedUrlResponse.headers.etag,
                  PartNumber: partNumber + 1,
                });
              })
            );

            await directUploadComplete({
              directVideoUploadFile: file,
              contentId,
              uploadId,
              uploadedParts,
            });

            if (autoTranscode) {
              await videoTranscode(contentId);
            }
          }
        })
      );
      return true;
    } catch (error) {
      throw error;
    }
  }
}

export const uploadVideoService = new UploadVideoService();
