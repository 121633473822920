import { FormInstance } from 'antd';
import { UserInterface } from '../../../type/userType';
import { userStore } from '../../../store/userStore';
import { NavigateFunction } from 'react-router-dom';

interface DetailHandlersHook {
  id: number;
  form: FormInstance;
  navigate: NavigateFunction;
}

interface DetailHandlersReturn {
  handleOnClickAddButton: () => Promise<void>;
  handleOnClickEditButton: () => Promise<void>;
}

const detailHandlers = ({ id, form, navigate }: DetailHandlersHook): DetailHandlersReturn => {
  const handleOnClickAddButton = async (): Promise<void> => {
    try {
      const values = await form.validateFields();
      const newUser: UserInterface = {
        id: -1,
        username: values.username,
        email: values.email,
        // eslint-disable-next-line camelcase
        first_name: values.first_name,
        // eslint-disable-next-line camelcase
        last_name: values.last_name,
        // eslint-disable-next-line camelcase
        role: values.role,
        project_id: values.project_id,
      };
      const createdUser = await userStore.create(newUser);
      navigate('/user', {
        state: {
          message: '사용자가 성공적으로 생성되었습니다',
          userId: createdUser.id,
        },
      });
    } catch (error) {
      console.log('유효성 검사 실패:', error);
    }
  };

  const handleOnClickEditButton = async (): Promise<void> => {
    const values = await form.validateFields();
    await userStore.update(id, values);
    navigate('/user', {
      state: {
        message: '사용자 정보가 성공적으로 수정되었습니다',
        userId: id,
      },
    });
  };

  return {
    handleOnClickAddButton,
    handleOnClickEditButton,
  };
};

export default detailHandlers;
