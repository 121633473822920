import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as Component from '../../../../../../shared/components';

const CategoryHeader: React.FC = () => {
  const navigate = useNavigate();

  const handleAddButtonClick = (): void => {
    navigate('/category/add');
  };

  return (
    <div
      className="header-container"
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '20px',
      }}
    >
      <div className="header">
        <h1>Category</h1>
      </div>
      <div className="yellow-button-wrapper" style={{ marginLeft: 'auto', marginRight: '0' }}>
        <Component.ColorButtonComponent text="+ Add Category" onClick={handleAddButtonClick} />
      </div>
    </div>
  );
};

export default CategoryHeader;
