import React from 'react';
import { Tabs } from 'antd';
import './Tab.css';

interface BaseProps {
  key: string;
  title: string;
  component: React.ReactNode;
}

interface TabProps {
  components: BaseProps[];
}

const Tab: React.FC<TabProps> = ({ components }) => {
  const items = components.map(component => ({
    key: component.key,
    label: component.title,
    children: component.component,
  }));

  return (
    <div className="tabs-container">
      <Tabs defaultActiveKey="1" items={items} />
    </div>
  );
};

export default Tab;
