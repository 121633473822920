import * as Component from 'components';
import { contentStore } from 'content/store';
import { ContentInterface } from 'content/type';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { contentHandlers } from './handlers';
import './Content.css';
import { getTableColumn } from './ContentColumn';

const Content: React.FC = () => {
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [selectedRecord, setSelectedRecord] = React.useState<ContentInterface | null>(null);
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const [refreshCounter, setRefreshCounter] = useState(0);
  const {
    onClickDeleteButton,
    handleOnClickTitle,
    handleOnClickSetting,
    handleCancelModal,
    handleShowModal,
    handleBulkDelete,
    handleAddVideoClick,
  } = contentHandlers({
    navigate,
    setIsModalVisible,
    setSelectedRecord,
  });

  const columns = getTableColumn({
    isModalVisible,
    selectedRecord,
    handleOnClickTitle,
    handleOnClickSetting,
    handleCancelModal,
    handleShowModal,
    onClickDeleteButton,
  });

  const fetchContent = useCallback(
    async (
      page: number,
      pageSize: number
    ): Promise<{ data: ContentInterface[]; count: number }> => {
      const contentList = await contentStore.fetch({
        page: page.toString(),
        page_size: pageSize.toString(),
        search: searchTerm,
      });
      return { data: contentList, count: contentStore.getTotalCount() };
    },
    [searchTerm]
  );

  useEffect(() => {
    setRefreshCounter(prev => prev + 1);
  }, [searchTerm]);

  return (
    <Component.BasePageComponent>
      <div className="video-content-container">
        <Component.TableComponent<ContentInterface>
          key={refreshCounter}
          tableName="Content"
          searchTerm={searchTerm}
          dataFetch={fetchContent}
          columns={columns}
          handleSearchTerm={setSearchTerm}
          handleAddClick={handleAddVideoClick}
          handleBulkDelete={handleBulkDelete}
        />
      </div>
    </Component.BasePageComponent>
  );
};

export default observer(Content);
