import { BaseApi } from 'api/baseApi';
import axios from 'axios';
import { BASE_URL, PLAYLIST_ITEM_API_PATH } from 'constants/api';
import { FetchResult } from 'type/baseApiType';
import { PlaylistItemInterface } from '../type/playlistType';

class PlaylistItemApi extends BaseApi<PlaylistItemInterface> {
  public async fetch(
    queryParams?: Record<string, string>
  ): Promise<FetchResult<PlaylistItemInterface>> {
    const response = await axios.get(`${BASE_URL}${PLAYLIST_ITEM_API_PATH}`, {
      params: queryParams,
    });
    return response.data;
  }

  public async fetchById(
    id: number,
    queryParams?: Record<string, string>
  ): Promise<PlaylistItemInterface> {
    const response = await axios.get(`${BASE_URL}${PLAYLIST_ITEM_API_PATH}${id}/`, {
      params: queryParams,
    });
    return response.data;
  }

  public async create(playlist: PlaylistItemInterface): Promise<PlaylistItemInterface> {
    const response = await axios.post(`${BASE_URL}${PLAYLIST_ITEM_API_PATH}`, playlist);
    return response.data;
  }

  public async delete(id: number): Promise<void> {
    await axios.delete(`${BASE_URL}${PLAYLIST_ITEM_API_PATH}${id}/`);
  }

  public async update(
    id: number,
    playlist: Partial<PlaylistItemInterface>
  ): Promise<PlaylistItemInterface> {
    const response = await axios.patch(`${BASE_URL}${PLAYLIST_ITEM_API_PATH}${id}/`, playlist);
    return response.data;
  }
}

export const playlistItemApi = new PlaylistItemApi(PLAYLIST_ITEM_API_PATH);
