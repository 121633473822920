import React, { useState } from 'react';
import { Button, Form, Table } from 'antd';
import { DubbingInterface } from 'content/type';
import { getDubbingColumns } from './DubbingColumns';
import { dubbingHandlers } from 'content/page/content/handlers';
import { PlusOutlined } from '@ant-design/icons';

interface DubbingProps {
  contentId: string;
  dubbingList: DubbingInterface[];
}

export const Dubbing: React.FC<DubbingProps> = ({ contentId, dubbingList }) => {
  const [dataSource, setDataSource] = useState<DubbingInterface[]>(dubbingList);
  const [form] = Form.useForm();

  const {
    handleLanguageChange,
    handleFileChange,
    handleActiveChange,
    handleAddDubbing,
    handleUpdateDubbing,
  } = dubbingHandlers({
    form,
    contentId: Number(contentId),
    setDataSource,
  });

  const columns = getDubbingColumns({
    handleLanguageChange,
    handleFileChange,
    handleActiveChange,
  });

  return (
    <>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        rowKey={record => record.id.toString()}
      />
      <Button
        type="dashed"
        icon={<PlusOutlined />}
        style={{ width: '100%', marginTop: '10px' }}
        onClick={() => handleAddDubbing(dataSource.length + 1)}
      >
        New Dubbing
      </Button>
      <Form.Item style={{ marginTop: 16, textAlign: 'center' }}>
        <Button type="primary" onClick={() => handleUpdateDubbing()}>
          Save
        </Button>
      </Form.Item>
    </>
  );
};

export default Dubbing;
