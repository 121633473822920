import { BaseApi } from 'api/baseApi';
import axios from 'axios';
import { BASE_URL, CONTRACT_API_PATH, CONTRACT_UPLOAD_API_PATH } from 'constants/api';
import { ContractInterface } from '../type/contractType';

class ContractApi extends BaseApi<ContractInterface> {
  public async fetchById(id: number): Promise<ContractInterface> {
    const response = await axios.get(`${BASE_URL}${CONTRACT_API_PATH}${id}/`);
    return response.data;
  }

  public async create(contract: ContractInterface): Promise<ContractInterface> {
    const response = await axios.post(`${BASE_URL}${CONTRACT_API_PATH}`, contract);
    return response.data;
  }

  public async update(id: number, contract: ContractInterface): Promise<ContractInterface> {
    const response = await axios.put(`${BASE_URL}${CONTRACT_API_PATH}${id}/`, contract);
    return response.data;
  }

  public async delete(contractId: number): Promise<void> {
    const response = await axios.delete(`${BASE_URL}${CONTRACT_API_PATH}${contractId}/`);
    return response.data;
  }

  public async upload(file: File): Promise<void> {
    const formData = new FormData();
    formData.append('file', file);

    const response = await axios.post(`${BASE_URL}${CONTRACT_UPLOAD_API_PATH}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  }
}

export const contractApi = new ContractApi(CONTRACT_API_PATH);
