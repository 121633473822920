import { UserInterface } from '../type/userType';
import { userApi } from '../apis/userApi';
import { BaseStore } from 'store/baseStore';

export class UserStore extends BaseStore<UserInterface> {
  public async init(): Promise<void> {
    const { results } = await userApi.fetch();
    this.setItems(results);
  }
}

export const userStore = new UserStore(userApi);
