import { Button, Space } from 'antd';
import { TableColumnInterface } from 'components/table/Table';
import React from 'react';
import { ContentProviderInterface } from '../../type/contentProviderType';

interface ContentProviderColumnsProps {
  onClickEditButton: (id: number) => void;
  onClickDeleteButton: (id: number) => void;
}

export const getContentProviderColumns = ({
  onClickEditButton,
  onClickDeleteButton,
}: ContentProviderColumnsProps): TableColumnInterface<ContentProviderInterface>[] => {
  return [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Title_en',
      dataIndex: 'title_en',
      key: 'title_en',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Slug',
      dataIndex: 'slug',
      key: 'slug',
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
    },
    {
      title: 'Actions',
      key: 'action',
      dataIndex: 'action',
      render: (_text: string, record: ContentProviderInterface): React.ReactNode => (
        <Space size="middle">
          <Button onClick={() => onClickEditButton(record.id)}>Edit</Button>
          <Button onClick={() => onClickDeleteButton(record.id)}>Delete</Button>
        </Space>
      ),
    },
  ];
};
