import React from 'react';
import { Modal, Table } from 'antd';
import { ImportVideoDataInterface } from '../../../type/videoSourceType';

interface VideoSourceModalProps {
  modalTitle: string;
  videoListModalOpen: boolean;
  videoListModalOnCancel: () => void;
  loading: boolean;
  importVideoDataList: ImportVideoDataInterface[];
}

const VideoSourceModal = ({
  modalTitle,
  videoListModalOpen,
  videoListModalOnCancel,
  loading,
  importVideoDataList,
}: VideoSourceModalProps): React.ReactElement => {
  return (
    <div>
      <Modal
        title={`Video List - ${modalTitle}`} // 동적으로 모달 제목 설정
        open={videoListModalOpen}
        onCancel={videoListModalOnCancel}
        footer={null} // 하단 버튼 제거
        width={800}
        confirmLoading={loading}
      >
        <Table
          dataSource={importVideoDataList}
          columns={[
            {
              title: 'Title',
              dataIndex: 'title',
              key: 'title',
            },
            {
              title: 'Publication Date',
              dataIndex: 'pubDate',
              key: 'pubDate',
            },
            {
              title: 'Thumbnail',
              dataIndex: 'thumbnailUrl',
              key: 'thumbnailUrl',
              render: (url: string): React.ReactElement => (
                <img src={url} alt="Thumbnail" style={{ width: '50px', height: '50px' }} />
              ),
            },
            {
              title: 'Content URL',
              dataIndex: 'contentUrl',
              key: 'contentUrl',
              render: (url: string): React.ReactElement => (
                <a href={url} target="_blank" rel="noopener noreferrer">
                  Link
                </a>
              ),
            },
            {
              title: 'Duration',
              dataIndex: 'duration',
              key: 'duration',
            },
          ]}
          rowKey="key"
          pagination={{ pageSize: 10 }}
          scroll={{ y: 400 }}
        />
      </Modal>
    </div>
  );
};

export default VideoSourceModal;
