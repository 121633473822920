import { SearchOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import React, { useState } from 'react';

export const TransferSearch = ({
  handleSearch,
  direction,
}: {
  handleSearch: (direction: 'left' | 'right', value: string) => void;
  direction: 'left' | 'right';
}) => {
  const [value, setValue] = useState('');

  const onSearch = () => {
    handleSearch(direction, value);
  };

  return (
    <div style={{ padding: '8px' }}>
      <Input.Group compact>
        <Input
          style={{ width: 'calc(100% - 40px)' }}
          placeholder="enter search term"
          value={value}
          onChange={e => setValue(e.target.value)}
          onPressEnter={onSearch}
        />
        <Button icon={<SearchOutlined />} onClick={onSearch} />
      </Input.Group>
    </div>
  );
};
