import { Form, FormInstance, Input } from 'antd';
import React, { useState } from 'react';
import * as Component from '../../../../../../shared/components';
import { PeopleInterface } from '../../../../type/peopleType';

interface PeopleDetailProps {
  form: FormInstance;
  people?: PeopleInterface;
  actionTitle: string;
  handleOnClickButtonClick: () => void;
  handleOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const PeopleDetail: React.FC<PeopleDetailProps> = ({
  form,
  people,
  actionTitle,
  handleOnClickButtonClick,
  handleOnChange,
}) => {
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setPreviewUrl(imageUrl);
    }
    handleOnChange(e);
  };

  return (
    <div>
      <div className="people-details-page">
        <h1>People: {people?.name}</h1>
        <Form form={form} layout="vertical" className="people-details-form">
          <Form.Item
            name="name"
            label="name"
            rules={[{ required: true, message: 'please enter name' }]}
            initialValue={people?.name}
          >
            <Input />
          </Form.Item>
          <Form.Item name="name_en" label="name_en">
            <Input />
          </Form.Item>
          <Form.Item
            name="thumbnail"
            label="Thumbnail"
            valuePropName="file"
            getValueFromEvent={e => e?.target?.files?.[0]}
            initialValue={people?.thumbnail}
          >
            <input type="file" accept="image/*" onChange={handleImageChange} />
            {(previewUrl || people?.thumbnail) && (
              <img
                src={previewUrl || people?.thumbnail}
                alt="thumbnail preview"
                style={{ maxWidth: '200px', marginTop: '10px' }}
              />
            )}
          </Form.Item>
          <Form.Item name="type" label="type" initialValue={people?.type}>
            <Input />
          </Form.Item>
          <Form.Item name="birthdate" label="birthdate" initialValue={people?.birthdate}>
            <Input type="date" />
          </Form.Item>
          <Form.Item name="nationality" label="nationality" initialValue={people?.nationality}>
            <Input />
          </Form.Item>
        </Form>
      </div>
      <div className="people-detail-update-button">
        <Component.ColorButtonComponent text={actionTitle} onClick={handleOnClickButtonClick} />
      </div>
    </div>
  );
};

export default PeopleDetail;
