import React from 'react';
import { List, Card, Avatar, Form, Input, Checkbox, Button } from 'antd';
import { PosterInterface } from 'content/type';
import { DeleteOutlined } from '@ant-design/icons';

interface PosterListProps {
  posters: PosterInterface[];
  selectedPoster: PosterInterface | null;
  onPosterSelect: (poster: PosterInterface | null) => void;
  onPosterRemove: (poster: PosterInterface) => void;
}

const PosterList: React.FC<PosterListProps> = ({
  posters,
  selectedPoster,
  onPosterSelect,
  onPosterRemove,
}) => {
  return (
    <List
      itemLayout="vertical"
      dataSource={posters}
      renderItem={(poster: PosterInterface) => (
        <Card className="upload-video-list-item" hoverable>
          <div className="video-meta">
            <Form.Item>
              <Checkbox
                checked={selectedPoster?.id === poster.id}
                onChange={e => {
                  if (e.target.checked) {
                    onPosterSelect(poster);
                  } else {
                    onPosterSelect(null);
                  }
                }}
              />
            </Form.Item>
            <Avatar
              shape="square"
              style={{ width: '100px', height: '100px', paddingLeft: '10px' }}
              src={poster.file ? poster.file : ''}
            />
            <div className="video-info">
              <Form.Item label="Name">
                <Input type="text" value={poster.file || ''} />
              </Form.Item>
              <Button
                type="text"
                danger
                icon={<DeleteOutlined />}
                onClick={() => onPosterRemove(poster)}
              >
                delete
              </Button>
            </div>
          </div>
        </Card>
      )}
    />
  );
};

export default PosterList;
