import { BaseStore } from 'store/baseStore';
import { licenseeApi } from '../apis/licenseeApi';
import { LicenseeInterface } from '../type/licenseeType';

export class LicenseeStore extends BaseStore<LicenseeInterface> {
  public async init(): Promise<void> {
    const result = await licenseeApi.fetch();
    this.setItems(result.results);
  }

  public async fetchLicensee(): Promise<LicenseeInterface[]> {
    const queryParams = {
      page_size: '100',
      type: 'pp',
    };
    const result = await licenseeApi.fetch(queryParams);
    return result.results;
  }
}

export const licenseeStore = new LicenseeStore(licenseeApi);
