import { BaseApi } from 'api/baseApi';
import axios from 'axios';
import { BASE_URL, CONTENT_API_PATH, REVENUE_API_PATH } from 'constants/api';
import {
  DubbingInterface,
  ContentInterface,
  RevenueInterface,
  SubtitleInterface,
  ThumbnailInterface,
} from 'content/type';
import { FetchResult } from 'type/baseApiType';

class ContentApi extends BaseApi<ContentInterface> {
  async fetchById(id: number): Promise<ContentInterface> {
    const response = await axios.get(`${BASE_URL}${CONTENT_API_PATH}${id}/`);
    return response.data;
  }

  async fetchByWord(page: number, word: string): Promise<FetchResult<ContentInterface>> {
    const response = await axios.get(`${BASE_URL}${CONTENT_API_PATH}`, {
      params: {
        page: page,
        search: word,
      },
    });
    return response.data;
  }

  async fetchByIds(ids: number[]): Promise<FetchResult<ContentInterface>> {
    const idsString = ids.join(',');
    const response = await axios.get(`${BASE_URL}${CONTENT_API_PATH}?ids=${idsString}`);
    return response.data;
  }

  // Video Transcode
  async videoTranscode(contentId: number): Promise<void> {
    const response = await axios.post(
      `${BASE_URL}${CONTENT_API_PATH}${contentId}/video-transcode/`
    );
    return response.data;
  }

  // Subtitle
  async fetchSubtitleList(contentId: number): Promise<SubtitleInterface[]> {
    const response = await axios.get(`${BASE_URL}${CONTENT_API_PATH}${contentId}/subtitle/`);
    return response.data;
  }

  async uploadSubtitle(contentId: number, formData: FormData): Promise<void> {
    const response = await axios.post(
      `${BASE_URL}${CONTENT_API_PATH}${contentId}/subtitle/`,
      formData
    );
    return response.data;
  }

  // Dubbing
  async fetchDubbingList(contentId: number): Promise<FetchResult<DubbingInterface>> {
    const response = await axios.get(`${BASE_URL}${CONTENT_API_PATH}${contentId}/dubbing/`);
    return response.data;
  }

  async uploadDubbing(contentId: number, formData: FormData): Promise<void> {
    const response = await axios.post(
      `${BASE_URL}${REVENUE_API_PATH}contents/${contentId}/revenues/`,
      formData
    );
    return response.data;
  }

  // Thumbnail
  async fetchThumbnailList(contentId: number): Promise<ThumbnailInterface[]> {
    const response = await axios.get(`${BASE_URL}${CONTENT_API_PATH}${contentId}/thumbnail/`);
    return response.data;
  }

  async uploadThumbnail(contentId: number, formData: FormData): Promise<void> {
    const response = await axios.post(
      `${BASE_URL}${CONTENT_API_PATH}${contentId}/thumbnail/`,
      formData
    );
    return response.data;
  }

  async removeThumbnail(contentId: number, thumbnailId: number): Promise<void> {
    const response = await axios.delete(
      `${BASE_URL}${CONTENT_API_PATH}${contentId}/thumbnail/${thumbnailId}/`
    );
    return response.data;
  }

  async updateThumbnail(contentId: number, thumbnail: ThumbnailInterface): Promise<void> {
    const response = await axios.patch(
      `${BASE_URL}${CONTENT_API_PATH}${contentId}/thumbnail/${thumbnail.id}/`,
      thumbnail
    );
    return response.data;
  }

  async fetchRevenueShareData(
    queryString: Record<string, string>
  ): Promise<FetchResult<RevenueInterface>> {
    const qs = Object.entries(queryString)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
    const response = await axios.get(`${BASE_URL}${REVENUE_API_PATH}?${qs}`);
    return response.data;
  }
}

export const contentApi = new ContentApi(CONTENT_API_PATH);
