import { PosterInterface, SeasonInterface, UploadPosterInterface } from 'content/type';
import { seasonApi } from '../apis/seasonApi';
import { BaseStore } from 'store/baseStore';
import { FetchResult } from 'type/baseApiType';

class SeasonStore extends BaseStore<SeasonInterface> {
  public async init(): Promise<void> {
    const response = await seasonApi.fetch();
    this.setItems(response.results);
  }

  public async fetchByWords(page: number, words: string): Promise<FetchResult<SeasonInterface>> {
    return await seasonApi.fetchByWord(page, words);
  }

  public async update(id: number, season: Partial<SeasonInterface>): Promise<void> {
    const filteredSeason = Object.fromEntries(Object.entries(season).filter(([_, value]) => value));
    await seasonApi.update(id, filteredSeason);
  }

  public async fetchPoster(seasonId: number): Promise<PosterInterface[]> {
    return await seasonApi.fetchPosterList(seasonId);
  }

  public async uploadPoster(seasonId: number, posters: UploadPosterInterface[]): Promise<void> {
    await Promise.all(
      posters.map((poster: UploadPosterInterface) => {
        const formData = new FormData();
        if (poster.file) {
          formData.append('file', poster.file);
        }
        if (poster.is_representative) {
          formData.append('is_representative', poster.is_representative.toString());
        }
        return seasonApi.uploadPoster(seasonId, formData);
      })
    );
  }

  public async removePoster(seasonId: number, posters: PosterInterface[]): Promise<void> {
    await Promise.all(posters.map(poster => seasonApi.removePoster(seasonId, poster.id)));
  }

  public async updatePoster(seasonId: number, poster: PosterInterface): Promise<void> {
    await seasonApi.updatePoster(seasonId, poster);
  }
}

export const seasonStore = new SeasonStore(seasonApi);
