import { SeriesInterface } from '../type/seriesType';
import { BaseStore } from 'store/baseStore';
import { PosterInterface, UploadPosterInterface } from 'content/type';
import { seriesApi } from 'content/apis/seriesApi';

class SeriesStore extends BaseStore<SeriesInterface> {
  public async init(): Promise<void> {
    const response = await this.fetch();
    this.setItems(response);
  }

  public async uploadPoster(seriesId: number, posters: UploadPosterInterface[]): Promise<void> {
    await Promise.all(
      posters.map((poster: UploadPosterInterface) => {
        const formData = new FormData();
        if (poster.file) {
          formData.append('file', poster.file);
        }
        if (poster.is_representative) {
          formData.append('is_representative', poster.is_representative.toString());
        }
        return seriesApi.uploadPoster(seriesId, formData);
      })
    );
  }

  public async removePoster(seriesId: number, posters: PosterInterface[]): Promise<void> {
    await Promise.all(posters.map(poster => seriesApi.removePoster(seriesId, poster.id)));
  }

  public async updatePoster(seriesId: number, poster: PosterInterface): Promise<void> {
    await seriesApi.updatePoster(seriesId, poster);
  }
}

export const seriesStore = new SeriesStore(seriesApi);
