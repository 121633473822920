import React, { ReactNode } from 'react';
import { Button, Typography } from 'antd';
import './ContentHeader.css';

interface PageHeaderProps {
  title: string;
  buttonText: string;
  children?: ReactNode;
  onManageButtonClick?: () => void;
  onManageButtonClick2?: () => void;
  buttonText2?: string;
}

const { Title } = Typography;

export default function ContentHeader({
  title,
  buttonText,
  children,
  onManageButtonClick,
  onManageButtonClick2,
  buttonText2,
}: PageHeaderProps): React.JSX.Element {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '16px',
        }}
      >
        <Title level={2}>{title}</Title>
        <div>
          {onManageButtonClick2 && <Button onClick={onManageButtonClick2}>{buttonText2}</Button>}
          <Button onClick={onManageButtonClick}>{buttonText}</Button>
          {children || ''}
        </div>
      </div>
    </div>
  );
}
