import { Form } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Component from '../../../../shared/components';
import { categoryStore } from '../../store/categoryStore';
import { CategoryInterface } from '../../type/categoryType';
import * as CategoryComponent from './components';
import { detailHandlers } from './handlers';

const CategoryEdit: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [category, setCategory] = useState<CategoryInterface | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { handleOnClickEditButtonClick } = detailHandlers({ id: id || '0', form, navigate });

  useEffect(() => {
    const fetchSeason = async (): Promise<void> => {
      await categoryStore.init();
      const category = await categoryStore.getCategoryById(Number(id));
      if (category) {
        setCategory(category);
        const formattedCategory = {
          ...category,
        };

        form.setFieldsValue(formattedCategory);
      }
    };
    fetchSeason().then(() => {
      setIsLoading(false);
    });
  }, [id]);

  return (
    <Component.BasePageComponent>
      {isLoading ? (
        <Component.LoadingSpinnerComponent />
      ) : (
        <CategoryComponent.DetailComponent
          form={form}
          category={category || undefined}
          actionTitle="SAVE"
          handleOnClickButtonClick={handleOnClickEditButtonClick}
        />
      )}
    </Component.BasePageComponent>
  );
};

export default observer(CategoryEdit);
