import { observer } from 'mobx-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as Component from '../../../../shared/components';
import { playlistStore } from '../../store/playlistStore';
import { PlaylistInterface } from '../../type/playlistType';
import tableHandlers from './handlers/tableHandlers';
import './Playlist.css';
import { getPlaylistColumns } from './PlaylistColumns';

const Playlist: React.FC = () => {
  const navigate = useNavigate();
  const { handleOnClickTitle, handleOnClickSetting, onClickDeleteButton, handleAddPlaylistClick } =
    tableHandlers({
      navigate,
    });

  const columns = getPlaylistColumns({
    handleOnClickTitle,
    handleOnClickSetting,
    onClickDeleteButton,
  });

  const fetchPlaylist = async (
    page: number,
    pageSize: number
  ): Promise<{ data: PlaylistInterface[]; count: number }> => {
    const data = await playlistStore.fetch({
      page: page.toString(),
      page_size: pageSize.toString(),
    });
    return { data, count: playlistStore.getTotalCount() };
  };

  return (
    <Component.BasePageComponent>
      <div className="playlist-container">
        <Component.TableComponent<PlaylistInterface>
          tableName="Playlist"
          dataFetch={fetchPlaylist}
          columns={columns}
          handleAddClick={handleAddPlaylistClick}
        />
      </div>
    </Component.BasePageComponent>
  );
};

export default observer(Playlist);
