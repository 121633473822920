import React, { useState } from 'react';
import { Button, Form, Table } from 'antd';
import { SubtitleInterface } from 'content/type';
import { getSubtitleColumns } from './SubtitleColumns';
import { subtitleHandlers } from 'content/page/content/handlers';
import { PlusOutlined } from '@ant-design/icons';

interface SubtitleProps {
  contentId: number;
  subtitleList: SubtitleInterface[];
}

export const Subtitle: React.FC<SubtitleProps> = ({ contentId, subtitleList }: SubtitleProps) => {
  const [dataSource, setDataSource] = useState<SubtitleInterface[]>(subtitleList);
  const [form] = Form.useForm();
  const {
    handleLanguageChange,
    handleFileChange,
    handleActiveChange,
    handleAddSubtitle,
    handleUpdateSubtitle,
  } = subtitleHandlers({
    form,
    contentId,
    setDataSource,
  });

  const columns = getSubtitleColumns({
    handleLanguageChange,
    handleFileChange,
    handleActiveChange,
  });

  return (
    <>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        rowKey={record => record.id.toString()}
      />
      <Button
        type="dashed"
        icon={<PlusOutlined />}
        style={{ width: '100%', marginTop: '10px' }}
        onClick={() => handleAddSubtitle(dataSource.length + 1)}
      >
        New Subtitle
      </Button>
      <Form.Item style={{ marginTop: 16, textAlign: 'center' }}>
        <Button type="primary" onClick={() => handleUpdateSubtitle()}>
          Save
        </Button>
      </Form.Item>
    </>
  );
};

export default Subtitle;
