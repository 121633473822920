import { observer } from 'mobx-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as Component from '../../../../shared/components';
import { tableHandlers } from '../handlers';
import { videoSourceStore } from '../store/videoSourceStore';
import { VideoSourceInterface } from '../type/videoSourceType';
import './VideoSource.css';
import { getVideoSourceColumns } from './VideoSourceColumns';

const VideoSourcePage: React.FC = () => {
  const navigate = useNavigate();
  const { onClickTitleButton, onClickSettingButton, onClickDeleteButton, onClickReloadButton } =
    tableHandlers({ navigate });

  const columns = getVideoSourceColumns({
    onClickTitleButton,
    onClickSettingButton,
    onClickDeleteButton,
    onClickReloadButton,
  });

  const fetchVideoSource = async (
    page: number,
    pageSize: number
  ): Promise<{ data: VideoSourceInterface[]; count: number }> => {
    const videoSourceList = await videoSourceStore.fetch({
      page: page.toString(),
      page_size: pageSize.toString(),
    });
    const filteredVideoSourceList = videoSourceList.filter(source => source.name !== 'ODK');
    return { data: filteredVideoSourceList, count: videoSourceStore.getTotalCount() };
  };

  return (
    <Component.BasePageComponent>
      <Component.TableComponent<VideoSourceInterface>
        tableName="VideoSource"
        dataFetch={fetchVideoSource}
        columns={columns}
      />
    </Component.BasePageComponent>
  );
};

export default observer(VideoSourcePage);
