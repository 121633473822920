import { NavigateFunction } from 'react-router-dom';

interface ContentHeaderHandlersHook {
  navigate: NavigateFunction;
}

interface ContentHeaderHandlersReturn {
  importMrssOnClick: () => void;
  odkSyncOnClick: () => void;
  handleMyLocalFilesClick: () => void;
  onManageButtonClick: (contentType: string, state?: Record<string, string>) => void;
}

export const contentHeaderHandlers = ({
  navigate,
}: ContentHeaderHandlersHook): ContentHeaderHandlersReturn => {
  const importMrssOnClick = (): void => {
    navigate('/video-source/add');
  };

  const odkSyncOnClick = (): void => {
    navigate('/video-source/odk');
  };

  const handleMyLocalFilesClick = (): void => {
    navigate('/upload/video');
  };

  const onManageButtonClick = (contentType: string, state?: Record<string, string>): void => {
    navigate(`/${contentType}`, {
      state,
    });
  };

  return {
    importMrssOnClick,
    odkSyncOnClick,
    handleMyLocalFilesClick,
    onManageButtonClick,
  };
};
