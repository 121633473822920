import React, { useEffect, useRef } from 'react';
import videojs from 'video.js';
import Player from 'video.js/dist/types/player';
import 'video.js/dist/video-js.css';

// VideoJS 플레이어 옵션 타입 정의
interface VideoJsOptions {
  sources: { src: string; type: string }[];
  controls?: boolean;
  autoplay?: boolean;
  responsive?: boolean;
  fluid?: boolean;
}

const VideoPlayer: React.FC<{ options: VideoJsOptions }> = ({ options }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const playerRef = useRef<Player | null>(null);

  useEffect((): void => {
    // 비디오 요소가 DOM에 추가된 후에 플레이어를 초기화합니다.
    const initializePlayer = (): void => {
      if (!playerRef.current) {
        const videoElement = videoRef.current;
        if (!videoElement) return;

        const player = videojs(videoElement, {
          ...options,
          controls: true,
          autoplay: false,
          responsive: true,
          fluid: true,
          html5: {
            hls: {
              enableLowInitialPlaylist: true,
              smoothQualityChange: true,
              overrideNative: true,
            },
          },
        });

        playerRef.current = player;

        player.ready(() => {
          console.log('Player is ready');
        });
      } else {
        // 기존 플레이어의 소스 업데이트
        const player = playerRef.current;
        player.src(options.sources);
      }
    };

    // DOM이 업데이트된 후에 플레이어를 초기화합니다.
    setTimeout(initializePlayer, 0);
  }, [options]);

  useEffect(() => {
    return (): void => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, []);

  return (
    <div data-vjs-player>
      <video ref={videoRef} className="video-js vjs-big-play-centered" />
    </div>
  );
};

export default VideoPlayer;
