import { Form, FormInstance, Input, Typography } from 'antd';
import * as Component from 'components';
import { PlaylistInterface } from 'content/type/playlistType';
import React, { useState } from 'react';
import './Detail.css';

interface PlaylistDetailProps {
  form: FormInstance;
  playlistData?: PlaylistInterface;
  actionTitle: string;
  handleOnClickButton: (setIsLoading: (isLoading: boolean) => void) => void;
}

const PlaylistDetail: React.FC<PlaylistDetailProps> = ({
  form,
  playlistData,
  actionTitle,
  handleOnClickButton,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { Text, Link } = Typography;
  return (
    <>
      {isLoading ? (
        <Component.LoadingSpinnerComponent />
      ) : (
        <div>
          <div className="playlist-details-page">
            <h1>playlist: {playlistData?.title}</h1>
            <Form
              form={form}
              layout="vertical"
              className="playlist-details-form"
              initialValues={playlistData}
            >
              <Form.Item name="title" label="Title">
                <Input />
              </Form.Item>
              <Form.Item name="description" label="Description">
                <Input />
              </Form.Item>
              <Form.Item name="created" label="Created">
                <Input readOnly />
              </Form.Item>
            </Form>
          </div>
          {actionTitle === 'UPDATE' ? (
            <div>
              <div className="playlist-sources">
                <h3>Playlist MRSS Sources</h3>
              </div>
              <div className="playlist-mrss" style={{ marginTop: '30px' }}>
                <Text strong style={{ marginLeft: '30px', marginRight: '10px' }}>
                  Zype MRSS Feed URL:
                </Text>
                <Link href={playlistData?.mrss_url} target="_blank">
                  {playlistData?.mrss_url}
                </Link>
              </div>
            </div>
          ) : null}
          <div className="playlist-detail-update-button" style={{ marginTop: '30px' }}>
            <Component.ColorButtonComponent
              text={actionTitle}
              onClick={() => handleOnClickButton(setIsLoading)}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default PlaylistDetail;
