import { BaseApi } from 'api/baseApi';
import axios from 'axios';
import { BASE_URL, CONTENT_API_PATH, VIDEO_SOURCE_API_PATH } from 'constants/api';
import { ContentInterface } from 'content/type';
import { FetchResult } from 'type/baseApiType';
import {
  VideoSourceInterface,
  VideoSourceResponseInterface,
  VideoSourceVideoListInterface,
} from '../type';

class VideoSourceApi extends BaseApi<VideoSourceInterface> {
  public async fetchById(id: number): Promise<VideoSourceInterface> {
    const response = await axios.get(`${BASE_URL}${VIDEO_SOURCE_API_PATH}${id}/`);
    return response.data;
  }

  public async get(): Promise<VideoSourceResponseInterface> {
    const response = await axios.get(`${BASE_URL}${VIDEO_SOURCE_API_PATH}`);
    return response.data;
  }

  public async create(videoSource: VideoSourceInterface): Promise<VideoSourceInterface> {
    const response = await axios.post(`${BASE_URL}${VIDEO_SOURCE_API_PATH}`, videoSource);
    return response.data;
  }

  public async update(
    id: number,
    videoSource: VideoSourceInterface
  ): Promise<VideoSourceInterface> {
    const response = await axios.put(`${BASE_URL}${VIDEO_SOURCE_API_PATH}${id}/`, videoSource);
    return response.data;
  }

  public async delete(id: number): Promise<void> {
    return await axios.delete(`${BASE_URL}${VIDEO_SOURCE_API_PATH}${id}/`);
  }

  public async generate(id: number): Promise<void> {
    return await axios.post(`${BASE_URL}${VIDEO_SOURCE_API_PATH}${id}/generate/`);
  }

  public async bulkOdkSync(startFromBeginning: boolean): Promise<void> {
    return await axios.post(`${BASE_URL}${VIDEO_SOURCE_API_PATH}odk-bulk/`, {
      reset: startFromBeginning,
    });
  }

  public async getODKItem(): Promise<VideoSourceInterface> {
    const response = await axios.get(`${BASE_URL}${VIDEO_SOURCE_API_PATH}odk/`);
    return response.data;
  }

  public async getVideoList(
    id: number,
    queryParams: Record<string, string>
  ): Promise<FetchResult<VideoSourceVideoListInterface>> {
    const response = await axios.get(`${BASE_URL}${VIDEO_SOURCE_API_PATH}${id}/video-list/`, {
      params: queryParams,
    });
    return response.data;
  }

  public async getContentProviderVideoList(
    queryParams: Record<string, string>
  ): Promise<FetchResult<ContentInterface>> {
    const response = await axios.get(`${BASE_URL}${CONTENT_API_PATH}`, {
      params: {
        has_content_provider: 'true',
        ...queryParams,
      },
    });
    return response.data;
  }
}

export const videoSourceApi = new VideoSourceApi(VIDEO_SOURCE_API_PATH);
