import { BaseApi } from 'api/baseApi';
import axios from 'axios';
import { BASE_URL, LICENSEE_API_PATH } from 'constants/api';
import { LicenseeInterface } from '../type/licenseeType';

class LicenseeApi extends BaseApi<LicenseeInterface> {
  public async fetchById(id: number): Promise<LicenseeInterface> {
    const response = await axios.get(`${BASE_URL}${LICENSEE_API_PATH}${id}/`);
    return response.data;
  }
}

export const licenseeApi = new LicenseeApi(LICENSEE_API_PATH);
