import { contentProviderStore } from 'account/store/contentProviderStore';
import { NavigateFunction } from 'react-router-dom';
interface UseContentProviderTableProps {
  navigate: NavigateFunction;
}

interface UseContentProviderTableReturn {
  onClickDeleteButton: (id: number) => Promise<void>;
  onClickEditButton: (id: number) => void;
  handleAddContentProviderClick: () => void;
}

const useContentProviderTable = ({
  navigate,
}: UseContentProviderTableProps): UseContentProviderTableReturn => {
  const onClickDeleteButton = async (id: number): Promise<void> => {
    // message.info('You do not have permission');
    // return;
    const isConfirmed = window.confirm('Are you sure you want to delete the selected items?');
    if (!isConfirmed) return;
    await contentProviderStore.delete(id);
    window.location.reload();
  };

  const onClickEditButton = (id: number): void => {
    navigate(`/content-provider/edit/${id}`);
  };

  // My Local Files 클릭 시 이동 처리
  const handleAddContentProviderClick = (): void => {
    navigate('/content-provider/add');
  };

  return {
    onClickDeleteButton,
    onClickEditButton,
    handleAddContentProviderClick,
  };
};

export default useContentProviderTable;
