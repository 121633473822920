import { FormInstance } from 'antd';
import { NavigateFunction } from 'react-router-dom';
import { playlistStore } from '../../../store/playlistStore';

interface DetailHookProps {
  id: string;
  form: FormInstance;
  navigate: NavigateFunction;
}

const detailHandlers = ({
  form,
  id,
  navigate,
}: DetailHookProps): {
  handleUpdateButtonClick: (setIsLoading: (isLoading: boolean) => void) => void;
} => {
  const handleUpdateButtonClick = async (
    setIsLoading: (isLoading: boolean) => void
  ): Promise<void> => {
    setIsLoading(true);
    const newPlaylist = form.getFieldsValue();
    await playlistStore.update(Number(id), newPlaylist);
    setIsLoading(false);
    navigate('/playlists/');
  };

  return { handleUpdateButtonClick };
};

export default detailHandlers;
