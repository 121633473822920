import { contentProviderStore } from 'account/store/contentProviderStore';
import { Form } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Component from '../../../../shared/components';
import * as ContractComponent from './components';
import { detailHandlers } from './handlers';
import { licenseeStore } from 'account/store/licenseeStore';
import { LicenseeInterface } from 'account/type/licenseeType';

const ContractAdd: React.FC = () => {
  const [form] = Form.useForm();
  const [licenseeList, setLicenseeList] = useState<LicenseeInterface[]>([]);
  const [contentProviderList, setContentProviderList] = useState<{ id: number; title: string }[]>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const { handleOnClickAddButtonClick } = detailHandlers({ id: '0', form, navigate });

  const fetchContentProvider = async (): Promise<void> => {
    const contentProviderData = await contentProviderStore.fetchContentProvider();
    setContentProviderList(contentProviderData);
  };

  const fetchLicensee = async (): Promise<void> => {
    const licenseeData = await licenseeStore.fetchLicensee();
    setLicenseeList(licenseeData);
  };

  useEffect(() => {
    Promise.all([fetchContentProvider(), fetchLicensee()]).then(() => {
      setIsLoading(false);
    });
  }, []);

  return (
    <Component.BasePageComponent>
      {isLoading ? (
        <Component.LoadingSpinnerComponent />
      ) : (
        <ContractComponent.DetailComponent
          form={form}
          contentProviderList={contentProviderList}
          licenseeList={licenseeList}
          actionTitle="ADD"
          handleOnClickButtonClick={handleOnClickAddButtonClick}
        />
      )}
    </Component.BasePageComponent>
  );
};

export default observer(ContractAdd);
