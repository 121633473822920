import { Select } from 'antd';
import * as Component from 'components';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { videoSourceStore } from 'upload/video-source/store/videoSourceStore';
import {
  VideoSourceSelectItemInterface,
  VideoSourceVideoListInterface,
} from 'upload/video-source/type';
import { getVideoSourceContentListColumns } from './VideoSourceContentColumns';

const VideoSourceContent: React.FC = () => {
  const location = useLocation();
  const videoSourceType = location.state?.videoSourceType;
  const [videoSourceList, setVideoSourceList] = useState<VideoSourceSelectItemInterface[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [selectedVideoSource, setSelectedVideoSource] = useState<number>();

  const fetchVideoSourceContent = useCallback(
    async (
      page: number,
      pageSize: number
    ): Promise<{ data: VideoSourceVideoListInterface[]; count: number }> => {
      if (selectedVideoSource === undefined || selectedVideoSource === null) {
        return { data: [], count: 0 };
      }

      const queryParams = {
        page: page.toString(),
        page_size: pageSize.toString(),
        search: searchTerm,
      };

      const videoSourceContentList = await videoSourceStore.fetchContentList(
        selectedVideoSource,
        queryParams
      );

      return {
        data: videoSourceContentList,
        count: videoSourceStore.getTotalCount(),
      };
    },
    [searchTerm, selectedVideoSource]
  );

  const handleChange = (value: string): void => {
    setSelectedVideoSource(Number(value));
  };

  const columns = getVideoSourceContentListColumns();

  useEffect(() => {
    if (videoSourceType === 'cp') {
      const fetchVideoSourceList = async (): Promise<void> => {
        const videoSourceList = await videoSourceStore.getVideoSourceSelectItemList();
        const filteredVideoSourceList = videoSourceList.filter(item => item.name !== 'ODK');
        setVideoSourceList(filteredVideoSourceList);
        setSelectedVideoSource(filteredVideoSourceList[0]?.id);
      };
      fetchVideoSourceList().then(() => {
        setIsLoading(false);
      });
    } else if (videoSourceType === 'odk') {
      setSelectedVideoSource(1);
      setIsLoading(false);
    } else {
      setSelectedVideoSource(0);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    setRefreshCounter(prev => prev + 1);
  }, [searchTerm]);

  return (
    <div>
      <Component.BasePageComponent>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          {videoSourceType === 'cp' && (
            <>
              <div style={{ marginRight: '10px' }}>Video Source</div>
              <Select style={{ width: '200px' }} onChange={handleChange}>
                {videoSourceList.map((videoSource: { id: number; name: string }) => (
                  <Select.Option key={videoSource.id} value={videoSource.id}>
                    {videoSource.name}
                  </Select.Option>
                ))}
              </Select>
            </>
          )}
        </div>
        {isLoading ? (
          <Component.LoadingSpinnerComponent />
        ) : (
          <Component.TableComponent<VideoSourceVideoListInterface>
            key={refreshCounter}
            tableName={
              videoSourceType === 'user'
                ? 'Uploaded Contents'
                : videoSourceType === 'odk'
                  ? 'Imported Contents From Sync'
                  : 'VideoSourceContent'
            }
            columns={columns}
            isDisableIdColumn={true}
            handleSearchTerm={setSearchTerm}
            dataFetch={fetchVideoSourceContent}
          />
        )}
      </Component.BasePageComponent>
    </div>
  );
};

export default observer(VideoSourceContent);
