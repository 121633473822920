import { Form } from 'antd';
import * as Components from 'components';
import { observer } from 'mobx-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DetailComponent } from './components';
import { accountHandlers } from './handlers';

const AccountPage: React.FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { handleUpdate } = accountHandlers({ form, navigate });

  return (
    <Components.BasePageComponent>
      <DetailComponent form={form} handleUpdate={handleUpdate} />
    </Components.BasePageComponent>
  );
};

export default observer(AccountPage);
