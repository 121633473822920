import { userStore } from 'account/store/userStore';
import { NavigateFunction } from 'react-router-dom';

interface TableHandlersHook {
  navigate: NavigateFunction;
}

interface TableHandlersReturn {
  onClickDeleteButton: (id: number) => Promise<void>;
  onClickEditButton: (id: number) => void;
  handleAddUserClick: () => void;
}

const tableHandlers = ({ navigate }: TableHandlersHook): TableHandlersReturn => {
  const onClickDeleteButton = async (id: number): Promise<void> => {
    // message.info('You do not have permission');
    // return;
    const isConfirmed = window.confirm('Are you sure you want to delete the selected items?');
    if (!isConfirmed) return;
    await userStore.delete(id);
    window.location.reload();
  };

  const onClickEditButton = (id: number): void => {
    navigate(`/user/edit/${id}`);
  };

  // My Local Files 클릭 시 이동 처리
  const handleAddUserClick = (): void => {
    navigate('/user/add');
  };

  return {
    onClickDeleteButton,
    onClickEditButton,
    handleAddUserClick,
  };
};

export default tableHandlers;
